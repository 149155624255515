import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SubscriptionService } from 'src/app/@shared/services/subscription.service';
import data from '../../../../assets/subscription.json';

import { SubscriptionModel } from 'src/app/@shared/models/Subscription.model';
import { faPlaneDeparture } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-admin-dashboard-subscription',
  templateUrl: './admin-dashboard-subscription.component.html',
  styleUrls: ['./admin-dashboard-subscription.component.sass'],
})
export class AdminDashboardSubscriptionComponent implements OnInit, OnDestroy {
  counts = [1, 2, 3, 4, 5];
  subData: SubscriptionModel[] = [];
  subIndex = -1;
  isCancelSub: boolean = false;
  subs = new Subscription();
  constructor(
    private _router: Router,
    private subService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.subService.setIndex(-1);
    this.subs = this.subService.subsIndex$.subscribe((i) => {
      this.subIndex = i;
    });
    this.subs.add(
      this.subService.getSubscription().subscribe((i: any) => {
        i.data.forEach((subPlan: any) => {
          this.subData.push({
            tier: subPlan.subscriptionType,
            price: subPlan.cost,
            plan: subPlan.name,
            details: [],
          });
        });
      })
    );
  }
  ngOnDestroy(): void {
    this.subService.setIndex(-1);
    this.subs.unsubscribe();
  }
  goToPayment() {
    this._router.navigateByUrl('account/subscription/payment');
  }
}
