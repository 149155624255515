import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  combineLatest,
  combineLatestWith,
  map,
  Observable,
  startWith,
} from 'rxjs';
import { UserGroupModel } from 'src/app/@shared/models/user-group.model';
import { UserModel } from 'src/app/@shared/models/user.model';
import { UserGroupService } from 'src/app/@shared/services/user-group.service';
import { UsersService } from 'src/app/@shared/services/users.service';
import { UserGroupComponent } from '../user-group/user-group.component';

@Component({
  selector: 'app-edit-user-group',
  templateUrl: './edit-user-group.component.html',
  styleUrls: ['../user-group/user-group.component.sass'],
})
export class EditUserGroupComponent
  extends UserGroupComponent
  implements OnInit
{
  orginalGroupMembers: any[] = this.groupMembers;
  constructor(
    private _userGroupService: UserGroupService,
    private _usersService: UsersService
  ) {
    super();
  }
  ngOnInit(): void {
    // this._userGroupService
    //   .getUserGroup(this.data.data.id)
    //   .subscribe(({ data }) => {
    //     this.groupName = this.data.data.groupName;
    //     this.groupMembers = [...data.members];
    //     
    //     this._usersService
    //       .getUsers()
    //       .subscribe(({ data }) => (this.users = data));
    //     this.userlist = this.userListControl.valueChanges.pipe(
    //       startWith(''),
    //       map((value) => {
    //         return this.users.filter((data) =>
    //           data.email.toLowerCase().includes(value || '')
    //         ); //this.filter(value as string);
    //       })
    //     );
    //   });

    this._userGroupService
      .getUserGroup(this.data.data.id)
      .pipe(combineLatestWith(this._usersService.getUsers()))
      .subscribe(([ usergroup ,  user ]) => {
        this.groupName = this.data.data.groupName;
        this.groupMembers = [...usergroup.data.members];
        this.users = user.data;
        this.userlist = this.userListControl.valueChanges.pipe(
          startWith(''),
          map((value) => {
            return this.users.filter((data) =>
              data.email.toLowerCase().includes(value || '')
            ); //this.filter(value as string);
          })
        );
      });
  }
  updateUserGroup() {
    const data: UserGroupModel = {
      id: this.data.data.id,
      groupName: this.groupName,
      members: [],
      createdAt: '',
    };

    const members = this.groupMembers.map((members) => {
      return { ...members, userGroupId: this.data.data.id };
    });

    this._userGroupService.updateUserGroups(data).subscribe(async (result) => {
      for (let i = 0; i < members.length; i++) {
        if (
          !this.orginalGroupMembers.some(
            (mem: any) => mem.email === members[i].email
          )
        ) {
          await this._userGroupService.addUserToGroups(members[i]);
        }
      }

      for (let i = 0; i < this.orginalGroupMembers.length; i++) {
        if (
          !members.some(
            (mem: any) => mem.email === this.orginalGroupMembers[i].email
          )
        ) {
          await this._userGroupService.removeUserFromGroups(
            this.data.data.id,
            this.orginalGroupMembers[i].id
          );
        }
      }

      this._userGroupService.getUserGroups().subscribe(({ data }) => {
        this._userGroupService.setUserGroup(
          this._userGroupService.format(data)
        );
      });
      this.closeModal();
    });
  }

  override addMember() {
    const loc = this.users.findIndex(
      (user) =>
        (user.email === this.groupMember ||
          user.email === this.groupMember.email) &&
        !this.groupMembers.some(
          (groupMember) => groupMember.email === this.groupMember.email
        )
    );

    if (loc !== -1) {
      const user = this.users[loc];
      const format = {
        userGroupId: this.data.data.id,
        userId: user.id,
        email: user.email,
        role: 'viewer',
      };
      this.groupMembers.push(format);
    }

    this.users = this.users.filter((data) => {
      return data.email != this.groupMember.email;
    });
    this.groupMember = '';
  }
}
