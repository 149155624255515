<div class="documents padding-top-50 margin-container">
  <h4 class="title">Documents</h4>
  <mat-tab-group
    class=""
    #tabGroup
    preserveContent
    (selectedTabChange)="changeTab($event)"
  >
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="label-container">
          <img src="../../../../assets/images/icons/myDoc.svg" />
          <span>My Document</span>
        </div>
      </ng-template>
      <app-table
        [resultsLength]="myResultsLength"
        [title]="'docs'"
        [columns]="filesCols"
        [data]="myfilesData"
        [paginate]="true"
        [canOpenFile]="true"
        [hideTitle]="true"
        [nameFunction]="openPdf"
      ></app-table>
    </mat-tab>
    <mat-tab *ngIf="groupDocumentFlag">
      <ng-template mat-tab-label>
        <div class="label-container">
          <img src="../../../../assets/images/icons/teamDoc.svg" />
          <span>Group Documents</span>
        </div>
      </ng-template>
      <app-table
        *ngIf="isGroupDataLoaded"
        [resultsLength]="groupResultsLength"
        [title]="'docs'"
        [columns]="filesCols"
        [data]="groupfilesData"
        [paginate]="true"
        [canOpenFile]="true"
        [hideTitle]="true"
        [nameFunction]="openPdf"
      ></app-table>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="label-container">
          <img src="../../../../assets/images/shared-docu.png" />
          <span>Shared Documents</span>
        </div>
      </ng-template>
      <app-table
        [resultsLength]="sharedResultsLength"
        [title]="'docs'"
        [columns]="filesCols"
        [data]="sharedfilesData"
        [paginate]="true"
        [canOpenFile]="true"
        [hideTitle]="true"
        [nameFunction]="openPdf"
      ></app-table>
    </mat-tab>
  </mat-tab-group>
</div>
