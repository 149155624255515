import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { temp } from './temp';
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import { IntegrationModel } from 'src/app/Angkat-guest/components/document-creation/document-creation.component';
@Injectable({
  providedIn: 'root',
})
export class AngkatDashboardService {
  private readonly baseUrl = environment.apiConfig.baseUrl;
  constructor(private _httpClient: HttpClient) {}
  /*GET {{host}}/integration/workflow?hostName=google.com&authorId=<author-id&workflowId=<workflow-id>
POST {{host}}/integration/workflow?userId=<user-id>*/
  getWorkflowDraft(integrationUrl: IntegrationModel) {
    let headers = new HttpHeaders()
      .set('x-host', integrationUrl.host)
      .set('x-api-key', integrationUrl['api-key'])
      .set('x-api-secret', integrationUrl['api-secret']);

    return this._httpClient.get<any>(
      `${this.baseUrl}/integration/workflow-draft`,
      {
        headers: headers,
        params: {
          hostName: integrationUrl.host,
          authorId: integrationUrl.userId,
          workflowId: integrationUrl.workflowId,
        },
      }
    );
  }
  getWorkflowFileAll(integrationUrl: IntegrationModel) {
    let headers = new HttpHeaders()
    .set('x-host', integrationUrl.host)
    .set('x-api-key', integrationUrl['api-key'])
    .set('x-api-secret', integrationUrl['api-secret']);

  return this._httpClient.get<any>(`${this.baseUrl}/integration/workflow/all?userId=${integrationUrl.userId}`, {
    headers: headers,
    params: {
      hostName: integrationUrl.host,
      authorId: integrationUrl.userId,
      
    },
  });
  }
  createFile(integrationData: IntegrationModel, formData?: any) {
    let headers = new HttpHeaders()
      .set('x-host', integrationData.host)
      .set('x-api-key', integrationData['api-key'])
      .set('x-api-secret', integrationData['api-secret']);

    return this._httpClient.post<any>(
      `${this.baseUrl}/integration/workflow?userId=${integrationData.userId}&workflowDraftId=${integrationData.workflowId}`,
      formData,
      { headers: headers }
    );
  }

  async generatePDF(test: any) {
    const content = test;
    const opt = {
      margin: 0.5,
      filename: 'example.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: 'in', format: 'legal', orientation: 'portrait' },
    };

    return html2pdf()
      .from(content)
      .set(opt)
      .output('blob')
      .then((data: Blob) => {
        return data;
      });
  }
}
