import { Component, OnInit, Input } from '@angular/core';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { UsersService } from 'src/app/@shared/services/users.service';

@Component({
  selector: 'app-admin-dashboard-wrapper',
  templateUrl: './admin-dashboard-wrapper.component.html',
  styleUrls: ['./admin-dashboard-wrapper.component.sass'],
})
export class AdminDashboardWrapperComponent implements OnInit {
  isViewAccount: boolean = false;
  @Input()
  viewPdf: boolean = false;
  constructor(private _navigationService: NavigationService, private _userService:UsersService) {}

  ngOnInit(): void {
    this._navigationService.accountView$.subscribe((i) => {
      this.isViewAccount = i;
    });
    this._userService.getCurrentUserDetails().subscribe((i)=>{
      this._userService.setCurrentUser(i.data);
      this._userService.updatePhoto(i.data.displayPhoto);
    });
  }

  viewAccount(value: boolean) {
    this._navigationService.isViewAccount = value;
    this.isViewAccount = value;
  }
}
