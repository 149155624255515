<div class="filter-container">
  <h6 class="title">Filter By</h6>
  <div class="filter-form">
    <form [formGroup]="filterForm" class="filter-form">
      <div class="input-wrapper">
        <mat-label>Date</mat-label>
        <mat-form-field
          appearance="fill"
          floatLabel="never"
          class="filter-form-field-date"
        >
          <input
            matInput
            [matDatepicker]="fromPicker"
            formControlName="fromDate"
            placeholder="11 Oct 2020 - 19 July 2022"
          />
          <mat-datepicker-toggle matSuffix [for]="fromPicker">
            <mat-icon></mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #fromPicker></mat-datepicker>
        </mat-form-field>
        
      </div>
      <span class="date-field txt-color">To</span>
      <div class="input-wrapper date-field">
        <mat-form-field
          appearance="fill"
          floatLabel="never"
          class="filter-form-field-date"
        >
          <input
            matInput
            [matDatepicker]="toPicker"
            formControlName="toDate"
            placeholder="11 Oct 2020 - 19 July 2022"
            [min]="minDate"
           
          />
          <mat-datepicker-toggle matSuffix [for]="toPicker">
            <mat-icon></mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #toPicker></mat-datepicker>
        </mat-form-field>
        
      </div>
    </form>
    <div class="input-wrapper">
      <mat-label>Email Address</mat-label>
      <mat-form-field
        appearance="fill"
        floatLabel="never"
        class="filter-form-field-email"
      >
        <input
          type="email"
          placeholder="jdelacruz@email.com"
          matInput
          [(ngModel)]="email"
          class="textfieldReg"
        />
      </mat-form-field>
    </div>
    <div class="input-wrapper">
      <mat-label>Type</mat-label>
      <mat-form-field
        appearance="fill"
        floatLabel="never"
        class="filter-form-field"
      >
      <mat-select [(ngModel)]="objectType" placeholder="Object Type">
        <mat-option [value]="'accounts'">Accounts</mat-option>
        <mat-option [value]="'folder'">Folder</mat-option>
        <mat-option [value]="'documents'">Documents</mat-option>
        <mat-option [value]="'images'">Images</mat-option>
      </mat-select>
      </mat-form-field>
    </div>
    <button type="submit" class="btn-submit" (click)="submit()">Filter</button>
  </div>
</div>
