<div class="cancel-container">
  <div class="cancel">
    <img src="../../../assets/images/torn-page.svg" alt="" />
    <div class="text">
      <h3 class="title">
        This document has been {{ expired ? "expired" : "voided" }}
      </h3>
    </div>
    <div class="buttons">
      <button class="return" (click)="return()">Back to dashboard</button>
    </div>
  </div>
</div>
