
<div class="spinner-container">
  <img src="../../../../assets/angkat/file-created.svg" alt="" />

  <div
    style="
      font-family: DM Sans;
      font-size: 32px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0px;
      text-align: center;
    ">
    Document successfully processed
  </div>
  <div>redirecting to document...</div>
</div>
