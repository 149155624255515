<div class="list-folder">
  <div class="folder-nav">
    <span class="back" (click)="goToLogin()"> < Back to Login</span>
    <span><img src="../../../assets/images/forma/Forma-logo-white.svg" class="logo"></span>
  </div>
  <div class="fill-flex">
    <app-table
      [title]="''"
      [hasCheckBox]="hasCheckBox"
      [columns]="filesCols"
      [canOpenFile]="true"
      (modal)="createFolder($event)"
      [type]="'create-folder'"
      [paginate]="true"
      [pageSize]="50"
      [showBreadcrumbs]="true"
      [data]="filesData | async"
      [folderFunctions]="folderFunctions"
      [selection]="selection"
      [breadcrumbBack]="onFolderBack"
      [rowFunction]="openFolder"
      [nameFunction]="openPdf"
      (modalCloses)="modalCloses($event)"
      (selectedItems)="selectedIds=$event"
      [isChecked]="isAllChecked"

    >
      <div class="extra-buttons" *ngIf="multipleFileActionsFlag">
        <button matRipple class="extra-button" (click)="bulkDownload()">
          Download
        </button>
        <button matRipple class="extra-button" (click)="deleteBulkFiles()">Delete</button>
        <button matRipple class="extra-button" (click)="moveBulk()">Move</button>
        <!-- <button matRipple class="extra-button">Duplicate</button> -->
      </div>
    </app-table>
  </div>
</div>
