import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.sass'],
})
export class ContainerComponent implements OnInit, OnDestroy {
  isNotPasswordRecovery:boolean=true;
  route: string = '';
  private subs = new Subscription;
  constructor(private _router: Router) {}

  ngOnInit(): void {
    // this.checkUrl();
    // this.subs=this._router.events.subscribe((i) => {
    //   this.checkUrl();
     
    // });
  }
  checkUrl(){
    this.route = this._router.url;
      if(this.route.includes('recover-password-success')||this.route.includes('reset-password')){
        this.isNotPasswordRecovery=false;
      }else{
        this.isNotPasswordRecovery=true;
      }
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
