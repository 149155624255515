<div class="template-billing-container">
  <div class="header-container">
    <h1 class="title">{{title}}</h1>
    <app-breadcrumb [data]="breadcrumbs"></app-breadcrumb>
  </div>

  <div class="content">
    <mat-card class="billing">
      <img
        class="file-icon"
        src="../../../assets/images/file-icon.svg"
        alt=""
      />
      <div class="billing-content">
        <div class="billing-summary">
          <div class="template-billing">
            <div class="template-details">
              <h3 class="template-name">
                {{ template.name || "Nondisclosure Agreement" }}
              </h3>
              <h4 class="template-pages">{{ template.size || "5 pages" }}</h4>
            </div>
            <p class="template-price">{{ template.rate || "PHP 199.99" }}</p>
          </div>
          <div class="divider"></div>
          <div class="total-container">
            <h4 class="total-text">
              <span>Total:</span> {{ template.rate || "PHP 199.99" }}
            </h4>
          </div>
        </div>
        <div class="billing-form" >
          <div class="form-group">
            <label id="payment-options"> Payment Options</label>
            <mat-radio-group
              aria-labelledby="payment-options"
              class="payment-options-group"
              [(ngModel)]="payment"
              [ngModelOptions]="{standalone: true}"
              
            >
              <mat-radio-button class="payment-option" [value]="paymentOption.card">
                Credit/Debit Card
                <img
                  class="file-icon"
                  src="../../../assets/images/credit-card.svg"
                  alt=""
                />
              </mat-radio-button>
              <mat-radio-button class="payment-option" [value]="paymentOption.paypal">
                PayPal
                <img
                  class="file-icon"
                  src="../../../assets/images/paypal.svg"
                  alt=""
                />
              </mat-radio-button>
              <mat-radio-button class="payment-option" [value]="paymentOption.ewallet">
                e-Wallet
                <img
                  class="file-icon"
                  src="../../../assets/images/e-wallet.svg"
                  alt=""
                />
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="form-section">
            <div class="form-group">
              <mat-label class="label">Card Number</mat-label>
              <mat-form-field
                class="form input"
                floatLabel="never"
                appearance="fill"
              >
                <input type="text" placeholder="xxxx xxxx xxxx xxxx" matInput [(ngModel)]="cardNumber"/>
              </mat-form-field>
            </div>
            <div class="inputs">
              <div class="form-group">
                <mat-label class="label">Expiry</mat-label>
                <mat-form-field
                  class="form input"
                  floatLabel="never"
                  appearance="fill"
                >
                  <input type="text" placeholder="MM/YY " matInput [(ngModel)]="cardExpiry" />
                </mat-form-field>
              </div>
              <div class="form-group">
                <mat-label class="label">CVC</mat-label>
                <mat-form-field
                  class="form input"
                  floatLabel="never"
                  appearance="fill"
                >
                  <input type="text" placeholder="xxx" matInput [(ngModel)]="cvc" />
                </mat-form-field>
              </div>
            </div>
            <button matRipple class="confirm-button" (click)="confirmSubscription()">Confirm</button>
          </div>
        </div>
      </div>
    </mat-card>
    <div class="preview" *ngIf="url.search('templates')===1">
      <app-preview [id]="id"></app-preview>
    </div>
  </div>
</div>
