import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileActionService } from '../services/file-action.service';
import { FilesService } from '../services/files.service';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.sass'],
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  @Input() isSearch: boolean = false;
  @Input() searchResult: any;
  count: number = 12;
  keyword: string = 'Senate of the Phils';
  prevPosition: string = 'Dashboard';
  hasCheckBox: boolean = environment.flag.multiFileSelect;
  filesCols: string[] = [];
  filesData: Object[] = [];
  sub: Subscription = new Subscription();
  constructor(
    private _fileService: FilesService,
    private _fileActionService: FileActionService,
    private _navigationService: NavigationService,
    private _route: ActivatedRoute,
    private _router:Router,
    private _location: Location,
  ) {
    this.prevPosition = this._navigationService.getLastLocation();
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.filesCols = this._fileService.filesCols;
    this._fileService.searchResults$.subscribe((data) => {
      this.filesData = data.data;
      this.count = this.filesData.length;
      this.keyword = data.searchQuery;
    });

    this.sub.add(
      this._route.params
        .pipe(
          switchMap(({ query }) => {
            this.keyword = query;
            return this._fileService.searchFiles(query);
          })
        )
        .subscribe(({ data }: any) => {
          this.filesData = this._fileService.formatSearchResults(data || []);
          this.count = data.length;
        })
    );
  }
  backMenu() {
    //this._router.navigateByUrl('/dashboard');
    this._location.back();
  }

  openPdf = (data: any)=> {
    if (data.file_type === 'folder' || data.file_type === 'back') return;

    let url =
      data?.action?.choices?.download?.path ||
      data?.actions?.choices?.download?.path ||
      data?.action?.download?.path ||
      data?.actions?.download?.path;

    if (
      data.fileExtension == 'docx' ||
      data.fileExtension == 'doc' ||
      data.fileExtension == 'xls' ||
      data.fileExtension == 'xlsx' ||
      data.fileExtension == 'ppt' ||
      data.fileExtension == 'pptx'
    ) {
      let office = '';
      if (data.fileExtension == 'docx' || data.fileExtension == 'doc') {
        office = 'ms-word';
      }
      if (data.fileExtension == 'xls' || data.fileExtension == 'xlsx') {
        office = 'ms-excel';
      }
      if (data.fileExtension == 'ppt' || data.fileExtension == 'pptx') {
        office = 'ms-powerpoint';
      }

      window.location.assign(
        office + ':ofv|u|' + window.location.origin + '/assets/' + url
      );
      return;
    }
    //url = `../../../assets/${url}`;
    
    this._fileActionService.pdfSrc = url;
    this._fileActionService.file = data;
    this._fileActionService.setIsViewFile(true);
    this._router.navigateByUrl(`/file-view/${data.fileExtension}/${data.id}`);
  }
}
