import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IntegrationModel } from '../document-creation/document-creation.component';
import { SignSecureService } from 'src/app/sign-secure/services/sign-secure.service';
import { MatPaginator } from '@angular/material/paginator';

export interface TableModel {
  title: string;
  date_created: number;
  recent_action: number;
  last_update: string;
  status: string;
  data?: any;
}

@Component({
  selector: 'angkat-table',
  templateUrl: './angkat-table.component.html',
  styleUrls: ['./angkat-table.component.sass'],
})
export class AngkatTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input()
  isPaginate: boolean = true;
  @Input() resultsLength: number = 0;
  @Input()
  pageSize: number = 5;

  @ViewChild('paginator')
  paginator!: MatPaginator;
  displayedColumns: string[] = [
    'title',
    'date_created',
    'recent_action',
    'last_update',
    'status',
  ];
  @Input()
  dataSource!: TableModel[] | null;
  @Input()
  urlData!: IntegrationModel;

  matDataSource: any;

  constructor(
    private _router: Router,
    private _ar: ActivatedRoute,
    private _signSecureService: SignSecureService
  ) {}
  ngAfterViewInit(): void {}
  ngOnInit(): void {
    console.log(this.dataSource);
  }
  ngOnChanges(changes: SimpleChanges): void {
    const tableDataArray: TableModel[] = [];
    if (this.dataSource) {
      this.dataSource = this.dataSource.sort(
        (a: any, b: any) => b.updatedAt - a.updatedAt
      );
      this.dataSource.forEach((data: any) => {
        const auditTrail = data.workflow.auditTrail.pop();
        const tableData: TableModel = {
          title: data.name,
          date_created: data.createdAtFormatted,
          recent_action: auditTrail.details,
          last_update: data.updatedAtFormatted,
          status: data.workflow.status,
          data: data,
        };
        tableDataArray.push(tableData);
      });
    }
    this.matDataSource = new MatTableDataSource(tableDataArray || []);
    this.matDataSource.paginator = this.paginator;
  }
  openPdf(data: any) {
    this._signSecureService.dirId = data.id;
    this._signSecureService.setWorkFlowData(data);
    this._router.navigate([`/sign-secure/${data.id}`], {
      relativeTo: this._ar,
    });
  }

}
