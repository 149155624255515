<ng-container>
  <div class="container">
    <div class="body margin-container">
      <div class="recents {{ userType }}">
        <div class="statistics">
          <app-progress-card
            [image]="'../../../assets/images/icons/file-icon.png'"
            [title]="'All Documents'"
            [number]="dashData.allDocuments"
            [location]="'documents'"
            (filterWorkflowDataEvent)="filterWorkflowData(filterEnum.all)"
          ></app-progress-card>
          <app-progress-card
            [image]="'../../../assets/images/icons/drafts-icon.svg'"
            [title]="'Viewed'"
            [number]="dashData.viewed"
            [location]="'viewed'"
            (filterWorkflowDataEvent)="filterWorkflowData(filterEnum.viewed)"
          ></app-progress-card>
          <app-progress-card
            [image]="'../../../assets/images/icons/pending-icon.svg'"
            [title]="'Pending'"
            [number]="dashData.pending"
            [location]="'pending'"
            (filterWorkflowDataEvent)="filterWorkflowData(filterEnum.pending)"
          ></app-progress-card>
          <app-progress-card
            [image]="'../../../assets/images/icons/signed-icon.svg'"
            [title]="'Signed'"
            [number]="dashData.signed"
            [location]="'signed'"
            (filterWorkflowDataEvent)="filterWorkflowData(filterEnum.signed)"
          ></app-progress-card>
          <app-progress-card
            [image]="'../../../assets/images/icons/completed-icon.svg'"
            [title]="'Completed'"
            [number]="dashData.completed"
            [location]="'completed'"
            (filterWorkflowDataEvent)="filterWorkflowData(filterEnum.completed)"
          ></app-progress-card>
        </div>
  
        <div  class="recents {{ userType }}">
          <app-table
          [title]="signSecureDocumentTitle"
          [data]="signSecureDocumentData"
          [columns]="signSecureDocumentCols"
          [canOpenFile]="true"
          [type]="'recent-documents'"
          (modalCloses)="recentDocumentModal($event)"
          [rowFunction]="openSignSecureDoc"
          [paginate]="true"
          [addButton]="'Use SignSecure'"
          [addButtonIcon]="'../../../../assets/images/signature-icon.svg'"
          (modal)="useSignSecure()"
          [pageSize]="5"
          class="signsecure"
        >
        <div class="tableSearch">
          <div class="search-form">
            <mat-form-field floatLabel="never" appearance="fill">
              <input
                matInput
                type="text"
                placeholder="Input Your Text in Here"
                [(ngModel)]="searchQuery"
                (keydown.enter)="search()"
              />
              <span matPrefix
                ><img
                  class="search-image"
                  src="../../../assets/images/icons/search.png"
                  alt=""
              /></span>
            </mat-form-field>
            <button type="submit" class="search-button" (click)="search()">
              Search
            </button>
          </div>
        </div>
        </app-table>
  
        </div>
        <div class="recent-admin">

          <app-table
            [title]="recentDocmentTitle"
            [data]="recentDocumentData | async"
            [columns]="recentDocuemtCols"
            [canOpenFile]="true"
            [type]="'recent-documents'"
            (modalCloses)="recentDocumentModal($event)"
            [nameFunction]="openPdf"
            style="flex-grow: 1"
          ></app-table>
          <app-table
            *ngIf="userType === 'admin'"
            [title]="recentUsersTitle"
            [data]="recentUsersData"
            [columns]="recentUsersCols"
            style="flex-grow: 1"
          ></app-table>
        </div>

        <app-table
          *ngIf="userType === 'user'"
          [title]="'Recent Folders'"
          [data]="recentFolders"
          [columns]="recentFoldersCols"
          [rowFunction]="openFolder"
          (modalCloses)="recentDocumentModal($event)"
          style="flex-grow: 1"
        ></app-table>
      </div>
      
    </div>
    <div class="bottom-row">
      <app-dashboard-banner></app-dashboard-banner>
    </div>
  </div>
</ng-container>
