import { NgModule } from '@angular/core';
import { GuestDashboardComponent } from './guest-dashboard/guest-dashboard.component';
import { SidePanelComponent } from './components/side-panel/side-panel.component';
import { AngkatTableComponent } from './components/angkat-table/angkat-table.component';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  MAT_DATE_FORMATS,
  MatNativeDateModule,
  MatRippleModule,
} from '@angular/material/core';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { HttpErrorInterceptor } from '../@shared/Interceptor/http-error-interceptor.interceptor';
import { Interceptor } from '../@shared/Interceptor/interceptor';
import { LoaderInterceptor } from '../@shared/Interceptor/loader.interceptor';
import { UploadInterceptor } from '../@shared/Interceptor/upload.interceptor';
import { DATE_FORMAT } from '../@shared/constants/date-format.const';
import { BrowserModule } from '@angular/platform-browser';
import { MatTableModule } from '@angular/material/table';
import { AngkatRoutingModule, routes } from './angkat-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AppComponent } from '../app.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgImageSliderModule } from 'ng-image-slider';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { AppRoutingModule } from '../app-routing.module';
import { SignSecureModule } from '../sign-secure/sign-secure.module';
import { AngkatComponent } from './angkat.component';
import { RouterModule } from '@angular/router';
import { SigningPageComponent } from '../sign-secure/signing-page/signing-page.component';
import { DocumentCreationComponent } from './components/document-creation/document-creation.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { OverlaySpinnerComponent } from './components/spinner/overlay-spinner.component';
import { FileCreatedComponent } from './components/spinner/file-created/file-created.component';
import { CreationFailedComponent } from './components/spinner/creation-failed/creation-failed.component';
import { ModalComponent } from '../components/modal/modal.component';
import { AppModule } from '../app.module';
@NgModule({
  declarations: [
    GuestDashboardComponent,
    SidePanelComponent,
    AngkatTableComponent,
    AngkatComponent,
    DocumentCreationComponent,
    OverlaySpinnerComponent,
    FileCreatedComponent,
    CreationFailedComponent,
    
  ],
  imports: [
    BrowserModule,
    AppModule,
    AngkatRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    MatTableModule,
    MatTabsModule,
    FormsModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatMenuModule,
    FontAwesomeModule,
    HttpClientModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatTreeModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatRadioModule,
    NgxMatIntlTelInputComponent,
    PdfViewerModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatMomentDateModule,
    NgImageSliderModule,
    MatProgressBarModule,
    MatRippleModule,
    SignSecureModule,
    NgIdleKeepaliveModule,
    MatListModule,
    PdfViewerModule,
    OverlayModule,
    MatDialogModule,
    MatProgressSpinnerModule
  ],
  exports: [],
  bootstrap: [AngkatComponent],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UploadInterceptor, multi: true },
    DatePipe,
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})
export class AngkatModule {}
