import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.sass'],
})
export class FileViewerComponent implements OnInit, OnDestroy {
  type: string = '';
  pdfSrc: string = '';
  id: string = '';
  subs: Subscription = new Subscription();

  constructor(private _route: ActivatedRoute) {}
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.subs.add(
      this._route.params.subscribe(({ id, type }) => {
        this.type = type;
        this.id = id;
      })
    );
  }
}
