import { NgModule } from '@angular/core';
import { SigningPageCancelComponent } from './signing-page-cancel/signing-page-cancel.component';
import { SigningPageComponent } from './signing-page/signing-page.component';
import { SigningPageContentComponent } from './signing-page-content/signing-page-content.component';
import { CdkMenuModule } from '@angular/cdk/menu';
import { SigningPageNavbarComponent } from './signing-page-navbar/signing-page-navbar.component';
import { MatSelectModule } from '@angular/material/select';
import { AuditTrailComponent } from './audit-trail/audit-trail.component';
import { DocumentDetailsComponent } from './document-details/document-details.component';
import { SigningPageDoneComponent } from './signing-page-done/signing-page-done.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CdkPortalOutlet, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../@shared/spinner/spinner.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AuditTrailDetailsComponent } from './audit-trail/audit-trail-details/audit-trail-details.component';
import { AppModule } from '../app.module';
import { KonvaModule } from 'ng2-konva';
import { FormsModule } from '@angular/forms';
import { DocumentPartiesComponent } from './document-details/document-parties/document-parties.component';
import { DocumentPartyComponent } from './document-details/document-party/document-party.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuditTrailDetailComponent } from './audit-trail/audit-trail-detail/audit-trail-detail.component';
import { CreateDocumentComponent } from './create-document/create-document.component';
import { StepsComponent } from './admin-template/steps/steps.component';
import { StepComponent } from './admin-template/step/step.component';
import { TemplateNavbarComponent } from './admin-template/template-navbar/template-navbar.component';
import { SignatureFieldPreviewComponent } from './admin-template/signature-field-preview/signature-field-preview.component';
import { SignatureFieldComponent } from './admin-template/signature-field/signature-field.component';
import { UploadTemplateComponent } from './admin-template/progress-screens/upload-template/upload-template.component';
import { TemplateDetailsComponent } from './admin-template/progress-screens/template-details/template-details.component';
import { TemplatePartiesComponent } from './admin-template/progress-screens/template-parties/template-parties.component';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ReviewComponent } from './admin-template/progress-screens/review/review.component';
import { MatRadioModule } from '@angular/material/radio';
import { CompleteComponent } from './admin-template/progress-screens/complete/complete.component';
import { AddFieldsComponent } from './admin-template/progress-screens/add-fields/add-fields.component';
import { PartyListComponent } from './admin-template/party-list/party-list.component';
import { PartyComponent } from './admin-template/party/party.component';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatRippleModule,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  AutomaticRemindersComponent,
  MY_FORMATS,
} from './admin-template/automatic-reminders/automatic-reminders.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AppRoutingModule } from '../app-routing.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { LocalizationService } from './services/localization.service';
import { CreateSignatureComponent } from './signature-modal/create-signature/create-signature.component';
import { CreateInitialsComponent } from './signature-modal/create-initials/create-initials.component';
import { AddSignatureComponentComponent } from './signature-modal/add-signature-component/add-signature-component.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { AcceptRejectComponent } from './workflow-modal/accept-reject/accept-reject.component';
import { CancelDocumentSuccessComponent } from './workflow-modal/cancel-document-success/cancel-document-success.component';
import { CancelDocumentComponent } from './workflow-modal/cancel-document/cancel-document.component';
import { DeclineDocumentComponent } from './workflow-modal/decline-document/decline-document.component';
import { QrRestrictedComponent } from './qr-modal/qr-restricted/qr-restricted.component';
import { QrScannerComponent } from './qr-modal/qr-scanner/qr-scanner.component';
import { QrVerificationFailsComponent } from './qr-modal/qr-verification-fails/qr-verification-fails.component';
import { QrVerificationSuccessComponent } from './qr-modal/qr-verification-success/qr-verification-success.component';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { DragDropFileUploadDirective } from './directives/drag-drop-file-upload.directive';

@NgModule({
  declarations: [
    SigningPageCancelComponent,
    SigningPageComponent,
    SigningPageContentComponent,
    SigningPageNavbarComponent,
    AuditTrailComponent,
    DocumentDetailsComponent,
    SigningPageDoneComponent,
    AuditTrailDetailsComponent,
    AuditTrailDetailComponent,
    DocumentPartiesComponent,
    DocumentPartyComponent,
    CreateDocumentComponent,
    StepsComponent,
    StepComponent,
    TemplateNavbarComponent,
    SignatureFieldPreviewComponent,
    SignatureFieldComponent,
    UploadTemplateComponent,
    TemplateDetailsComponent,
    TemplatePartiesComponent,
    ReviewComponent,
    CompleteComponent,
    AddFieldsComponent,
    PartyListComponent,
    PartyComponent,
    AutomaticRemindersComponent,
    SpinnerComponent,
    CreateSignatureComponent,
    CreateInitialsComponent,
    AddSignatureComponentComponent,
    AcceptRejectComponent,
    CancelDocumentComponent,
    CancelDocumentSuccessComponent,
    DeclineDocumentComponent,
    QrVerificationSuccessComponent,
    QrRestrictedComponent,
    QrScannerComponent,
    QrVerificationFailsComponent,
    DragDropFileUploadDirective
  ],
  imports: [
    CommonModule,
    PortalModule,
    MatSidenavModule,
    CdkMenuModule,
    PdfViewerModule,
    KonvaModule,
    MatSelectModule,
    FormsModule,
    MatTooltipModule,
    KonvaModule,
    MatCardModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    DragDropModule,
    MatRadioModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatTabsModule,
    AngularSignaturePadModule,
    NgxScannerQrcodeModule,
    MatIconModule,
    MatListModule,
    MatRippleModule,
    
  ],
  exports: [
    SpinnerComponent,
    CreateDocumentComponent,
    CreateSignatureComponent,
    CreateInitialsComponent,
    AddSignatureComponentComponent,
    AcceptRejectComponent,
    CancelDocumentComponent,
    CancelDocumentSuccessComponent,
    DeclineDocumentComponent,
    QrVerificationSuccessComponent,
    QrRestrictedComponent,
    QrScannerComponent,
    QrVerificationFailsComponent,
    
  ],
  providers: [
    LocalizationService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SignSecureModule {}
