import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import { FileActionService } from 'src/app/@shared/services/file-action.service';
import { FilesService } from 'src/app/@shared/services/files.service';
import { ModalComponent } from '../modal/modal.component';
import { Location } from '@angular/common';
import { Subscriber, Subscription, catchError, forkJoin, lastValueFrom, of } from 'rxjs';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { SignSecureService } from 'src/app/sign-secure/services/sign-secure.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { QrService } from 'src/app/sign-secure/services/qr.service';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PDFDocument, PDFName, PDFString, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import { PdfViewerComponent as PDFViewer } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.sass'],
})
export class PdfViewerComponent implements OnInit {
  pdfSrc: any = '';
  @Input() id: string = '';
  zoom: number = 1;
  totalPages: number = 0;
  pageNumber: number = 1;
  showSignatureButton: boolean = false;
  showShareButton: boolean = false;
  fileData: any;
  pdf: any;
  subs = new Subscription();

  showQRButton: boolean = false;
  showQRModal: string = 'none';

  overlayHeight: string = '100%';
  @ViewChild('viewer')
  pdfViewer!: PDFViewer;
  @ViewChild('content')
  overlay!: ElementRef;

  isPublished: boolean = false;

  qrPosition: string = 'top-right';
  fileLink: string = '';

  disableQRButton: boolean = false;
  origPdfSrc: any;
  constructor(
    public dialog: MatDialog,
    private _fileActionService: FileActionService,
    private _fileService: FilesService,
    private _router: Router,
    private _location: Location,
    private _user: AuthenticationService,
    private _signsecure: SignSecureService,
    private _nav: NavigationService,
    private _auth: AuthenticationService,
    private _qr: QrService,
    private _loader: LoaderService,
    private _sign: SignSecureService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.subs = forkJoin([
      this._fileService.getFile(this.id),
      this._fileService
        .viewDirectory(this.id)
        .pipe(catchError(error => of(error))),
      this._fileService.getProxyUrl(this.id),
    ]).subscribe(([fileData, fileViewed, proxyUrl]) => {
      this.fileData = fileData?.data;
      console.log(this.fileData);
      if (fileData.data) {
        this.showSignatureButton =
          !this._fileService.checkIfShared(fileData.data) &&
          !!this._user.userId;
        console.log('file', fileData.data);
        this.showShareButton = this._fileService.checkIfUserViewer(
          fileData.data
        );
        console.log(this.showShareButton);
      }
      this.disableQRButton = !(this.fileData.isQRAdded || false);
      this.showQRButton = this._auth.userId === fileData?.data.createdBy;
      this.checkPublished(this.fileData);
      const fileReader = new FileReader();
      fileReader.onload = () => {
        this.pdfSrc = new Uint8Array(fileReader.result as ArrayBuffer);
      };
      fileReader.readAsArrayBuffer(proxyUrl);
    });
  }

  checkFile() {}

  zoomLevel(level: string) {
    switch (level) {
      case 'in':
        if (this.zoom <= 2.75) {
          this.zoom = +this.zoom + +0.25;
        }
        break;
      case 'out':
        if (this.zoom >= 0.5) {
          this.zoom = this.zoom - 0.25;
        }
        break;
      default:
        break;
    }
  }

  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pdf = pdf;
    this.totalPages = pdf.numPages;
    this.overlay.nativeElement.addEventListener(
      'scroll',
      this.onScroll.bind(this)
    );
  }

  pageNavi(navi: string) {
    switch (navi) {
      case 'up':
        if (this.pageNumber > 1) this.pageNumber = this.pageNumber - 1;
        break;
      case 'down':
        if (this.pageNumber < this.totalPages)
          this.pageNumber = this.pageNumber + 1;

        break;
      default:
        break;
    }
  }

  goBack() {
    this._fileActionService.setIsModify(false);
    this._fileActionService.setIsViewFile(false);
    //this._router.navigate(['..']);
    this._location.back();
  }

  download(name?: string) {
    this._fileService.downloadPDF(this.fileData.name, this.pdfSrc);
  }
  openModal() {
    const file = this.fileData;
    const dialogRef = this.dialog?.open(ModalComponent, {
      width: '636px',
      data: {
        action: 'share-permission',
        title: file.name,
        fileId: file.id,
        data: [...file.userGroups, ...file.users] || [],
      },
    });
    // dialogRef?.afterClosed()?.subscribe((result) => {
    //   this.callback.emit(result);
    // });
  }

  print() {
    try {
      this.pdf.getData().then((u8: any) => {
        let blob = new Blob([u8.buffer], {
          type: 'application/pdf',
        });

        const blobUrl = window.URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe?.contentWindow?.print();
      });
    } catch (e) {
      window.print();
    }
  }

  async showQRScreen() {
    if (!this.disableQRButton) {
      return;
    }
    console.log(this.pdfSrc,this.fileData);
    if (this.showQRModal === 'none') {
      this.origPdfSrc = this.pdfSrc;
      await this.addQrCode();
      const height =
        this.pdfViewer.pdfViewerContainer?.nativeElement?.scrollHeight;
      this.overlayHeight = `${height + 90}px`;
      console.log({ height: this.overlayHeight });
      this.showQRModal = 'flex';
    } else {
      this.showQRModal = 'none';
      this.pdfSrc = this.origPdfSrc;
    }
  }

  async qrPositionChanged(event: any) {
    const position = event?.value ?? 'top-right';
    await this.addQrCode(position);
  }
  async addQrCode(position: string = 'top-right') {
    const link = `${window.location.origin}/share/${this.fileData.fileExtension}/${this.fileData.id}`;
    const pdfDoc = await PDFDocument.load(this.origPdfSrc);
    const qrcode = await this._qr.getQRCode(link);
    const pages = pdfDoc.getPages();
    const pagesLength = pages?.length ?? 0;
    const fontBytes = await fetch('../../../assets/fonts/DMSans.ttf').then(
      res => res.arrayBuffer()
    );
    pdfDoc.registerFontkit(fontkit);
    const docFont = await pdfDoc.embedFont(fontBytes);
    for (let page = 0; page < pagesLength; page++) {
      const image = await pages[page].doc.embedPng(qrcode);
      const size = pages[page].getSize();
      const pos = this._qr.getQRCodeConfig(position, size.height, size.width);
      pages[page].drawImage(image, { ...pos, width: 55, height: 55 });
    }
    this.pdfSrc = await pdfDoc.save();
  }
  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    const { target } = event;
    this.pdfViewer.pdfViewerContainer.nativeElement.scrollTop =
      target.scrollTop;
  }
  save() {
    this.showQRModal = 'none';
    this._loader.show();
    const file = this._sign.bytesToFile(
      this.pdfSrc,
      this.fileData.name ?? 'updated-file'
    );
    this._fileService
      .updateFile(file, this.fileData.id)
      .subscribe(i => {
        this.fileLink = `./assets/${i.data.path}`;
        this._fileService
          .updateQRAdded(this.fileData.id)
          .subscribe(() => {
            this._loader.hide();
            this.disableQRButton = false;
          });
      });
  }

  checkPublished(file: any) {
    file?.tags?.forEach((tag: any) => {
      if (tag.name.toLowerCase() == 'published') {
        this.isPublished = true;
      }
    });
    console.log(file, this.isPublished);
    this.isPublished = file.isPublished;
  }

  async useSignSecure(name?: string) {
    if (this.isPublished) return;

    const file =
      this._fileActionService?.file?.actions?.download ||
      this._fileActionService?.file?.actions?.choices?.download ||
      this._fileActionService?.file?.actions?.download_normal;

    const bytes = await lastValueFrom(this._fileService.getProxyUrl(file.id))
    const arrayBuffer = await bytes.arrayBuffer()
    console.log({bytes})
    this._signsecure.clearWorkFlowData();

    const fileObj = this._signsecure.bytesToFile(arrayBuffer, file.name);
    this._signsecure.setId(file.id);
    this._signsecure.setFile(fileObj, file.id);
    this._signsecure.setFileInfo(file.name, file.description);
    this._signsecure.saveWorkflowData();
    this._sign.nextProgress(2);
    this._nav.setLocation('sign-secure')
  }

}
