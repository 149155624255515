import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminDashboardAuditLogComponent } from '../@core/admin-dashboard/admin-dashboard-audit-log/admin-dashboard-audit-log.component';
import { AdminDashboardFolderFilesComponent } from '../@core/admin-dashboard/admin-dashboard-folder-files/admin-dashboard-folder-files.component';
import { AdminDashboardManageUserGroupsComponent } from '../@core/admin-dashboard/admin-dashboard-manage-user-groups/admin-dashboard-manage-user-groups.component';
import { AdminDashboardManageUserComponent } from '../@core/admin-dashboard/admin-dashboard-manage-user/admin-dashboard-manage-user.component';
import { AdminDashboardReportComponent } from '../@core/admin-dashboard/admin-dashboard-report/admin-dashboard-report.component';
import { AdminDashboardSubscriptionComponent } from '../@core/admin-dashboard/admin-dashboard-subscription/admin-dashboard-subscription.component';
import { AdminDashboardTemplatesComponent } from '../@core/admin-dashboard/admin-dashboard-templates/admin-dashboard-templates.component';
import { AdminDashboardWrapperComponent } from '../@core/admin-dashboard/admin-dashboard-wrapper/admin-dashboard-wrapper.component';
import { ContainerComponent } from '../@core/container/container.component';
import { GoogleLoginComponent } from '../@core/google-login/google-login.component';
import { AccountComponent } from '../@core/menu/account/account.component';
import { DocumentsComponent } from '../@core/menu/document/documents.component';
import { FaqComponent } from '../@core/menu/faq/faq.component';
import { InboxComponent } from '../@core/menu/inbox/inbox.component';
import { SignatureComponent } from '../@core/menu/signature/signature.component';
import { SupportPageComponent } from '../@core/menu/support-page/support-page.component';
import { WorkflowComponent } from '../@core/menu/workflow/workflow.component';
import { RegistrationComponent } from '../@core/registration/registration.component';
import { PasswordChangedComponent } from '../@core/reset-password/password-changed/password-changed.component';
import { ResetPasswordComponent } from '../@core/reset-password/reset-password.component';
import { SettingsComponent } from '../@core/settings/settings.component';
import { ShareFilesComponent } from '../@core/share-files/share-files.component';
import { SignInComponent } from '../@core/sign-in/sign-in.component';
import { EmailSentComponent } from '../@core/verification-screens/email-sent/email-sent.component';
import { RecoverPasswordComponent } from '../@core/verification-screens/recover-password/recover-password.component';
import { FileViewComponent } from '../@shared/file-view/file-view.component';
import { SearchResultsComponent } from '../@shared/search-results/search-results.component';
import { AuthguardService } from '../@shared/services/authguard.service';
import { SignSecureAuthguardService } from '../@shared/services/signsecure-authguard';
import { TemplateBillingComponent } from '../components/billing/billing.component';
import { FileViewerComponent } from '../components/file-viewer/file-viewer.component';
import { GuestFolderViewComponent } from '../components/guest-folder-view/folder-view.component';
import { VerifyAccountComponent } from '../components/verify-account/verify-account.component';
import { CreateDocumentComponent } from '../sign-secure/create-document/create-document.component';
import { SigningPageComponent } from '../sign-secure/signing-page/signing-page.component';
import { GuestDashboardComponent } from './guest-dashboard/guest-dashboard.component';
import { DocumentCreationComponent } from './components/document-creation/document-creation.component';

const notLogin: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login/google-login', component: GoogleLoginComponent },

  { path: 'login', component: SignInComponent },
  { path: 'recover-password', component: RecoverPasswordComponent },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
  },
  {
    path: 'reset-password/success/:token',
    component: PasswordChangedComponent,
  },
  { path: 'reset-password-success', component: EmailSentComponent },
  // { path: 'document/:docId?token', component: EmailSentComponent }, // token == guest token
];

const login: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: AdminDashboardReportComponent },
  { path: 'inbox', component: InboxComponent },
  { path: 'documents', component: DocumentsComponent },
  { path: 'folders-and-files/:id', component: FileViewComponent },
  {
    path: 'folders-and-files/folder/:id',
    component: AdminDashboardFolderFilesComponent,
  },
  {
    path: 'folders-and-files',
    component: AdminDashboardFolderFilesComponent,
    pathMatch: 'full',
  },
  { path: 'workflow', component: WorkflowComponent },
  { path: 'templates/:id', component: TemplateBillingComponent },
  {
    path: 'templates',
    component: AdminDashboardTemplatesComponent,
    pathMatch: 'full',
  },
  { path: 'account', component: AccountComponent },
  { path: 'signatures', component: SignatureComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'support', component: SupportPageComponent },
  { path: 'manage-users', component: AdminDashboardManageUserComponent },
  { path: 'manage-groups', component: AdminDashboardManageUserGroupsComponent },
  { path: 'audit-logs', component: AdminDashboardAuditLogComponent },
  { path: 'search/:query', component: SearchResultsComponent },
  {
    path: 'account/subscription',
    component: AdminDashboardSubscriptionComponent,
  },
  {
    path: 'account/subscription/payment',
    component: TemplateBillingComponent,
  },
  { path: 'settings', component: SettingsComponent },
  { path: 'workflow', component: WorkflowComponent },
];

const fullScreenViews = [
  { path: 'file-view/:type/:id', component: FileViewerComponent },
  { path: 'folder-view/:id', component: GuestFolderViewComponent },
  { path: 'register', component: RegistrationComponent },
  { path: 'verify-account/:token', component: VerifyAccountComponent },
  { path: 'not-verified-account', component: VerifyAccountComponent },
  { path: 'share/:type/:id', component: ShareFilesComponent },
  {
    path: 'sign-secure',
    component: CreateDocumentComponent,
  },
  {
    path: 'sign-secure/:id',
    component: SigningPageComponent,
  },
];

export const routes: Routes = [
  {
    path: 'angkat/:host/:api-key/:api-secret/:userId',
    component: GuestDashboardComponent,
  },
  {
    path: 'angkat/createDoc/:host/:api-key/:api-secret/:userId/:workflowId',
    component: DocumentCreationComponent,
  },
  {
    path: 'sign-secure/:id',
    component: SigningPageComponent,
  },
  { path: '', component: ContainerComponent, children: notLogin },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AngkatRoutingModule {}
