<mat-card class="container">
   <app-verification-success
    *ngIf="type == 'verification-success'"
  ></app-verification-success>
  <app-verifitaion-fail
    *ngIf="type == 'verification-fail'"
  ></app-verifitaion-fail>
  <app-recover-password
    *ngIf="type == 'recoverPassword'"
    (emailSentEvent)="changeType($event)"
  ></app-recover-password>
  <app-email-sent
    *ngIf="type == 'email-sent-reg' || type == 'email-resetpw'"
    [type]="type"
    (returnEvent)="returnScreen($event)"
  ></app-email-sent> 
</mat-card>
