<ng-container *ngIf="isLoading$ | async"
  >
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
    <div
      style="
        font-family: DM Sans;
        font-size: 32px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: center;
      ">
      Document creation in progress, please wait...
    </div>
  </div>
</ng-container>
