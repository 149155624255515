<div class="navbar-container">
  <div class="search-container">
    <div class="search-form">
      <mat-form-field floatLabel="never" appearance="fill">
        <input
          matInput
          type="text"
          placeholder="Input Your Text in Here"
          [(ngModel)]="searchQuery"
          (keydown.enter)="search()"
        />
        <span matPrefix
          ><img
            class="search-image"
            src="../../../assets/images/icons/search.png"
            alt=""
        /></span>
      </mat-form-field>
      <button type="submit" class="search-button" (click)="search()">
        Search
      </button>
    </div>
  </div>
  <div class="right-side">
    <button matRipple class="upload" [matMenuTriggerFor]="uploadMenu">
      Add Document <img src="../../../assets/images/icons/plus.png" alt="" />
    </button>
    <mat-menu #uploadMenu="matMenu" xPosition="before">
      <div class="upload-menu-item">
        <button
          mat-menu-item
          class="menu-label"
          (click)="showUploadDragAndDropModal()"
        >
          <img src="../../../assets/images/forma/upload-document.png" alt="" />
          Upload document
          <input
            type="file"
            id="fileUpload"
            name="fileUploads"
            style="display: none"
            (change)="onFileSelected($event)"
            accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
            multiple
          />
        </button>
        <a routerLink="/templates" mat-menu-item class="menu-label" *ngIf="isFormaCommercial">
          <img
            src="../../../assets/images/forma/small-template-icon.png"
            alt=""
          />
          Create from template
        </a>
      </div>
    </mat-menu>
    <button matRipple class="avatar-container" [matMenuTriggerFor]="accountMenu">
      <img [src]="pictureSrc" alt="Avatar" />
    </button>
    <mat-menu #accountMenu="matMenu" xPosition="before">
      <button mat-menu-item class="menu-label" (click)="viewAccount()">
        <img
          class="mini-avatar"
          [src]="miniPictureSrc"
          alt="smallAvatar"
        />
        View Account
      </button>
      <button mat-menu-item class="menu-label" (click)="logout()">
        <img src="../../../assets/images/icons/logout.png" alt="logout" /> Log
        out
      </button>
    </mat-menu>
  </div>
</div>
