<div class="document-details__content">
  <app-spinner></app-spinner>
  <div class="section">
    <div class="title">
      <h4>Document Details</h4>
      <img
        src="../../../assets/images/close-icon.svg"
        alt=""
        (click)="close.emit(true)" />
    </div>

    <p>
      <strong>Author:</strong> {{ data?.author?.name }} ({{
        data?.author?.email
      }})
    </p>
    <p><strong>Document Title:</strong> {{ data.name }}</p>
    <p><strong>Document Description:</strong> {{ data.description }}</p>
  </div>

  <div class="section">
    <h4>Email</h4>

    <p><strong>Subject:</strong> {{ email?.subject }}</p>
    <p><strong>Message:</strong> {{ email?.message || 'None' }}</p>
  </div>

  <div class="section">
    <h4>Parties</h4>

    <app-document-parties [data]="parties"></app-document-parties>
  </div>
  <div class="section">
    <h4>Expiration and reminder options</h4>
    <p>
      <strong>Document expiry date: </strong>
      {{ reminders.expiration }}
    </p>
    <p>
      <strong>Send reminder in: </strong>
      {{ reminders.reminderInXDays }}
    </p>
    <p>
      <strong>Send reminder every: </strong>
      {{ reminders.reminderEveryXDays }}
    </p>
    <p>
      <strong>Send reminder before expiration: </strong>
      {{ reminders.reminderBeforeXDays }}
    </p>
  </div>
</div>
