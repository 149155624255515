import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { dashFilter } from '../enums/dashboard-filter.enum';

@Injectable({
  providedIn: 'root',
})
export class DashBoardServices {
  private readonly baseUrl = environment.apiConfig.baseUrl;
  private readonly dashboardUrl = environment.apiConfig.dashboard.url;
  appVersion: string = '';
  constructor(private _httpClient: HttpClient) {}
  getDashboardData() {
    return this._httpClient.get<any>(`${this.baseUrl}${this.dashboardUrl}/data`);
  }
  filterDashboardData(filter:dashFilter) {
    let params = new HttpParams();
    params = params.append('type',filter);
    return this._httpClient.get<any>(`${this.baseUrl}${this.dashboardUrl}/documents`,{params:params});
  }
  getAppVersion() {
    return this._httpClient.get<any>(`${this.baseUrl}/appdetails`);
  }
}
