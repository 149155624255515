<div class="spinner-container">
  <img src="../../../../assets/angkat/angkat-close.svg" alt="" />

  <div
    style="
      font-family: DM Sans;
      font-size: 32px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0px;
      text-align: center;
    ">
    Document failed to process
  </div>
  <div>Please try again</div>
  <button class="back-button">Go back</button>
</div>
