import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  filter,
  observable,
  Observable,
  of,
  switchMap,
  take,
} from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { LoaderService } from '../services/loader.service';
import { FilesService } from '../services/files.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  isRefreshing: boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    private _authService: AuthenticationService,
    private _loaderService: LoaderService,
    private _fileService: FilesService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      !request.url.includes('auth/login') &&
      !request.url.includes('/integration/login')&&
      !request.url.includes('directory/user/create') &&
      !request.url.includes('api/v1/user/create') &&
      !request.url.includes('api/v1/user') &&
      !request.url.includes('/forgot-password')&&
      !request.url.includes('/change-password')&&
      !request.url.includes('/auth/register') &&
      !request.url.includes('auth/confirm-registration') &&
      !request.url.includes('/auth/reset-password') &&
      !request.url.includes('/directory/view/') && 
      !request.url.includes('/directory/verify/') &&
      !request.url.includes('/admin/user/create') &&
      !request.url.includes('integration/workflow?')
    ) {
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if (
            request.url.includes('file-document/create/') ||
            request.url.includes('folder/create') ||
            request.url.includes('directory/user-group/create') ||
            request.url.includes('directory/user/create') ||
            (/^.+directory\/\w+$/.test(request.url) &&
              error.error.status !== 401)
          ) {
            return next.handle(request);
          } 
          this._loaderService.hide();

          if (this._authService.token) {
            if (error.status == 401) {
              return this.tokenRefresher(request, next);
            }
          }
          return of();
        })
      );
    } else {
      return next.handle(request);
    }
  }

  private tokenRefresher(request: HttpRequest<unknown>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      if (this._authService.token) {
        return this._authService.refreshToken().pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            this._authService.authenticateUser(token.data.accessToken);
            this.refreshTokenSubject.next(this._authService.token);
            return next.handle(this.addTokenHeader(request));
          }),
          catchError((err) => {
            this.isRefreshing = false;

            //this._authService.logout();
            return of();
          })
        );
      }
    }
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: { authorization: `Bearer ${this._authService.token}` },
    });
  }
}
