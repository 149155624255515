import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  interval,
  of,
  Subscription,
  switchMap,
  takeUntil,
  takeWhile,
} from 'rxjs';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { SignatureService } from 'src/app/@shared/services/signature.service';
import { UsersService } from 'src/app/@shared/services/users.service';

@Component({
  selector: 'app-add-signature',
  templateUrl: './add-signature.component.html',
  styleUrls: ['./add-signature.component.sass'],
})
export class AddSignatureComponent implements OnInit, OnDestroy {
  @Output() closeModalEvent = new EventEmitter();
  sub = new Subscription();
  timer = new Subscription();
  time = 100;
  image: string = '';
  value: any;
  reader = new FileReader();
  otp: any = '';
  otpValue: string = '';
  otpError: string = '';
  constructor(
    private _signatureService: SignatureService,
    private _userService: UsersService,
    private _auth: AuthenticationService
  ) {
    this.reader.onload = (e: any) =>
      (this.image = this.reader.result as string);
  }

  ngOnInit(): void {}
  ngOnDestroy() {
    this.sub.unsubscribe();
    this.timer.unsubscribe();
  }

  onChangeOption({ value }: any) {}

  dragover(data: FileList) {
    this.value = data[0];
    this.image = data[0].name;
    this.reader.readAsDataURL(this.value);
    // this.closeModalEvent.emit(data);
  }

  checkIfContainsFolder(data: FileList) {
    for (let i = 0; i < data.length; i++) {
      if (data.item(i)?.type === '') {
        return true;
      }
    }
    return false;
  }

  onFileSelected(data: any) {
    const regex = /.*\.(jpg|JPG|png|PNG)$/g;
    let files = [];

    if (data.path) {
      this.value = data.path[0].files[0];
      this.image = this.value.name;
      this.reader.readAsDataURL(this.value);
    } else if (data.target) {
      this.value = data.target.files[0];
      this.image = this.value.name;
      this.reader.readAsDataURL(this.value);
    }
    // this.closeModalEvent.emit(files);
  }
  startTimer() {
    this.timer = interval(1000).subscribe((timeData) => {
      
      this.time = this.time - 1;
    });
    return this.timer;
  }
  upload() {
    document.getElementById('fileUpload')?.click();
  }

  close() {
    this.closeModalEvent.emit();
  }

  chooseAnother() {
    this.image = '';
    this.value = {};
    this.upload();
  }

  sendOTP() {
    this.otp = 'otp';

    this.sub = this._userService
      .generateOTP()
      .pipe(
        switchMap(() => {
          return interval(1000).pipe(
            takeWhile(val=>
              this.time >= 1
            )
          );
        })
      )
      .subscribe((timeData) => {
        this.time = this.time - 1;
      });
  }

  inputOtp(data: any) {
    this.otpValue = data?.target?.value || '';
    
  }

  verifyOtp() {
    
    this.sub.add(
      this._userService
        .validateOTP(this.otpValue)
        .pipe(
          switchMap((data) => {
            

            if (data.data.valid) {
              return this._signatureService.addSignature({
                name: `signature-${this._auth.userId}-${
                  this._signatureService.total + 1
                }`,
                file: this.value,
              });
            } else {
              this.otpError = 'Invalid OTP';
              return of();
            }
          })
        )
        .subscribe((i) => {
          

          this.close();
        })
    );
  }
}
