import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { SignSecureService } from '../services/sign-secure.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signing-page-done',
  templateUrl: './signing-page-done.component.html',
  styleUrls: ['./signing-page-done.component.sass']
})
export class SigningPageDoneComponent implements OnInit {

  @Input()
  data: any

  lastLocation: string = '' 
  constructor(private _sign: SignSecureService, private _nav: NavigationService, private _router:Router) { }

  ngOnInit(): void {
    this.lastLocation = this._nav.getLastLocation()
  }

  download() {
    var blob = new Blob([this._sign.signPdfData], { type: "application/pdf" });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = this._sign.signPdfName;
    link.download = fileName;
    link.click();
  }

  return() {
    const prev = this._nav.prevLocation !== this._nav.path ? this._nav.prevLocation : '/dashboard'
    this._router.navigateByUrl(prev)
  }


}
