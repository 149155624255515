<app-spinner></app-spinner>
<div class="container margin-container">
  <div class="header-container">
    <h4 class="title">My Account</h4>
    <div class="right" *ngIf="isFormaCommercial">
      <h5 class="title">Personal Plan</h5>
      <a>745 MB of 1 GB used</a>
      <mat-progress-bar
        class="progress-bar"
        mode="determinate"
        value="74.5"
      ></mat-progress-bar>
    </div>
  </div>
  <div class="cards-container">
    <div class="account-container">
      <div class="account-title">Profile Picture</div>
      <div class="account-sub-title">Update your photo here</div>
      <div class="profile-picture-container">
        <div class="left">
          <img [src]="pictureSrc" />
          <button matRipple class="upload-picture-btn" (click)="upload()">
            Choose file
          </button>
        </div>
        <div class="right">
          <button matRipple class="create" (click)="updateDisplayPhoto()">
            Save Photo
          </button>
        </div>
      </div>
      <div class="account-title">Personal Info</div>
      <div class="account-sub-title">Update your personal information here</div>
      <div class="form-field-pair label padding-top-20">
        <div class="input-group">
          <mat-label>First Name</mat-label>
          <mat-form-field
            class="input-form-pair"
            floatLabel="never"
            appearance="fill"
          >
            <input
              type="text"
              matInput
              class="textfieldReg"
              [(ngModel)]="givenName"
            />
          </mat-form-field>
        </div>
        <div class="input-group">
          <mat-label>Last Name</mat-label>
          <mat-form-field
            class="input-form-pair"
            floatLabel="never"
            appearance="fill"
          >
            <input [(ngModel)]="lastName" matInput />
          </mat-form-field>
        </div>
      </div>

      <div class="form-field-pair label padding-top-20">
        <div class="input-group">
          <mat-label>Email</mat-label>
          <mat-form-field
            class="input-form-pair"
            floatLabel="never"
            appearance="fill"
          >
            <input
              type="email"
              [(ngModel)]="email"
              matInput
              class="textfieldReg readonly"
              disabled
            />
          </mat-form-field>
        </div>
        <form [formGroup]="editAccountForm">
          <div class="input-group">
            <mat-label>Mobile Number</mat-label>
            <mat-form-field
              class="input-form-pair"
              floatLabel="never"
              appearance="fill"
            >
              <ngx-mat-intl-tel-input
                [preferredCountries]="['ph']"
                formControlName="phoneNumber"
                #phone
              ></ngx-mat-intl-tel-input>

              <!--<input type="text" matInput class="textfieldReg" />!-->
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="btnGrp">
        <!--<button matRipple class="cancel" (click)="close()">Cancel</button>!-->
        <button matRipple class="create" (click)="updateProfile()">
          Update personal info
        </button>
      </div>

      <div class="account-title">Change Password</div>
      <div class="account-sub-title">Update your password here</div>
      <div class="flex">
        <mat-label class="label">Current Password</mat-label>
      </div>
      <div class="flex-column">
        <mat-form-field
          class="input-form-pair label"
          floatLabel="never"
          appearance="fill"
          [ngClass]="!errorMsg ? 'input-form-pair' : 'input-form-pair-error'"
        >
          <input
            [type]="hideOldPw ? 'password' : 'text'"
            [(ngModel)]="oldPassword"
            placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
            matInput
            class="textfieldReg"
            (ngModelChange)="errorMsg = ''; errorStatus = 0"
          />
          <mat-icon matSuffix (click)="hideOldPw = !hideOldPw">{{
            hideOldPw ? "visibility_off" : "visibility"
          }}</mat-icon>
        </mat-form-field>
        <ng-container *ngIf="oldPassword">
          <mat-error
            class="error"
            *ngIf="oldPassword.length > 0 && errorStatus >= 403"
            >{{ errorMsg }}</mat-error
          >
        </ng-container>
      </div>
      <div class="form-field-pair label padding-top-20">
        <div class="input-group">
          <mat-label>New Password</mat-label>
          <mat-form-field
            class="input-form-pair"
            floatLabel="never"
            appearance="fill"
            [ngClass]="!errorMsg&&isPwordValid ? 'input-form-pair' : 'input-form-pair-error'"
          >
            <input
              [type]="hideNewPw ? 'password' : 'text'"
              [(ngModel)]="newPassword"
              placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
              matInput
              (ngModelChange)="errorMsg = ''; errorStatus = 0; checkPword()"
            />
            <mat-icon matSuffix (click)="hideNewPw = !hideNewPw">{{
              hideNewPw ? "visibility_off" : "visibility"
            }}</mat-icon>
          </mat-form-field>
          <ng-container *ngIf="newPassword">
            <span *ngIf="!isPwordValid" style="color:red">
              Password must be at least 8 characters<br />
              Contains at least 1 lower case<br />
              Contains at least 1 upper case<br />
              Contains at least 1 number<br />
              Contains at least 1 special character
            </span>
            <mat-error
              class="error"
              *ngIf="newPassword.length > 0 && errorStatus == 1"
              >{{ errorMsg }}</mat-error
            >
          </ng-container>
        </div>
        <div class="input-group">
          <mat-label>Confirm Password</mat-label>
          <mat-form-field
            class="input-form-pair"
            floatLabel="never"
            appearance="fill"
            [ngClass]="!errorMsg ? 'input-form-pair' : 'input-form-pair-error'"
          >
            <input
              [type]="hideConfirm ? 'password' : 'text'"
              [(ngModel)]="confirmPassword"
              (ngModelChange)="errorMsg = ''; errorStatus = 0"
              placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
              matInput
            />
            <mat-icon matSuffix (click)="hideConfirm = !hideConfirm">{{
              hideConfirm ? "visibility_off" : "visibility"
            }}</mat-icon>
          </mat-form-field>
          <ng-container *ngIf="confirmPassword">
            <mat-error
              class="error"
              *ngIf="confirmPassword.length > 0 && errorStatus == 1"
              >{{ errorMsg }}</mat-error
            >
          </ng-container>
        </div>
      </div>

      <div class="btnGrp">
        <!--<button matRipple class="cancel" (click)="close()">Cancel</button>!-->
        <button matRipple class="create" (click)="updatePassword()">
          Change Password
        </button>
      </div>
    </div>

    <div class="subscription-container" *ngIf="isFormaCommercial">
      <span class="subscription-title">Subscriptions</span>
      <span class="subscription-sub-title">Manage your subscriptions </span>

      <div class="subscriptions">
        <div class="card-group">
          <mat-card class="progress-card">
            <div class="subs">
              <div class="title-group">
                <span class="subscription-title">ForMA</span>
                <span class="subscription-title">PHP 0.00</span>
              </div>
              <span class="subscription-sub-title">Business Plan </span>
              <span class="subs-colored-sub-title"
                >Next billing cycle on Dec 1 2022</span
              >
            </div>
            <div class="footer">
              <p>See all</p>
              <img src="../../../assets/images/icons/next.png" alt="" />
            </div>
          </mat-card>
          <span class="cancel-sub" (click)="cancelSub()"
            >Cancel Subscription
          </span>
        </div>

        <div class="card-group">
          <mat-card class="progress-card">
            <div class="subs">
              <div class="title-group">
                <span class="subscription-title">SignSecure</span>
                <span class="subscription-title">PHP 0.00</span>
              </div>
              <span class="subscription-sub-title">Personal Plan </span>
              <span class="subs-colored-sub-title"
                >Next billing cycle on Dec 1 2022</span
              >
            </div>
            <div class="footer">
              <p>See all</p>
              <img src="../../../assets/images/icons/next.png" alt="" />
            </div>
          </mat-card>
          <span class="cancel-sub" (click)="cancelSub()"
            >Cancel Subscription
          </span>
        </div>
      </div>

      <div class="btm-row">
        <button matRipple class="button" (click)="addSubscription()">
          Add a new subscription
        </button>
      </div>
    </div>
  </div>
</div>

<input
  type="file"
  id="fileUpload"
  name="fileUpload"
  style="display: none"
  (change)="onFileSelected($event)"
  accept=".jpg,.png,.pdf,.docx,.doc,.xlsx,.xls,.ppt,.pptx"
  multiple
/>
