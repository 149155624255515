<ng-container *ngIf="documentProgress | async as progress">
	<div class="container {{ progress }}">
		<app-template-navbar
			class="header"
			[step]="progress"
			(review)="review($event)"
			[pageNumber]="pdfDetails?.page ?? 0"
			[totalPages]="pdfDetails?.total ?? 0"
			[updatePage]="pdfDetails?.updatePage ?? null"
			[addedAll]="addedAll"
		></app-template-navbar>
		<div class="body">
			<mat-card class="card-body card-{{ progress }}">
				<ng-container [ngSwitch]="progress">
					<ng-container
						*ngSwitchCase="'1'"
						[ngTemplateOutlet]="Upload"
					></ng-container>
					<ng-container
						*ngSwitchCase="'2'"
						[ngTemplateOutlet]="Details"
					></ng-container>
					<ng-container
						*ngSwitchCase="'3'"
						[ngTemplateOutlet]="Parties"
					></ng-container>
					<ng-container
						*ngSwitchCase="'4'"
						[ngTemplateOutlet]="Fields"
					></ng-container>
					<ng-container
						*ngSwitchCase="'5'"
						[ngTemplateOutlet]="Reviews"
					></ng-container>
					<ng-container
						*ngSwitchCase="'6'"
						[ngTemplateOutlet]="Complete"
					></ng-container>
					<ng-container
						*ngSwitchDefault
						[ngTemplateOutlet]="Default"
					></ng-container>
				</ng-container>
			</mat-card>
		</div>
	</div>
</ng-container>

<ng-template #Default></ng-template>
<ng-template #Upload>
	<app-upload-template></app-upload-template>
</ng-template>

<ng-template #Details>
	<app-template-details></app-template-details>
</ng-template>

<ng-template #Parties>
	<app-template-parties></app-template-parties>
</ng-template>

<ng-template #Fields>
	<app-add-fields
		(data)="setSignatureData($event)"
		(pdfDetails)="setPdfDetails($event)"
		(allHaveSignatures)="addedAllSignatories($event)"
		(qr)="updateQRPosition($event)"
		[qrPosition]="qr"
	></app-add-fields>
</ng-template>

<ng-template #Reviews>
	<app-review></app-review>
</ng-template>

<ng-template #Complete>
	<app-complete></app-complete>
</ng-template>
