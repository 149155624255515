<ng-container [ngSwitch]="details.type">
  <ng-container
    *ngSwitchCase="'signature'"
    [ngTemplateOutlet]="signature"
  ></ng-container>
  <ng-container
    *ngSwitchCase="'signature-name'"
    [ngTemplateOutlet]="signature_name"
  ></ng-container>
  <ng-container
    *ngSwitchCase="'signature-date'"
    [ngTemplateOutlet]="signature_date"
  ></ng-container>
  <ng-container
    *ngSwitchCase="'signature-name-designation'"
    [ngTemplateOutlet]="signature_name_designation"
  ></ng-container>
  <ng-container *ngSwitchCase="'name'" [ngTemplateOutlet]="name"></ng-container>
  <ng-container
    *ngSwitchCase="'initials'"
    [ngTemplateOutlet]="initials"
  ></ng-container>
  <ng-container
    *ngSwitchCase="'designation'"
    [ngTemplateOutlet]="designation"
  ></ng-container>
  <ng-container
    *ngSwitchCase="'date-time'"
    [ngTemplateOutlet]="date_time"
  ></ng-container>
  <ng-container
    *ngSwitchCase="'textbox'"
    [ngTemplateOutlet]="textbox"
  ></ng-container>
  <!-- <ng-container *ngSwitchDefault [ngTemplateOutlet]="Default"></ng-container> -->
</ng-container>

<ng-template #signature>
  <div class="signature-content" [ngStyle]="{ 'background-color': color }">
    <p class="document-id">BCC715E2F75C42F</p>
    <p class="content" [style.color]="textColor">[Signature will be placed here]</p>
  </div>
</ng-template>

<ng-template #signature_name>
  <div class="signature-content" [ngStyle]="{ 'background-color': color }">
    <p class="document-id">BCC715E2F75C42F</p>
    <p class="content" [style.color]="textColor">
      [Signature will be placed here]
    </p>
  </div>
  <p class="name">{{ signatory.name}}</p>
</ng-template>

<ng-template #signature_date>
  <div class="signature-content" [ngStyle]="{ 'background-color': color }">
    <p class="document-id">BCC715E2F75C42F</p>
    <p class="content" [style.color]="textColor">
      [Signature will be placed here]
    </p>
  </div>
  <p class="date">02/22/2023 12:16 PM</p>
</ng-template>

<ng-template #signature_name_designation>
  <div class="signature-content" [ngStyle]="{ 'background-color': color }">
    <p class="document-id">BCC715E2F75C42F</p>
    <p class="content" [style.color]="textColor">
      [Signature will be placed here]
    </p>
  </div>
  <p class="name">{{ signatory.name}}</p>
  <p class="designation">{{ signatory.designation}}</p>
</ng-template>

<ng-template #name>
  <p class="name">{{ signatory.name}}</p>
</ng-template>

<ng-template #initials>
  <div class="signature-content" [ngStyle]="{ 'background-color': color }">
    <p class="document-id">BCC715E2F75C42F</p>
    <p class="content" [style.color]="textColor">
      [Initials will be placed here]
    </p>
  </div>
</ng-template>

<ng-template #designation>
  <p class="designation">Designation</p>
</ng-template>

<ng-template #date_time>
  <p class="date">02/22/2023 12:16 PM</p>
</ng-template>

<ng-template #textbox>
  <div class="signature-content" [ngStyle]="{ 'background-color': color }">
    <p class="content"  [style.color]="textColor">[Text will be placed here]</p>
  </div>
</ng-template>
