<ng-container>
  <div class="add-new-user">
    <h4 class="title">Create Group</h4>
    <div class="content">
      <div class="input-group margin-bottom-30">
        <mat-label class="label">Group Name</mat-label>
        <mat-form-field floatLabel="never" appearance="fill">
          <input
            type="name"
            matInput
            placeholder="Legal"
            [(ngModel)]="groupName"
          />
        </mat-form-field>
      </div>
      <div class="input-group">
        <mat-label class="label">Add members to the group</mat-label>
        <mat-form-field floatLabel="never" appearance="fill">
          <input
            type="email"
            placeholder="jdoe@email.com"
            (keydown.enter)="addMember()"
            matInput
            [(ngModel)]="groupMember"
            [matAutocomplete]="auto"
            [formControl]="userListControl"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of userlist | async"
              [value]="option"
              (click)="addMember()"
            >
              {{ option.email }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <mat-chip-list class="members">
        <mat-chip
          class="member-tag"
          *ngFor="let member of groupMembers; let i = index"
          >{{ member.email }}
          <img
            src="../../../assets/images/icons/close.svg"
            alt=""
            (click)="removeMember(i)"
          />
        </mat-chip>
      </mat-chip-list>
      <div class="actions">
        <button matRipple class="cancel" (click)="closeModal()">Cancel</button>
        <button matRipple class="create" (click)="addUserGroup()">Save</button>
      </div>
    </div>
  </div>
</ng-container>
