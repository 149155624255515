<div class="workflow padding-top-50">
  <div class="header">
    <h4 class="title">WorkFlow</h4>
    <button class="header-button" (click)="redirectUrl()">
      Use SignSecure
    </button>
  </div>
  <mat-tab-group class="">
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="label-container">
          <img src="../../../../assets/images/icons/myDoc.svg" />
          <span>Default Workflow</span>
        </div>
      </ng-template>
      <app-table
        [title]="'workflow'"
        [columns]="filesCols"
        [type]="'recent-documents'"
        [data]="data"
        [hideTitle]="true"
        [rowFunction]="rowFunction"
        [paginate]="true"
      ></app-table>
    </mat-tab>
    <ng-container *ngIf="templateFlag">
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="label-container">
            <img src="../../../../assets/images/icons/teamDoc.svg" />
            <span>Workflow Templates</span>
          </div>
        </ng-template>
        <app-table
          [title]="'workflow'"
          [columns]="filesCols"
          [data]="data"
          [hideTitle]="true"
        ></app-table>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</div>
