import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { InboxService } from '../services/inbox.service';

@Component({
  selector: 'app-inbox-view',
  templateUrl: './inbox-view.component.html',
  styleUrls: ['./inbox-view.component.sass'],
})
export class InboxViewComponent implements OnInit {
  @Output() goBackEvent = new EventEmitter<boolean>();
  message: any;
  html!: SafeResourceUrl;
  constructor(private _inboxService: InboxService) {}

  ngOnInit(): void {
    this._inboxService.message$.subscribe((data) => {
      this.message = data;
      this.html = data.html;
    });
  }
  goBack() {
    this.goBackEvent.emit(false);
  }
}
