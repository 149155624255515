import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, map, switchMap, tap } from 'rxjs';
import { AngkatDashboardService } from 'src/app/@shared/services/angkat-services/angkat-dashboard.service';
import { IntegrationModel } from '../components/document-creation/document-creation.component';
import { TableModel } from '../components/angkat-table/angkat-table.component';
import { SignSecureService } from 'src/app/sign-secure/services/sign-secure.service';

@Component({
  selector: 'angkat-guest-dashboard',
  templateUrl: './guest-dashboard.component.html',
  styleUrls: ['./guest-dashboard.component.sass'],
})
export class GuestDashboardComponent implements OnInit {
  urlData!: IntegrationModel;
  user: string = '';

  tableData$ = this.activatedRoute.params.pipe(
    tap(params => {
      this.urlData = {
        'api-key': decodeURIComponent(params['api-key']),
        'api-secret': decodeURIComponent(params['api-secret']),
        host: params['host'],
        userId: params['userId'],
        workflowId: params['workflowId'],
      };

      this._signSecureService.userId = this.urlData.userId;
      this._signSecureService.urlData = this.urlData;
    }),
    switchMap(data => {
      console.log(data);
      return this._angkatDS.getWorkflowFileAll(this.urlData).pipe(
        tap(data => {
          const workflowArray = data.data.data;
          this.user = workflowArray[0].workflow.parties[1]?.name;
        }),
        map(data => {
          return data.data.data;
        })
      );
    })
  );
  constructor(
    private _angkatDS: AngkatDashboardService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _signSecureService: SignSecureService
  ) {}

  ngOnInit(): void {}
  logout() {
    this.router.navigate(['/login'], { replaceUrl: true });
  }
}
