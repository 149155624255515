// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  local: false,
  qrCodeKey: 'TagvpfaaztHecpmK5Lrb',
  apiConfig: {
    baseUrl: 'https://forma-angkat-api.unawa.dev/api/v1',
    auth: { url: '/auth' },
    user: { url: '/user' },
    admin: { url: '/admin' },
    userGroup: { url: '/user-group' },
    folder: { url: '/folder' },
    file: { url: '/file-document' },
    directory: { url: '/directory' },
    support: { url: '/support' },
    message: { url: '/messages' },
    dashboard: { url: '/dashboard' },
    audit: { url: '/audit' },
    signature: { url: '/signature' },
    publish: { url: '/publish' },
    google: { url: '/google' },
    subscription: { url: '/subscription-plans' },
    settings: { url: '/settings' },
    payment: { url: '/payment' },
  },
  flag: {
    registration: true,
    workFlowTemplate: false,
    unawaLogo: false,
    multipleFileActions: true,
    multiFileSelect: true,
    signatureMenu: false,
    groupDocuments: true,
    commercial: false,
    useLocalSign: true,
    hideSidebarMenu:true,
    customColorPicker:true,
		isFormaCommercial:false
  },
  azureAD: {
    clientId: 'fa47f1aa-effd-42a2-b839-4293f84c685b', //'6f837e95-c998-4b52-beb7-1cafd1b83d2d',
    authority:
      'https://login.microsoftonline.com/4248007e-4534-45b5-a6b1-a5bedafc6510/', //f6a568ac-2e69-47d3-8219-0de781635651/',
    redirectUri: 'https://dms.unawa.dev',
  },
  integration: {
    createUser:
      'https://us-central1-sign-sc-live.cloudfunctions.net/createUser',
    authenticateUser:
      'https://us-central1-sign-sc-live.cloudfunctions.net/authenticateUser',
    signSecureWebBaseURL: 'https://sign-sc-live.web.app',
    signSecureAPIBaseURL:
      'https://us-central1-sign-sc-live.cloudfunctions.net/getUserDetails',
    formTemplatesURL: 'https://sign-sc-live.web.app/dashboard/templates',
    signaturesURL: 'https://sign-sc-live.web.app/dashboard/signatures',
    deleteDocument:
      'https://asia-east2-sign-sc-live.cloudfunctions.net/deleteDocumentV2',
    ipInfoURL: "https://ipinfo.io/",
    ipInfoApiToken: "a58848088d5bc5",
    ipifyURL: "https://api.ipify.org"
  },
  version: 'dev.0.3.3',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
