import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WrapperBackgroundComponent } from './@core/wrapper-background/wrapper-background.component';
import { LogoContainerComponent } from './components/logo-container/logo-container.component';
import { FormContainerComponent } from './components/form-container/form-container.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { VerificationContainerComponent } from './components/verification-container/verification-container.component';
import { MatIconModule } from '@angular/material/icon';
import { AdminSidebarComponent } from './components/admin-sidebar/admin-sidebar.component';
import { AdminNavbarComponent } from './components/admin-navbar/admin-navbar.component';
import { AdminDashboardWrapperComponent } from './@core/admin-dashboard/admin-dashboard-wrapper/admin-dashboard-wrapper.component';
import { TableComponent } from './@shared/table/table.component';
import { ProgressCardComponent } from './@shared/progress-card/progress-card.component';
import { MatTableModule } from '@angular/material/table';
import { TagsComponent } from './@shared/tags/tags.component';
import { ActionsComponent } from './@shared/actions/actions.component';
import { AdminDashboardContentComponent } from './components/admin-dashboard-content/admin-dashboard-content.component';
import { MatTabsModule } from '@angular/material/tabs';
import { FilterComponent } from './@shared/filter/filter.component';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatNativeDateModule,
  MatRippleModule,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ModalComponent } from './components/modal/modal.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { FileViewComponent } from './@shared/file-view/file-view.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { Interceptor } from './@shared/Interceptor/interceptor';
import { MatChipsModule } from '@angular/material/chips';
import { SignInComponent } from './@core/sign-in/sign-in.component';
import { RegistrationComponent } from './@core/registration/registration.component';
import { AdminDashboardReportComponent } from './@core/admin-dashboard/admin-dashboard-report/admin-dashboard-report.component';
import { UserDashboardReportComponent } from './@core/user-dashboard-report/user-dashboard-report.component';
import { AdminDashboardManageUserComponent } from './@core/admin-dashboard/admin-dashboard-manage-user/admin-dashboard-manage-user.component';
import { AdminDashboardManageUserGroupsComponent } from './@core/admin-dashboard/admin-dashboard-manage-user-groups/admin-dashboard-manage-user-groups.component';
import { AdminDashboardAuditLogComponent } from './@core/admin-dashboard/admin-dashboard-audit-log/admin-dashboard-audit-log.component';
import { AdminDashboardFolderFilesComponent } from './@core/admin-dashboard/admin-dashboard-folder-files/admin-dashboard-folder-files.component';
import { AddNewUserComponent } from './@core/user-modal/add-new-user/add-new-user.component';
import { EditUserComponent } from './@core/user-modal/edit-user/edit-user.component';
import { DeleteUserComponent } from './@core/user-modal/delete-user/delete-user.component';
import { AddUserGroupComponent } from './@core/user-group-modal/add-user-group/add-user-group.component';
import { EditUserGroupComponent } from './@core/user-group-modal/edit-user-group/edit-user-group.component';
import { UserGroupComponent } from './@core/user-group-modal/user-group/user-group.component';
import { DeleteUserGroupComponent } from './@core/user-group-modal/delete-user-group/delete-user-group.component';
import { UploadFileComponent } from './@core/file-modal/upload-file/upload-file.component';
import { ShareFileComponent } from './@core/file-modal/share-file/share-file.component';
import { FaqComponent } from './@core/menu/faq/faq.component';
import { AccountComponent } from './@core/menu/account/account.component';
import { AdminMenuComponent } from './components/admin-menu/admin-menu.component';
import { SentConfirmationComponent } from './@core/user-modal/sent-confirmation/sent-confirmation.component';
import { DocumentsComponent } from './@core/menu/document/documents.component';
import { InboxComponent } from './@core/menu/inbox/inbox.component';
import { HttpErrorInterceptor } from './@shared/Interceptor/http-error-interceptor.interceptor';
import { SpinnerComponent } from './@shared/spinner/spinner.component';
import { LoaderInterceptor } from './@shared/Interceptor/loader.interceptor';
import { MatTreeModule } from '@angular/material/tree';
import { DocumentTreeComponent } from './@core/menu/document-tree/document-tree.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { BreadcrumbComponent } from './@shared/breadcrumb/breadcrumb.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UploadInterceptor } from './@shared/Interceptor/upload.interceptor';
import { SearchResultsComponent } from './@shared/search-results/search-results.component';
import { RecoverPasswordComponent } from './@core/verification-screens/recover-password/recover-password.component';
import { EmailSentComponent } from './@core/verification-screens/email-sent/email-sent.component';
import { VerifitaionFailComponent } from './@core/verification-screens/verifitaion-fail/verifitaion-fail.component';
import { VerificationSuccessComponent } from './@core/verification-screens/verification-success/verification-success.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SupportPageComponent } from './@core/menu/support-page/support-page.component';
import { UploadComponent } from './@core/file-modal/upload/upload.component';
import { MatRadioModule } from '@angular/material/radio';
import { DragDropFileUploadDirective } from './@shared/directives/drag-drop-file-upload.directive';
import { InboxViewComponent } from './@shared/inbox-view/inbox-view.component';
import { SupportUploadComponent } from './@core/file-modal/support-upload/support-upload.component';
import { WorkflowComponent } from './@core/menu/workflow/workflow.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DeleteFileComponent } from './@core/file-modal/delete-file/delete-file.component';
import { OcrPreviewComponent } from './@core/ocr-modal/ocr-preview/ocr-preview.component';
import { CreateFolderComponent } from './@core/file-modal/create-folder/create-folder.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { DatePipe } from '@angular/common';
import { SignatureComponent } from './@core/menu/signature/signature.component';
import { AddSignatureComponent } from './@core/signature-modal/add-signature/add-signature.component';
import { RecentDocInfoComponent } from './@core/file-modal/recent-doc-info/recent-doc-info.component';
import { DeleteInboxComponent } from './@core/inbox-modal/delete-inbox/delete-inbox.component';
import { DeleteSignatureComponent } from './@core/signature-modal/delete-signature/delete-signature.component';
import { ImageViewComponent } from './components/image-view/image-view.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MoveFileComponent } from './@core/file-action-modal/move-file/move-file.component';
import { DuplicateFileComponent } from './@core/file-action-modal/duplicate-file/duplicate-file.component';
import { MembersFileComponent } from './@core/file-action-modal/members-file/members-file.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { DATE_FORMAT } from './@shared/constants/date-format.const';
import { AdminDashboardTemplatesComponent } from './@core/admin-dashboard/admin-dashboard-templates/admin-dashboard-templates.component';
import { ContainerComponent } from './@core/container/container.component';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { CancelSubscriptionModalComponent } from './@core/subscription-modal/cancel-subscription-modal/cancel-subscription-modal.component';
import { AdminDashboardSubscriptionComponent } from './@core/admin-dashboard/admin-dashboard-subscription/admin-dashboard-subscription.component';
import { SubscriptionCardComponent } from './@core/admin-dashboard/admin-dashboard-subscription/subscription-card/subscription-card.component';
import { TemplateBillingComponent } from './components/billing/billing.component';
import { TemplateCardComponent } from './components/template-card/template-card.component';
import { DashboardBannerComponent } from './@shared/dashboard-banner/dashboard-banner.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { TemplateBannerComponent } from './components/template-banner/template-banner.component';
import { PreviewComponent } from './components/preview/preview.component';
import { PaymentConfirmationComponent } from './@shared/subscription/payment-confirmation/payment-confirmation.component';
import { TemplatePaymentConfirmationComponent } from './@core/template-modal/template-payment-confirmation/template-payment-confirmation.component';
import { GoogleLoginComponent } from './@core/google-login/google-login.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SettingsComponent } from './@core/settings/settings.component';
import { SettingsFormComponent } from './components/settings-form/settings-form.component';
import { SettingsPreviewComponent } from './components/settings-preview/settings-preview.component';
import { SettingsGeneralComponent } from './components/settings-general/settings-general.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ColorSliderComponent } from './components/color-slider/color-slider.component';
import { ColorPaletteComponent } from './components/color-palette/color-palette.component';
import { ResetPasswordComponent } from './@core/reset-password/reset-password.component';
import { SignSecureModule } from './sign-secure/sign-secure.module';
import { SignSecureService } from './sign-secure/services/sign-secure.service';
import { DeleteDocumentComponent } from './@core/file-modal/delete-document/delete-document.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MatListModule } from '@angular/material/list';
import { VerifyAccountComponent } from './components/verify-account/verify-account.component';
import { MediaViewerComponent } from './components/media-viewer/media-viewer.component';
import { PasswordChangedComponent } from './@core/reset-password/password-changed/password-changed.component';
import { ShareFilesComponent } from './@core/share-files/share-files.component';
import { GuestFolderViewComponent } from './components/guest-folder-view/folder-view.component';
@NgModule({
  declarations: [
    AppComponent,
    WrapperBackgroundComponent,
    LogoContainerComponent,
    FormContainerComponent,
    VerificationContainerComponent,
    AdminSidebarComponent,
    AdminNavbarComponent,
    AdminDashboardWrapperComponent,
    TableComponent,
    ProgressCardComponent,
    TagsComponent,
    ActionsComponent,
    AdminDashboardContentComponent,
    FilterComponent,
    ModalComponent,
    FileViewComponent,
    SignInComponent,
    RegistrationComponent,
    AdminDashboardReportComponent,
    UserDashboardReportComponent,
    AdminDashboardManageUserComponent,
    AdminDashboardManageUserGroupsComponent,
    AdminDashboardAuditLogComponent,
    AdminDashboardFolderFilesComponent,
    AddNewUserComponent,
    EditUserComponent,
    DeleteDocumentComponent,
    DeleteUserComponent,
    AddUserGroupComponent,
    EditUserGroupComponent,
    UserGroupComponent,
    DeleteUserGroupComponent,
    UploadFileComponent,
    ShareFileComponent,
    FaqComponent,
    AccountComponent,
    AdminMenuComponent,
    SentConfirmationComponent,
    DocumentsComponent,
    InboxComponent,
    DocumentTreeComponent,
    BreadcrumbComponent,
    SearchResultsComponent,
    RecoverPasswordComponent,
    EmailSentComponent,
    VerifitaionFailComponent,
    VerificationSuccessComponent,
    SupportPageComponent,
    UploadComponent,
    DragDropFileUploadDirective,
    InboxViewComponent,
    SupportUploadComponent,
    WorkflowComponent,
    PdfViewerComponent,
    DeleteFileComponent,
    OcrPreviewComponent,
    CreateFolderComponent,
    SnackbarComponent,
    SignatureComponent,
    AddSignatureComponent,
    RecentDocInfoComponent,
    DeleteInboxComponent,
    DeleteSignatureComponent,
    ImageViewComponent,
    MoveFileComponent,
    DuplicateFileComponent,
    MembersFileComponent,
    AdminDashboardTemplatesComponent,
    ContainerComponent,
    FileViewerComponent,
    CancelSubscriptionModalComponent,
    AdminDashboardSubscriptionComponent,
    SubscriptionCardComponent,
    TemplateBillingComponent,
    TemplateCardComponent,
    DashboardBannerComponent,
    TemplateBannerComponent,
    PreviewComponent,
    PaymentConfirmationComponent,
    TemplatePaymentConfirmationComponent,
    GoogleLoginComponent,
    SettingsComponent,
    SettingsFormComponent,
    SettingsPreviewComponent,
    SettingsGeneralComponent,
    ColorPickerComponent,
    ColorSliderComponent,
    ColorPaletteComponent,
    ResetPasswordComponent,
    VerifyAccountComponent,
    MediaViewerComponent,
    PasswordChangedComponent,
    ShareFilesComponent,
    GuestFolderViewComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    MatTableModule,
    MatTabsModule,
    FormsModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatMenuModule,
    FontAwesomeModule,
    HttpClientModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatTreeModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatRadioModule,
    NgxMatIntlTelInputComponent,
    PdfViewerModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatMomentDateModule,
    NgImageSliderModule,
    MatProgressBarModule,
    MatRippleModule,
    SignSecureModule,
    NgIdleKeepaliveModule,
    MatListModule,
  ],
  exports: [],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UploadInterceptor, multi: true },
    DatePipe,
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
