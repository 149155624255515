import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Observable,
  startWith,
  map,
  Subscription,
  switchMap,
  forkJoin,
  of,
  combineLatest,
} from 'rxjs';
import { ModalData } from 'src/app/@shared/models/Modal.model';
import { UserGroupModel } from 'src/app/@shared/models/user-group.model';
import { FilesService } from 'src/app/@shared/services/files.service';
import { UserGroupService } from 'src/app/@shared/services/user-group.service';
import { UsersService } from 'src/app/@shared/services/users.service';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';

@Component({
  selector: 'app-share-file',
  templateUrl: './share-file.component.html',
  styleUrls: ['./share-file.component.sass'],
})
export class ShareFileComponent implements OnInit, OnDestroy {
  @Output() closeModalEvent = new EventEmitter();

  @Input() data: ModalData = <ModalData>{};
  groupMembers: any[] = [];
  userEmail: string = '';

  userGroups: any[] = [];
  title: string = '';
  users: any;
  userlist: Observable<any[]>;
  userListControl = new FormControl<string>('');

  userGroupList: any[] = [];
  usergrouplist: any[] = [];
  fileId?: string = '';
  shareToAnyone: boolean = false;
  subs = new Subscription();
  constructor(
    private _fileService: FilesService,
    private _userService: UsersService,
    private _userGroupService: UserGroupService,
    private _snackbar: MatSnackBar
  ) {
    this.userlist = new Observable<String[]>();
  }

  ngOnInit(): void {
    // Data Retrieval
    this.fileId = this.data.id || this.data.fileId;
    this.subs.add(
      this._fileService
        .getFile(this.fileId)
        .pipe(
          switchMap(result => {
            this.data.data =
              [...result.data.users, ...result.data.userGroups] || [];
            this.shareToAnyone = result.data.shareToAnyone;
            return combineLatest([
              this._userService.getUsers(),
              this._userGroupService.getUserGroups(),
            ]);
          })
        )
        .subscribe(([userData, groupData]) => {
          userData = userData.data;
          groupData = groupData.data;

          this._userService.setUserModel(userData);
          const uData = this.data.data;
          //filter userData
          userData.forEach((val: any) => {
            const loc = uData.findIndex((datum: any) => datum.id === val.id);
            if (
              loc > -1 &&
              !this.groupMembers.some(
                (member: any) => member.id === uData[loc]?.id
              )
            ) {
              this.addUser(val, uData[loc].role);
            }
          });

          this.users = userData;

          //format and filter userGroupData
          const formattedData = this._userGroupService.format(groupData);
          this._userGroupService.setUserGroup(formattedData);
          const gData = this.data.data;
          formattedData.forEach((val: any) => {
            const loc = gData.findIndex((datum: any) => datum.id === val.id);
            if (loc > -1) {
              this.addUserGroup(val, gData[loc].role);
            }
          });

          const filteredData = formattedData.filter(
            val => !gData.some((datum: any) => val.id === datum.id)
          );

          this.userGroupList = filteredData;
          this.usergrouplist = filteredData;
        })
    );

    this.userlist = this.userListControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        return this.filter(value as string);
      })
    );
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  addUserGroup(data: any, role: string = 'VIEWER') {
    const { value } = data;

    const groupData = value ?? data;

    this.usergrouplist = this.userGroupList.filter(val => {
      const result = this.userGroups.some(datum => datum.id === val.id);

      return val.id !== groupData.id && !result;
    });

    const formatData: any = {};
    formatData.name = groupData?.group_name || data.group_name;
    formatData.id = groupData?.id || data.id;
    formatData.role = role;

    if (value) {
      formatData.list_of_members =
        groupData['membersCount'] || groupData['no._of_members'];
    } else {
      formatData.list_of_members =
        data['membersCount'] || data['no._of_members'];
    }

    if (!this.userGroups.some(uGroup => uGroup.id === formatData.id)) {
      this.userGroups.push(formatData);
    }
  }

  removeGroup(index: number) {
    this.usergrouplist = this.userGroupList.filter(
      val => !this.userGroups.some(datum => datum.id === val.id)
    );

    this.userGroups.splice(index, 1);
  }

  segregateData(data: string[], userGroups: any[]) {
    const regexEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/g;

    data.forEach((datum: any) => {
      if (regexEmail.test(datum)) {
        this.groupMembers.push(datum);
      } else {
        const index = userGroups.findIndex(group => group.id === datum.id);
        if (index < 0) {
        } else {
          if (!this.userGroups.includes(datum)) this.userGroups.push(datum);
          userGroups.splice(index, 1);
        }
      }
    });
    this.userGroupList = userGroups;
  }

  addUser(data: any, role: string = 'VIEWER') {
    this.userEmail = '';
    const mData = {
      id: data.id,
      name: `${data.givenName} ${data.lastName}`,
      email: data.email,
      role: role,
    };
    this.groupMembers.push(mData);
  }

  removeUser(index: number) {
    this.groupMembers.splice(index, 1);
  }
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.groupMembers.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  closeModal(cancelled?: boolean) {
    if (cancelled) {
      this.closeModalEvent.emit({ cancelled });
    } else {
      this.closeModalEvent.emit({
        userGroups: this.userGroups,
        users: this.groupMembers,
      });
    }
  }

  filter(name: string): any[] {
    const filterValue = name || '';
    if (filterValue.length < 2) {
      return [];
    }
    return this.users.filter(
      (value: any) =>
        value.email.includes(filterValue) &&
        !this.groupMembers.some((member: any) => member.email === value.email)
    );
  }

  save() {
    const fileId = this.data.fileId || this.data.id;
    const length = this.userGroups.length;

    this._fileService
      .updateSharingPermission(fileId as string, {
        users: this.groupMembers,
        userGroups: this.userGroups,
        shareToAnyone: this.shareToAnyone,
      })
      .subscribe(data => {
        this.updateRecentFileData(fileId, data.data);
        this.closeModal(false);
      });
  }

  updateMemberPermission(data: any, member: any) {
    const { value } = data;
  }
  updateRecentFileData(fileId?: string, data?: any) {
    const recent = this._fileService.prevRecentFiles;
    const fileIndex = recent.findIndex(file => file.id === fileId);
    if (fileIndex > -1) {
      recent[fileIndex].actions.info.data = {
        ...recent[fileIndex].actions.info.data,
        users: data.users,
        userGroups: data.userGroups,
      };
      this._fileService.setRecentFiles(recent);
    }
  }

  copyLink() {
    const link =
      this.data.fileType == 'folder'
        ? `${window.location.host}/folder-view/${
            this.data.fileId || this.data.id
          }`
        : `${window.location.host}/file-view/${
            this.data.fileExtension ?? 'pdf'
          }/${this.data.fileId || this.data.id}`;
    navigator.clipboard.writeText(link);
    this._snackbar.openFromComponent(SnackbarComponent, {
      duration: 800,
      data: { type: 'CopyLink' },
    });
  }

  checkPublic() {
    const fileId = this.data.fileId || this.data.id;

    this._fileService
      .updateSharingPermission(fileId as string, {
        shareToAnyone: this.shareToAnyone,
      })
      .subscribe(data => {});
  }
}
