<div class="details-container">
  <div class="top">
    <h1>Add document details</h1>
    <a>Tell us more about the document to be signed.</a>
  </div>

  <div class="body">
    <div class="form-group">
      <span class="form-field">
        <mat-label>Document Title</mat-label>
        <mat-form-field floatLabel="never" appearance="fill">
          <input type="text" matInput [(ngModel)]="name" />
        </mat-form-field>
      </span>
      <span class="form-field">
        <mat-label>Document Description</mat-label>
        <mat-form-field floatLabel="never" appearance="fill">
          <input type="text" matInput [(ngModel)]="description" placeholder="Enter document description"/>
        </mat-form-field>
      </span>
    </div>
  </div>

  <div class="bottom">
    <div class="button-grp">
      <button class="back" (click)="back()">Back</button>
      <button class="next" (click)="next()">Next</button>
    </div>
  </div>
</div>
