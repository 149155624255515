import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import {
  catchError,
  connect,
  lastValueFrom,
  map,
  merge,
  of,
  share,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../@shared/services/authentication.service';
import { DashBoardServices } from '../@shared/services/dashboard.service';
import { ThemeSettings, SettingsService } from '../@shared/services/settings.service';

@Component({
  selector: 'angkat-root',
  templateUrl: './angkat.component.html',
  styleUrls: ['./angkat.component.sass'],
})
export class AngkatComponent implements OnInit {
  title = 'unawa-dms-UI';
  initialLoad: boolean = true;
  userType: string = '';
  viewPdf: boolean = false;
  viewImage: boolean = false;
  pdfSrc: string = '';
  fileType: string = '';

  primaryColor: string = '';
  menuBackgroundColor: string = '';
  backgroundColor: string = '';
  textColor: string = '';
  topbarColor: string = '';
  hoverColor: string = '';
  bc = new BroadcastChannel('idle_channel');
  sessionTimer: any;

  theme: ThemeSettings = {
    logo: '',
    primaryColor: '',
    menuBackgroundColor: '',
    backgroundColor: '',
    textColor: '',
    topbarColor: '',
    hoverColor: '',
    headingFont: '',
    subheadingFont: '',
    bodyFont: ''
  };
  constructor(
    private _authService: AuthenticationService,
    private _dashboardService: DashBoardServices,
    private _settingsService: SettingsService,
    
  ) {}

  ngOnInit() {
    this._settingsService
      .getSettings()
      .subscribe((data: any) => {
        const settings = data.data
        console.log(settings,'data is working')
        this.theme.primaryColor = settings.primaryColor;
        this.theme.menuBackgroundColor = settings.backgroundColor;
        this.theme.backgroundColor = settings.menuBackgroundColor;
        this.theme.textColor = settings.textColor;
        this.theme.topbarColor = settings.linkColor;
        this.theme.logo = settings.companyLogo
        this.theme.hoverColor = settings.hoverColor
        this._settingsService.setTheme(this.theme);
      });

    this._dashboardService.getAppVersion().subscribe((i) => {
      this._dashboardService.appVersion = i.data.version;
    });



    this._settingsService.updateTheme$.subscribe((theme) => {
      this._settingsService.updateLogo(theme.logo ?? this._settingsService.logo)
      this.primaryColor = `--primary-color: ${theme.primaryColor};`;
      this.menuBackgroundColor = `--secondary-color: ${theme.menuBackgroundColor};`;
      this.backgroundColor = `--background-color: ${theme.backgroundColor};`;
      this.textColor = `--text-color: ${theme.textColor};`;
      this.topbarColor = `--topbar-color: ${theme.topbarColor};`;
      this.hoverColor = `--hover-color: ${theme.hoverColor};`;
    });
  }
  
 
}
