import { Component, OnInit } from '@angular/core';
import { timeout } from 'rxjs';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { SignatureService } from 'src/app/@shared/services/signature.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.sass'],
})
export class SignatureComponent implements OnInit {
  columns: string[] = ['created at', 'image type', 'signature', 'actions'];
  signatures: any = this._signatureService.signatures$;

  hasCheckBox: boolean = environment.flag.multiFileSelect;
  constructor(
    private _signatureService: SignatureService,
    private _loader: LoaderService
  ) {}

  ngOnInit(): void {
    this.getSignatures();
  }

  getSignatures(wait: boolean = false) {
    this._signatureService.getSignatures().subscribe((i: any) => {
      
      const data = this._signatureService.formatSignatures(i.data);
      this._signatureService.setSignatures(data);
      if (wait) {
        this._loader.hide();
      }
    });
  }

  addSignature(data: any) {
    const { dialog, type: action, breadcrumb } = data;

    const dialogRef = dialog?.open(ModalComponent, {
      data: { action, data: breadcrumb },
    });

    dialogRef?.afterClosed()?.subscribe((data: any) => {
      // Temporary Workaround // need to raise to BE
      this._loader.show();
      setTimeout(() => {
        this.getSignatures(true);
      }, 1500);
    });
  }
}
