import {
	AfterContentChecked,
	AfterContentInit,
	AfterViewChecked,
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core"
import Konva from "konva"
import { KonvaComponent } from "ng2-konva"
import { debounceTime, Observable, of, Subject } from "rxjs"
import { Position } from "../../models/fields.model"
import { SignSecureService } from "../../services/sign-secure.service"

@Component({
	selector: "app-signature-field",
	templateUrl: "./signature-field.component.html",
	styleUrls: ["./signature-field.component.sass"],
})
export class SignatureFieldComponent
	implements OnInit, AfterViewInit, AfterContentChecked {
	@Input()
	details: any[] = []

	@Input()
	i: number = 0

	@Input()
	config: any

	@Input()
	zoom: number = 1

	@Input()
	qr: any

	@ViewChild("konvaStage")
	stage!: KonvaComponent

	transformer!: Konva.Transformer

	stageConfig: Observable<any> = of({})

	@Input()
	qrPosition: string = "top-right"

	@Output()
	location: EventEmitter<{ x: number; y: number }> = new EventEmitter()

	@Output()
	showControls: EventEmitter<any> = new EventEmitter()

	@Output()
	updateSignatureDetail: EventEmitter<{
		detail: any
		index: number
		page: number
		position: Position
	}> = new EventEmitter()

	@Output()
	isDragged: EventEmitter<any> = new EventEmitter()

	prev: number = -1

	prevConfig: any = null
	constructor(private _sign: SignSecureService) { }

	qrLayer!: Konva.Layer
	prevPosition: string = ""
	prevDetails: any[] = []
	redraw: boolean = false
	redrawCount = 0

	isControlShowing: boolean = false
	ngOnInit(): void {
		this.stageConfig = of(this.config)
		this.redrawCount = 0
	}

	ngAfterContentChecked(): void {
		this.stageConfig = of(this.config)
		if (this.stage && this.config !== this.prevConfig) {
			this.prevConfig = this.config
			const stage: Konva.Stage = this.stage.getStage()
			stage.setSize(this.config)
			stage.scale({ x: this.zoom, y: this.zoom })

			if (this.zoom !== this.prev) {
				this.prev = this.zoom
				this.redrawCount = 0
				stage.children?.forEach((child) => child.destroyChildren())

				this.ngAfterViewInit()
			}
		}

		if (
			!!this.qr &&
			(this.prevPosition !== this.qrPosition ||
				this.zoom !== this.prev ||
				(this.redraw && this.redrawCount < 5))
		) {
			this.redrawCount++
			this.prevPosition = this.qrPosition
			Konva.Image.fromURL(this.qr, (img: Konva.Image) => {
				const qrpos = this.getQRLocation()
				img.filters([Konva.Filters.RGBA])
				img.alpha(0.6)
				img.setAttrs({
					x: qrpos?.x,
					y: qrpos?.y,
					width: 84,
					height: 84,
					listening: false,
				})

				if (this.qrLayer) {
					this.qrLayer.destroy()
				}

				this.qrLayer = new Konva.Layer()
				this.qrLayer.add(img)
				this.stage?.getStage()?.add(this.qrLayer)
			})
		}
	}

	ngAfterViewInit(): void {
		console.log({ updated: "something" })
		this.prev = this.zoom
		this.prevDetails = this.details
		this.redraw = true
		const stage: Konva.Stage = this.stage.getStage()
		stage.clear()
		stage.on("dragmove", (e: any) => this.startDrag(e))
		stage.on("mousemove", (e: any) => this.mouseover(e))
		stage.on("mouseleave", (e: any) => this.mouseover(e))
		stage.on("click tap", (e: any) => this.konvaClick(e))
		stage.on("dragend", (e: any) => this.updateDetail(e))

		this.transformer = new Konva.Transformer({
			borderDash: [4],
			borderStroke: "dash",
			borderStrokeWidth: 2,
			borderEnabled: true,
			flipEnabled: false,
			padding: 8,
			draggable: true,
			resizeEnabled: false,
			rotateEnabled: false,
		})

		// this.transformer.on("transformend", (e: any) => this.resize(e));

		this.details.forEach((detail, index) => {
			const layer = new Konva.Layer(detail.layerConfig)
			layer.add(this.transformer)

			let group: Konva.Group

			if (detail.transform) {
				const { x, y, ...data } = detail.transform
				group = new Konva.Group(data)
			} else {
				group = new Konva.Group({
					id: detail.trackId,
					index,
					type: detail.type,
				})
			}

			if (detail.type === "signature") {
				group.add(new Konva.Text(this.getZoomedSettings(detail.documentConfig)))
				group.add(new Konva.Text(this.getZoomedSettings(detail.textConfig)))
				group.add(new Konva.Rect(this.getZoomedSettings(detail.config)))
			} else if (detail.type === "signature-name") {
				group.add(new Konva.Text(this.getZoomedSettings(detail.documentConfig)))
				group.add(new Konva.Text(this.getZoomedSettings(detail.nameConfig)))
				group.add(new Konva.Text(this.getZoomedSettings(detail.textConfig)))
				group.add(new Konva.Rect(this.getZoomedSettings(detail.config)))
			} else if (detail.type === "signature-date") {
				group.add(new Konva.Text(this.getZoomedSettings(detail.documentConfig)))
				group.add(new Konva.Text(this.getZoomedSettings(detail.dateConfig)))
				group.add(new Konva.Text(this.getZoomedSettings(detail.textConfig)))
				group.add(new Konva.Rect(this.getZoomedSettings(detail.config)))
			} else if (detail.type === "signature-name-designation") {
				group.add(new Konva.Text(this.getZoomedSettings(detail.documentConfig)))
				group.add(new Konva.Text(this.getZoomedSettings(detail.nameConfig)))
				group.add(
					new Konva.Text(this.getZoomedSettings(detail.desginationConfig))
				)
				group.add(new Konva.Text(this.getZoomedSettings(detail.textConfig)))
				group.add(new Konva.Rect(this.getZoomedSettings(detail.config)))
			} else if (detail.type === "initials") {
				group.add(new Konva.Text(this.getZoomedSettings(detail.documentConfig)))
				group.add(new Konva.Text(this.getZoomedSettings(detail.textConfig)))
				group.add(new Konva.Rect(this.getZoomedSettings(detail.config)))
			} else if (detail.type === "name") {
				group.add(new Konva.Text(this.getZoomedSettings(detail.nameConfig)))
			} else if (detail.type === "designation") {
				group.add(
					new Konva.Text(this.getZoomedSettings(detail.desginationConfig))
				)
			} else if (detail.type === "date-time") {
				group.add(new Konva.Text(this.getZoomedSettings(detail.dateConfig)))
			} else if (detail.type === "textbox") {
				group.add(new Konva.Text(this.getZoomedSettings(detail.textConfig)))
				group.add(new Konva.Rect(this.getZoomedSettings(detail.config)))
			}

			layer.dragBoundFunc((pos) => {
				console.log('magic', pos)

				const detail = { ...this.details[index] }
				var stageWidth = stage.width();
				var stageHeight = stage.height();

				const shapes = group.children

				// console.log({ metadata, shapes, details: this.details })
				let mappedShapes: { [key: string]: any } = {}
				shapes?.forEach((shape: any) => {
					mappedShapes[shape.attrs.id] = shape
				})

				const attr = this.getAttributes(detail, mappedShapes)
				const groupX = detail?.transform?.scaleX ?? 1
				const groupY = detail?.transform?.scaleY ?? 1

				console.log({ detail })
				let newX = attr.x + pos.x
				let newY = attr.y + pos.y

				if (newX < 0) {
					newX = 0 - attr.x
				} else if (newX + (attr.width * groupX) > stageWidth) {
					newX = stageWidth - attr.x - (attr.width * groupX)
				} else {
					newX = pos.x
				}

				if (newY < 0) {
					newY = 0 - attr.y
				} else if (newY + (attr.height * groupY) > stageHeight) {
					newY = stageHeight - attr.y - (attr.height * groupY)
				} else {
					newY = pos.y
				}

				console.log('magic', { newX, newY, originX: attr.x, originY: attr.y, stageWidth })
				return {
					x: newX,
					y: newY
				};
			})
			this.transformer.forceUpdate()
			stage.add(layer)
			layer.add(group)

			layer.clearBeforeDraw()
			stage.batchDraw()
		})
	}

	konvaClick(e: any): void {
		if (!e) return

		const stage = this.stage.getStage()
		if (e.target === stage) {
			this.isControlShowing = false
			this.showControls.emit("hide")
			this.transformer.nodes([])
			return
		}

		this.isControlShowing = true
		const target: Konva.Group = e.target.parent ?? e.target
		this.transformer.nodes([target])
		const width = this.transformer.getWidth()
		const attr = target.getAttrs()

		const { type } = attr
		let space
		console.log({ type })
		switch (type) {
			case "designation":
			case "name":
			case "date-time":
				space = 50
				break
			default:
				space = 20
		}

		const x = this.transformer.getX() + width + 20
		const y = this.transformer.getY() - space
		this.showControls.emit({
			x,
			y,
			target,
			transformer: this.transformer,
			index: target.attrs.index,
			page: this.i,
		})
	}

	mouseover(event: any) {
		if (event?.type === "mousemove") {
			const mEvent = event.evt
			this.location.emit({ x: mEvent.offsetX, y: mEvent.offsetY })
		} else if (event?.type === "mouseleave") {
			this.location.emit({ x: -1, y: -1 })
		}
	}

	updateDetail(event: any) {
		console.log({ things: event.target.children, target: event.target })
		const group = event.target.children.find(
			(child: any) => !(child instanceof Konva.Transformer)
		)

		const metadata = group.attrs
		const shapes = group.children
		const detail = this.details[metadata.index]

		let mappedShapes: { [key: string]: any } = {}
		shapes.forEach((shape: any) => {
			mappedShapes[shape.attrs.id] = shape
		})

		const attr = this.getAttributes(detail, mappedShapes)
		const lastPos = event.target._lastPos

		const position = {
			x: attr.x + lastPos.x,
			y: attr.y + lastPos.y,
		}

		this.updateSignatureDetail.emit({
			detail,
			index: metadata.index,
			page: this.i,
			position,
		})

		this.isDragged.emit(false)
	}

	resize(event: any) {
		const group = event.target

		const metadata = group.attrs
		const shapes = group.children

		const detail = { ...this.details[metadata.index] }

		let mappedShapes: { [key: string]: any } = {}
		shapes.forEach((shape: any) => {
			mappedShapes[shape.attrs.id] = shape
		})

		let x = 0
		let y = 0
		if (metadata.type === "signature") {
			detail.documentConfig = mappedShapes[detail.documentConfig.id].attrs
			detail.textConfig = mappedShapes[detail.textConfig.id].attrs
			detail.config = mappedShapes[detail.config.id].attrs

			const rect = event.target.children.find(
				(child: any) => child instanceof Konva.Rect
			)

			x = rect.x()
			y = rect.y()
		} else {
			const element = event.target.children[0]
			x = element.x()
			y = element.y()
		}

		const position = { x, y }
		detail.transform = metadata

		this.updateSignatureDetail.emit({
			detail,
			index: metadata.index,
			page: this.i,
			position,
		})

		const width = this.transformer.getWidth()
		const attrs = group.getAttrs()

		const { type } = attrs
		let space
		console.log({ type })
		switch (type) {
			case "designation":
			case "name":
			case "date-time":
				space = 50
				break
			default:
				space = 20
		}

		const transformX = this.transformer.getX() + width + 20
		const transformY = this.transformer.getY() - space
		this.showControls.emit({
			transformX,
			transformY,
			group,
			transformer: this.transformer,
		})
	}

	startDrag(event: any) {
		console.log({ dragevent: event })

		const group = event.target.children.find(
			(child: any) => !(child instanceof Konva.Transformer)
		)

		const node = this.transformer.getNode()
		console.log({ node })
		const metadata = group.attrs
		const shapes = group.children
		const detail = this.details[metadata.index]

		console.log({ metadata, shapes, details: this.details })
		let mappedShapes: { [key: string]: any } = {}
		shapes.forEach((shape: any) => {
			mappedShapes[shape.attrs.id] = shape
		})

		console.log({ mappedShapes, shapes, detail })

		const attr = this.getAttributes(detail, mappedShapes)

		const lastPos = event.target._lastPos

		const position = { x: attr.x + lastPos.x, y: attr.y + lastPos.y }

		const width = this.transformer.getWidth()

		const x = position.x + width + 20
		const y = this.transformer.getY() - 20
		this.isDragged.emit({ x, y })
	}

	getAttributes(detail: any, shapes: any) {
		let attr
		switch (detail.type) {
			case "name":
				attr = shapes[detail.nameConfig.id].attrs
				break
			case "date-time":
				attr = shapes[detail.dateConfig.id].attrs
				break
			case "designation":
				attr = shapes[detail.desginationConfig.id].attrs
				break
			default:
				attr = shapes[detail.config.id].attrs
		}
		return attr
	}

	getZoomedSettings(config: any) {
		const newConfig = { ...config }

		return newConfig
	}

	getQRLocation() {
		switch (this.qrPosition) {
			case "top-right":
				return { x: this.config.width - 124, y: 35 }
			case "bottom-right":
				return {
					x: this.config.width - 124,
					y: this.config.height - 119,
				}
			case "bottom-left":
				return { x: 40, y: this.config.height - 119 }
			case "top-left":
				return { x: 40, y: 35 }
			case "top-center":
				return { x: this.config.width / 2 - 42, y: 35 }
			case "bottom-center":
				return {
					x: this.config.width / 2 - 42,
					y: this.config.height - 119,
				}
			default:
				return { x: 40, y: 35 }
		}
	}

	checkArrayEqual(firstArray: any[], secondArray: any[]) {
		const STRICT_EQUALITY_BROKEN = (a: any, b: any) => a === b
		const STRICT_EQUALITY_NO_NAN = (a: any, b: any) => {
			if (
				typeof a == "number" &&
				typeof b == "number" &&
				"" + a == "NaN" &&
				"" + b == "NaN"
			)
				// isNaN does not do what you think; see +/-Infinity
				return true
			else return a === b
		}

		const deepEquals: any = (
			a: any,
			b: any,
			areEqual = STRICT_EQUALITY_NO_NAN,
			setElementsAreEqual = STRICT_EQUALITY_NO_NAN
		) => {
			/* compares objects hierarchically using the provided 
					 notion of equality (defaulting to ===);
					 supports Arrays, Objects, Maps, ArrayBuffers */
			if (a instanceof Array && b instanceof Array)
				return arraysEqual(a, b, areEqual)
			if (
				Object.getPrototypeOf(a) === Object.prototype &&
				Object.getPrototypeOf(b) === Object.prototype
			)
				return objectsEqual(a, b, areEqual)
			if (a instanceof Map && b instanceof Map) return mapsEqual(a, b, areEqual)
			if (a instanceof Set && b instanceof Set) {
				if (setElementsAreEqual === STRICT_EQUALITY_NO_NAN)
					return setsEqual(a, b)
				else
					throw "Error: set equality by hashing not implemented because cannot guarantee custom notion of equality is transitive without programmer intervention."
			}
			if (
				(a instanceof ArrayBuffer || ArrayBuffer.isView(a)) &&
				(b instanceof ArrayBuffer || ArrayBuffer.isView(b))
			)
				return typedArraysEqual(a, b)
			return areEqual(a, b) // see note[1] -- IMPORTANT
		}

		const arraysEqual: any = (a: any, b: any, areEqual: any) => {
			if (a.length != b.length) return false
			for (var i = 0; i < a.length; i++)
				if (!deepEquals(a[i], b[i], areEqual)) return false
			return true
		}
		const objectsEqual: any = (a: any, b: any, areEqual: any) => {
			var aKeys = Object.getOwnPropertyNames(a)
			var bKeys = Object.getOwnPropertyNames(b)
			if (aKeys.length != bKeys.length) return false
			aKeys.sort()
			bKeys.sort()
			for (var i = 0; i < aKeys.length; i++)
				if (!areEqual(aKeys[i], bKeys[i]))
					// keys must be strings
					return false
			return deepEquals(
				aKeys.map((k) => a[k]),
				aKeys.map((k) => b[k]),
				areEqual
			)
		}
		const mapsEqual = (a: any, b: any, areEqual: any) => {
			// assumes Map's keys use the '===' notion of equality, which is also the assumption of .has and .get methods in the spec; however, Map's values use our notion of the areEqual parameter
			if (a.size != b.size) return false
			return [...a.keys()].every(
				(k) => b.has(k) && deepEquals(a.get(k), b.get(k), areEqual)
			)
		}
		const setsEqual = (a: any, b: any) => {
			// see discussion in below rest of StackOverflow answer
			return a.size == b.size && [...a.keys()].every((k) => b.has(k))
		}
		const typedArraysEqual = (a: any, b: any) => {
			// we use the obvious notion of equality for binary data
			a = new Uint8Array(a)
			b = new Uint8Array(b)
			if (a.length != b.length) return false
			for (var i = 0; i < a.length; i++) if (a[i] != b[i]) return false
			return true
		}

		return deepEquals(firstArray, secondArray)
	}
}
