import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  PageSizes,
  PDFDocument,
  PDFFont,
  PDFPage,
  rgb,
  RotationTypes,
} from 'pdf-lib';

import { formatDate, Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import fontkit from '@pdf-lib/fontkit';
import {
  firstValueFrom,
  from,
  lastValueFrom,
  startWith,
  Subject,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import moment from 'moment';
import { MatSidenav } from '@angular/material/sidenav';
import {
  ComponentPortal,
  ComponentType,
  Portal,
  TemplatePortal,
} from '@angular/cdk/portal';
import { UsersService } from 'src/app/@shared/services/users.service';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { FilesService } from 'src/app/@shared/services/files.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { QrService } from '../services/qr.service';
import { SignSecureService } from '../services/sign-secure.service';
import { LocalizationService } from '../services/localization.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';

@Component({
  selector: 'app-signing-page',
  templateUrl: './signing-page.component.html',
  styleUrls: ['./signing-page.component.sass'],
})
export class SigningPageComponent implements OnInit, OnDestroy {
  data: any;

  navdata: any[] = [];

  done: boolean = false;
  cancel: boolean = false;
  role: string = '';

  declined: boolean = false;
  doneData: any;
  status: any;
  createdByMe: boolean = false;
  documentStatus: string = '';

  openDrawer: boolean = false;
  auditTrail: any[] = [];
  action: string = '';

  @ViewChild('sidenav', { static: true })
  sidenav!: MatSidenav;

  private panelPortal$ = new Subject<Portal<any>>();
  panelPortal = from(this.panelPortal$);
  @ViewChild('AuditTrail', { static: true }) auditTailRef!: TemplateRef<any>;
  @ViewChild('DocumentDetails', { static: true })
  documentDetailsRef!: TemplateRef<any>;
  @ViewChild('Default', { static: true }) defaultRef!: TemplateRef<any>;

  signatures: any[] = [];
  pdfObject: any;
  alreadyViewed: boolean = false;
  signNow: boolean = false;
  currentUser: any;
  expired: boolean = false;
  path: any = '';
  subs: Subscription = new Subscription();

  signingOrder: number = 0;
  party: any;
  readonly second = 1000;
  constructor(
    private _signSecure: SignSecureService,
    private _loader: LoaderService,
    private _auth: AuthenticationService,
    private vcf: ViewContainerRef,
    private _user: UsersService,
    private _file: FilesService,
    private _qr: QrService,
    private change: ChangeDetectorRef,
    private router: Router,
    private _localize: LocalizationService,
    private route: ActivatedRoute,
    private _nav: NavigationService,
    private _location: Location
  ) {}

  ngOnInit(): void {
    const data = this._nav.getPublicData();
    if (!!data) {
      this.processWorflowData(data);
      this.subs.add(
        this._signSecure
          .getProxyUrl(data.id)
          .subscribe(async (response: any) => {
            const fileReader = new FileReader();
            fileReader.onload = () => {
              const path = new Uint8Array(fileReader.result as ArrayBuffer);
              this.data.proxyPath = path;
              this.path = path;
            };
            fileReader.readAsArrayBuffer(response);
          })
      );
    } else {
      const docuId = this.route.snapshot.paramMap.get('id') ?? '';
      // this.subs.add(
      //   this._signSecure.worflowData$.subscribe(workflowData => {
      //     if (workflowData.id) {
      //       this.processWorflowData(workflowData);
      //     } else {
      //       this._location.back();
      //     }
      //   })
      // );

      this.subs.add(
        this._signSecure.worflowData$
          .pipe(
            switchMap(data => {
              if (data.id) {
                this.processWorflowData(data);
              } else {
                this._location.back();
              }
              return this._signSecure.getAngkatWorkflow();
            })
          )
          .subscribe(async response => {
            const fileReader = new FileReader();
            fileReader.onload = () => {
              const path = new Uint8Array(fileReader.result as ArrayBuffer);
              this.path = path;
            };
            fileReader.readAsArrayBuffer(response);
          })
      );

      this.checkIncompleteProcess();
    }
  }
  getCurrentUserIdByEmail(workflow: any) {
    return workflow.parties.filter((data: any) => {
      return data.email == workflow.currentParty;
    })[0].id;
  }
  async processWorflowData(workflowData: any) {
    if (workflowData?.id) {
      const workflow = workflowData?.workflow;
      this.data = workflowData;
      this.navdata = workflow?.signatures ?? [];
      this.signatures = [...(workflow?.signatures ?? [])];
      this.signingOrder =
        workflow?.parties?.findIndex(
          (party: any) => party.id === this._auth.userId
        ) ?? 0;

      const party = workflow?.parties![this.signingOrder];
      this.party = party;
      this.role = party?.role ?? 'SIGN';
      this.status = party?.status;
      this.auditTrail = workflow?.auditTrail ?? [];

      this.currentUser = workflow?.parties
        ? workflow?.parties[workflow.currentOrder ?? 0]
        : {};
      const currentUser = this.getCurrentUserIdByEmail(workflow);
      this.signNow =
        (!workflow?.signOrder &&
          workflow?.parties?.some(
            (party: any) =>
              party.id === this._signSecure.userId ||
              party.email === this._auth.email
          )) ||
        currentUser == this._signSecure.userId;
      this.createdByMe = workflowData.createdBy === this._auth.userId;
      this.cancel =
        this.data?.status === 'CANCELLED' ||
        workflow?.status === 'CANCELLED' ||
        (this.data?.status === 'EXPIRED' && !this.createdByMe);
      this.expired = this.data?.status === 'EXPIRED';

      this.documentStatus = workflow?.status ?? '';

      if (!!party?.executedFormatted) {
        this.status = 'COMPLETED';
      } else {
        this.status = workflow?.status ?? '';
      }


      if (!party?.viewedFormatted && !this.alreadyViewed) {
        this.alreadyViewed = true;
        await firstValueFrom(this._signSecure.viewAngkatWorkflow());
      }
    }
  }

  async checkIncompleteProcess() {
    const pending = this._signSecure.havePendingSignature();

    if (!!pending) {
      let data = JSON.parse(pending);

      data = await lastValueFrom(this._signSecure.worflowData$);

      let path = '../../../assets/dms-documents/pdf-test.pdf';
      if (!environment.local) {
        path = `./assets/${data.path}`;
      }

      const blob: Blob = await lastValueFrom(
        this._signSecure.getAngkatWorkflow()
      );
      const bytes = await blob.arrayBuffer();
      const pdfdocument = await PDFDocument.load(bytes);
      await this.checkIfAllSignatures(pdfdocument, data);
    }
  }

  async getImage() {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  updateData(event: any) {
    this.navdata = event;
  }

  //
  async generateNewPdfFile() {
    this._loader.show();
    // await this.getUpdatedDocument();
    await this.generateSignedPdf();
    this._loader.hide();
  }

  async generateSignedPdf() {
    const blob: Blob = await lastValueFrom(
      this._signSecure.getAngkatWorkflow()
    );
    const bytes = await blob.arrayBuffer();
    const pdfDoc = await PDFDocument.load(bytes);
    const pages = pdfDoc.getPages();

    const pagesLength = this.navdata.length;
    //fonts
    const fontBytes = await fetch('../../../assets/fonts/DMSans.ttf').then(
      res => res.arrayBuffer()
    );
    const fontItalicBytes = await fetch(
      '../../../assets/fonts/DMSans-Italic.ttf'
    ).then(res => res.arrayBuffer());
    const fontBoldBytes = await fetch(
      '../../../assets/fonts/DMSans-Bold.ttf'
    ).then(res => res.arrayBuffer());
    const logoImage = await fetch(
      '../../../assets/images/signature-logo.png'
    ).then(res => res.arrayBuffer());
    const yohaku = 50 / PdfViewerComponent.CSS_UNITS;

    let text = this.data.id;
    text = `${window.location.origin}/share/workflow/${text}`;
    const qrCode = await this._qr.getQRCode(text);

    for (let page = 0; page < pagesLength; page++) {
      try {
        const signatures = this.navdata[page];
        const length = signatures?.length ?? 0;
        pages[page]?.doc.registerFontkit(fontkit);
        const docFont = await pages[page]?.doc.embedFont(fontBytes);
        const docItalicFont = await pages[page]?.doc.embedFont(fontItalicBytes);
        for (let i = 0; i < length; i++) {
          const signature = signatures[i];
          const pageSize = pages[page].getSize();

          // Draw signature data
          if (signature.signatureData) {
            // draw signature
            let image = await pages[page]?.doc?.embedPng(
              signature.signatureData
            );

            const signatureConfig = signature?.signatureConfig;
            const y =
              pageSize.height -
              +signatureConfig?.y / PdfViewerComponent.CSS_UNITS -
              30;
            //this.getYLocation(+signature?.signatureConfig?.y, signature.pageSize.height, pageSize.height) - 15
            const x = +signatureConfig?.x / PdfViewerComponent.CSS_UNITS;
            //this.getXLocation(+signature?.signatureConfig?.x, signature.pageSize.width, pageSize.width)
            const config = {
              x,
              y,
              width:
                +signature.signatureConfig?.width /
                PdfViewerComponent.CSS_UNITS,
              height:
                (+signature.signatureConfig?.height *
                  +signature.signatureConfig?.scaleY) /
                PdfViewerComponent.CSS_UNITS,
            };
            pages[page].drawImage(image, config);

            // draw logo
            if (signature.sigData) {
              let image = await pages[page]?.doc?.embedPng(logoImage);
              const y = pageSize.height - +signature?.sigData?.y - 10; // this.getYLocation(+signature?.sigData?.y, signature.pageSize.height, pageSize.height)
              const x = +signature?.sigData?.x; //this.getXLocation(+signature?.sigData?.x, signature.pageSize.width, pageSize.width)
              const config = {
                x: x + 8,
                y: y - 10,
                width: +signature.sigData?.width,
                height: +signature.sigData?.height,
              };
              pages[page].drawImage(image, config);

              // draw document id
              let documentConfig = {
                x: x + 125, // this.getYLocation(+signature?.documentConfig?.x, signature.pageSize.width, pageSize.width),
                y: y, //- 8 / PdfViewerComponent.CSS_UNITS, //this.getYLocation(+signature?.documentConfig?.y, signature.pageSize.height, pageSize.height) + 23,
                font: docFont,
                size:
                  +signature?.documentConfig?.fontSize /
                    PdfViewerComponent.CSS_UNITS ?? 5,
              };
              pages[page].drawText(
                this._signSecure.signatureId,
                documentConfig
              );
            }
          }

          if (signature.nameConfig) {
            const nameConfig = {
              x: (+signature.nameConfig.x + 8) / PdfViewerComponent.CSS_UNITS,
              y:
                pageSize.height -
                +signature.nameConfig.y / PdfViewerComponent.CSS_UNITS -
                7,
              font: docFont,
              size:
                +signature.nameConfig?.fontSize /
                  PdfViewerComponent.CSS_UNITS ?? 5,
            };
            pages[page].drawText(signature.nameConfig?.text ?? '', nameConfig);
          }

          if (signature.dateConfig) {
            const dateConfig = {
              x: (+signature.dateConfig.x + 8) / PdfViewerComponent.CSS_UNITS,
              y:
                pageSize.height -
                +signature.dateConfig.y / PdfViewerComponent.CSS_UNITS -
                7,
              font: docFont,
              size:
                +signature.dateConfig?.fontSize /
                  PdfViewerComponent.CSS_UNITS ?? 5,
            };
            pages[page].drawText(
              moment().format('DD/MM/YYYY hh:mm A'),
              dateConfig
            );
          }

          if (signature.desginationConfig) {
            const desginationConfig = {
              x:
                (+signature.desginationConfig.x + 8) /
                PdfViewerComponent.CSS_UNITS,
              y:
                pageSize.height -
                +signature.desginationConfig.y / PdfViewerComponent.CSS_UNITS -
                7,
              font: docItalicFont,
              size:
                +signature.desginationConfig?.fontSize /
                  PdfViewerComponent.CSS_UNITS ?? 5,
            };
            pages[page].drawText(
              signature.desginationConfig?.text ?? '',
              desginationConfig
            );
          }

          await new Promise(resolve => setTimeout(() => resolve(0), 500));
        }
      } catch (e) {}
    }

    this._signSecure.signPdfData = await pdfDoc.save();
    this._signSecure.signPdfName = this.data.name;
    this._loader.show();
    const correctName = this._signSecure.getCorrectFilename(this.data.name);
    const formatData = {
      file: this._signSecure.bytesToFile(
        this._signSecure.signPdfData,
        correctName
      ),
      signatures: this.signatures,
      parties: this.data?.workflow?.parties,
      id: this.data.id,
    };

    // this.developManifest(await pdfDoc.copy())

    const { file, ...rest } = formatData;

    this.subs.add(
      (await this._signSecure.documentSigned(formatData)).subscribe(
        async (data: any) => {
          this.checkIfAllSignatures(await pdfDoc.copy(), data.data);
          // this.developManifest(pdfDoc)
        }
      )
    );
  }

  async generateApprovedDocument(data: any) {
    // await this.getUpdatedDocument();
    const bytes = this.path;
    const pdfDoc = await PDFDocument.load(bytes);
    this._signSecure.pendingSignature(data);
    await this.checkIfAllSignatures(pdfDoc, data);
    this._signSecure.signPdfData = await pdfDoc.save();
    this.done = true;
  }

  async developManifest(pdfDoc: any) {
    await this.addQRCode(pdfDoc);
    await this.createManifest(pdfDoc);
    this._signSecure.signPdfData = await pdfDoc.save();
    this.download();
    this._loader.hide();
  }

  getYLocation(y: number, canvaHeight: number, pageHeight: number) {
    const diff = Math.abs(canvaHeight - pageHeight);
    const aspectRatio = canvaHeight / pageHeight;
    const mid = canvaHeight / 2;

    let newY = pageHeight - y;

    if (newY > pageHeight * 0.9) {
      newY = newY * aspectRatio + 50;
    } else if (newY <= pageHeight * 0.2) {
      newY = newY + diff + 100;
    }

    return newY;
  }

  getXLocation(x: number, canvaWidth: number, pageWidth: number) {
    const diff = Math.abs(canvaWidth - pageWidth);
    const aspectRatio = pageWidth / canvaWidth;
    const mid = canvaWidth / 2;

    let newX = x;

    if (newX > canvaWidth * 0.9) {
      newX = newX * aspectRatio;
    }

    return newX;
  }

  async checkIfAllSignatures(pdf: PDFDocument, data: any) {
    const everyoneDone = data?.workflow?.parties?.every(
      (party: any) => party?.role === 'COPY' || !!party?.executedFormatted
    );
    if (everyoneDone) {
      this.data = data;
      await this.addQRCode(pdf);
      const documentData = await pdf.save();
      const document = this._signSecure.bytesToFile(
        documentData,
        'document.pdf'
      );
      const manifest = await this.createManifest(pdf);
      this._signSecure.signPdfData = await pdf.save();
      const formatData = {
        id: data.id,
        file: this._signSecure.bytesToFile(
          this._signSecure.signPdfData,
          this._file.changeExtension(this.data.name, 'pdf')
        ),
        document,
        manifest,
      };
      this._signSecure.doneSignature();
      this.subs.add(
        await lastValueFrom(this._signSecure.documentDone(formatData))
          .then(() => {
            this._loader.hide();
            this._localize.clearProperty('workflow');
            this.done = true;
          })
          .catch(error => {
            // Handle errors here
            console.error('Error processing document:', error);
          })
      );
    } else if (this._signSecure.havePendingSignature()) {
      this._signSecure.doneSignature();
      this._loader.hide();
      this.done = true;
    } else {
      this._loader.hide();
      this.done = true;
    }
  }

  doAction(data: any) {
    this.setPanelContent(this.defaultRef);
    this._signSecure.signPdfName = this.data.name;
    if (data?.action === 'approve') {
      this.generateApprovedDocument(data.data);
      this.doneData = {
        image: '../../../assets/images/approve-document.svg',
        title: 'Document approved',
        description: `The document <strong>“${this.data.name}”</strong> has been approved.`,
      };
    } else if (data?.action === 'reject') {
      this.done = true;
      this.generateApprovedDocument(data.data);
      this.doneData = {
        image: '../../../assets/images/reject-document.svg',
        title: 'Document rejected',
        description: `The document <strong>“${this.data.name}”</strong> has been rejected. Everyone in the party will be notified.`,
      };
    } else if (data?.action === 'audit-trail') {
      this.setPanelContent(this.auditTailRef);
      this.openDrawer = true;
    } else if (data?.action === 'document-details') {
      this._loader.show();
      this.data['author'] = this.data.workflow.parties[0];
      this.setPanelContent(this.documentDetailsRef);
      this.openDrawer = true;
      this._loader.hide();
    } else if (data?.action === 'document-declined') {
      this.done = true;

      this.generateApprovedDocument(data.data);
      this.doneData = {
        image: '../../../assets/images/decline-document.svg',
        title: 'You declined to sign the document',
        description: `Sender will be notified that you declined to sign this document`,
      };
    }
  }

  async addQRCode(pdf: PDFDocument, documentType?: string) {
    let qrPosition = this.data.workflow?.qrPosition ?? 'bottom-right';
    if (documentType === 'manifest') {
      qrPosition = 'bottom-left';
    }
    let text = this.data.id;
    text = `${window.location.origin}/share/workflow/${text}`;
    const qrCode = await this._qr.getQRCode(text);
    const fontBytes = await fetch('../../../assets/fonts/DMSans.ttf').then(
      res => res.arrayBuffer()
    );
    const pages = pdf.getPages();
    const pagesLength = pages?.length ?? 0;
    for (let page = 0; page < pagesLength; page++) {
      const image = await pages[page].doc.embedPng(qrCode);
      const size = pages[page].getSize();
      const pos = this._qr.getQRCodeConfig(qrPosition, size.height, size.width);
      pages[page].drawImage(image, { ...pos, width: 45, height: 45 });
    }
  }

  async createManifest(pdf: PDFDocument) {
    const pdfDoc = await PDFDocument.create({});
    let page = pdfDoc.addPage(PageSizes.Letter);
    let y = page.getSize().height - 50;

    const columnWidth = page.getSize().width / 2;
    const fontSize = 10;

    pdfDoc.registerFontkit(fontkit);

    const fontBytes = await fetch('../../../assets/fonts/DMSans.ttf').then(
      res => res.arrayBuffer()
    );
    const fontBoldBytes = await fetch(
      '../../../assets/fonts/DMSans-Bold.ttf'
    ).then(res => res.arrayBuffer());
    const customFont = await pdfDoc.embedFont(fontBytes);
    const customBoldFont = await pdfDoc.embedFont(fontBoldBytes);
    const x1 = 50;
    const x2 = columnWidth + x1;

    const pageCount = pdf.getPageCount();

    const logoArrayBuffer = await fetch(
      '../../../assets/images/manifest-unawa.png'
    ).then(res => res.arrayBuffer());
    const logoImage = await pdfDoc.embedPng(logoArrayBuffer);

    const logoWidth = 90;
    const logoHeight = 20;
    const logoX = page.getWidth() / 2 - logoWidth / 2;
    const logoY = page.getHeight() - logoHeight - 20;
    page.drawImage(logoImage, {
      x: logoX,
      y: logoY,
      width: logoWidth,
      height: logoHeight,
    });

    const logoText = 'MANIFEST';
    const logoTextWidth = customBoldFont.widthOfTextAtSize(
      logoText,
      fontSize + 7
    );
    const logoTextX = page.getWidth() / 2 - logoTextWidth / 2;
    const logoTextY = logoY - (fontSize + 7) - 20;

    page.drawText(logoText, {
      x: logoTextX,
      y: logoTextY,
      size: fontSize + 7,
      font: customBoldFont,
      color: rgb(0, 0, 0),
    });

    y = logoTextY - fontSize * 4;
    const userData = this.data.workflow.parties[0];
    const data: any = [
      {
        ['title']: 'Document Details',
        ['Folder Name']: this.data.parent || 'SignSecure workflow documents',
        ['Total no. of pages (excl. Manifest)']: pageCount,
        ['Document Title']: this.data.name,
        ['Author']: `${userData?.name}`,
        ['Date Created']: formatDate(
          new Date(this.data.createdAt),
          'dd/MM/yyyy HH:mm aa',
          'en-us'
        ),
        ['Date of Completion']: moment().format('DD/MM/YYYY hh:mm A'),
        ['Email']: `${userData?.email}`,
        ['IP Address']: this.data.ip,
      },
    ];

    let parties = this.data?.workflow?.parties ?? [];

    let index = 0;
    for (const party of parties?.filter(
      (p: { role: string }) => p.role === 'SIGN'
    )) {
      data.push({
        ['title']: index === 0 ? 'Signatory' : '',
        ['Name']: party.name,
        ['Login Type']: 'Email and Password',
        ['Signature Type']: party.signatureType,
        ['IP Address']: party.ip,
        ['Viewed']: moment.unix(party.viewed).format('DD/MM/YYYY hh:mm A'),
        ['Signed/Approved']: party.executedFormatted,
        ['Signature ID']: party.signatureId,
        // ['Region/City']: party.regionCity,
      });
      index++;
    }

    index = 0;
    for (const party of parties?.filter(
      (p: { role: string }) => p.role === 'APPROVE'
    )) {
      data.push({
        ['title']: index === 0 ? 'Approver' : '',
        ['Name']: party.name,
        ['Login Type']: party.loginType,
        ['Signature Type']: 'N/A',
        ['IP Address']: party.ip,
        ['Viewed']: moment.unix(party.viewed).format('DD/MM/YYYY hh:mm A'),
        ['Signed/Approved']: party.executedFormatted,
        ['Signature ID']: 'N/A',
        // ['Region/City']: party.regionCity,
      });
      index++;
    }

    for (const datum of data) {
      const title = datum.title;
      const keys = Object.keys(datum);
      const values = Object.values(datum);

      const spaceNeeded = fontSize * 3;
      const footerSize = 150;

      if (y - spaceNeeded - footerSize < 0) {
        this.addFooter(page, fontSize, customFont);
        page = pdfDoc.addPage(PageSizes.Letter);
        y = page.getSize().height - 50;
      }

      const leftMargin = 0;
      const rightMargin = 40;
      const fullWidth = page.getSize().width - x1 - leftMargin - rightMargin;
      const marginLeft = x1 + leftMargin;

      if (title.trim() === '') {
        const lineMargin = 0.5; // Adjust this value as needed
        const lineY = y - fontSize * 0.5 - lineMargin;
        const fullWidth = page.getSize().width - x1 - leftMargin - rightMargin;

        page.drawLine({
          start: { x: marginLeft, y: lineY },
          end: { x: marginLeft + fullWidth, y: lineY },
          thickness: 2,
          color: rgb(245 / 255, 245 / 255, 245 / 255),
        });
      } else {
        const titleBackgroundColor = rgb(245 / 255, 245 / 255, 245 / 255);
        const titleBackgroundHeight = fontSize + 20;
        const centerY = y - titleBackgroundHeight / 2 + fontSize / 4;

        page.drawRectangle({
          x: marginLeft,
          y: centerY,
          width: fullWidth,
          height: titleBackgroundHeight,
          color: titleBackgroundColor,
        });

        page.drawText(title, {
          x: marginLeft + 10,
          y: centerY + titleBackgroundHeight / 3,
          size: fontSize + 4,
          font: customBoldFont,
          color: rgb(0, 0, 0),
        });
      }

      y -= fontSize * 3;

      keys.splice(0, 1);
      values.splice(0, 1);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const value: any = values[i];

        const x = i % 2 === 0 ? x1 : x2;
        const colonX = x + customFont.widthOfTextAtSize(key, fontSize);

        if (key === 'Document Pages (excl. Manifest)') {
          let titleX = x;
          page.drawText('Document Pages ', {
            x: titleX,
            y,
            size: fontSize,
            font: customFont,
            color: rgb(0, 0, 0),
          });

          titleX =
            titleX + customFont.widthOfTextAtSize('Document Pages ', fontSize);
          page.drawText('(excl. Manifest)', {
            x: titleX,
            y,
            size: fontSize,
            font: customBoldFont,
            color: rgb(0, 0, 0),
          });
        } else {
          page.drawText(key, {
            x,
            y,
            size: fontSize,
            font: customFont,
            color: rgb(0, 0, 0),
          });
        }

        page.drawText(':', {
          x: colonX,
          y,
          size: fontSize,
          font: customFont,
          color: rgb(0, 0, 0),
        });

        page.drawText(value && value !== undefined ? value?.toString() : '', {
          x: colonX + fontSize * 0.5,
          y,
          size: fontSize,
          font: customBoldFont,
          color: rgb(0, 0, 0),
        });

        if (i % 2 !== 0) {
          y -= fontSize * 2;
        }
      }

      y -= fontSize * 2;
    }

    await this.addQRCode(pdfDoc, 'manifest');
    this.addFooter(page, fontSize, customFont);

    const copyPages = await pdf.copyPages(
      pdfDoc,
      Array.from(Array(pdfDoc.getPageCount()).keys())
    );

    for (let copyPage of copyPages) {
      pdf.addPage(copyPage);
    }

    const manifestData = await pdfDoc.save();

    return this._signSecure.bytesToFile(manifestData, 'manifest.pdf');
  }

  download(data: any = this._signSecure.signPdfData, type = 'application/pdf') {
    var blob = new Blob([data], { type });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = this._signSecure.signPdfName;
    link.download = fileName;
    link.click();
  }

  closeSlider() {
    this.openDrawer = false;
  }

  setPanelContent(
    componentOrTemplateRef: ComponentType<any> | TemplateRef<any>
  ) {
    let portal: Portal<any>;
    if (componentOrTemplateRef instanceof TemplateRef) {
      portal = new TemplatePortal(componentOrTemplateRef, this.vcf);
    } else {
      portal = new ComponentPortal(componentOrTemplateRef);
    }
    this.panelPortal$.next(portal);
  }

  addFooter(page: any, fontSize: any, customFont: any) {
    const textX = page.getSize().width / 2 + 50;
    const textY = 60;
    const text =
      'This document was signed with SignSecure™\nScan the QR code to verify document';

    const lines = text.split('\n');

    page.drawText(lines[0], {
      x: textX,
      y: textY,
      size: fontSize,
      font: customFont,
      color: rgb(0, 0, 0),
    });

    const secondLineY = textY - fontSize * 1.2;

    page.drawText(lines[1], {
      x: textX,
      y: secondLineY,
      size: fontSize,
      font: customFont,
      color: rgb(0, 0, 0),
    });
  }
}
