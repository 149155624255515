import { Component, Input, OnInit, ViewChild, TemplateRef, ViewContainerRef, DoCheck, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { every } from 'rxjs';
import { ModalData } from 'src/app/@shared/models/Modal.model';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { SettingsService } from 'src/app/@shared/services/settings.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';


@Component({
  selector: 'app-overlay-spinner',
  templateUrl: './overlay-spinner.component.html',
  styleUrls: ['./overlay-spinner.component.sass']
})
export class OverlaySpinnerComponent implements OnInit {
  isLoading$ = this._loaderService.isLoading;
  constructor(
   private _loaderService:LoaderService
   
  ) {}
 ngOnInit(){}

}