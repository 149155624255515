<ng-container>
  <div class="create-folder">
    <h4 class="title">New Folder</h4>
    <div class="content">
      <div class="input-group">
        <mat-label class="label-text">Folder Name</mat-label>
        <mat-form-field floatLabel="never" appearance="fill">
          <input
            type="name"
            matInput
            placeholder="Untitled Folder"
            [(ngModel)]="name"
            (ngModelChange)="error = false"
            [ngModelOptions]="{ standalone: true }"
            [ngClass]="error ? 'form-error' : 'form-valid'"
          />
        </mat-form-field>
        <span class="txt-error" *ngIf="error">{{ errorMsg }}</span>
      </div>
      <div class="actions">
        <button matRipple class="cancel" type="reset" (click)="closeModal()">
          Cancel
        </button>
        <button matRipple class="create" (click)="createFolder()">Create</button>
      </div>
    </div>
  </div>
</ng-container>
