import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FilesService } from 'src/app/@shared/services/files.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { SignSecureService } from 'src/app/sign-secure/services/sign-secure.service';
import { Location } from '@angular/common';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';

@Component({
  selector: 'app-share-files',
  templateUrl: './share-files.component.html',
  styleUrls: ['./share-files.component.sass'],
})
export class ShareFilesComponent implements OnInit {
  type: string = '';
  id: string = '';

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _files: FilesService,
    private _nav: NavigationService,
    private _auth: AuthenticationService
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe(({ type, id }) => {
      switch (type) {
        case 'workflow':
          this.checkWorkflowFile(id);
          break;
        case 'pdf': 
          this.checkSharedFile(id);
          break;
        default:
          this._router.navigate([`login`], { replaceUrl: true });
      }
    });
  }

  checkWorkflowFile(id: string) {
    this._files.verifyFile(id).subscribe(response => {
      if (response?.data && response.data.workflow.privacyType === 'PUBLIC') {
        this._nav.setPublicData(response.data);
        this._router.navigate([`sign-secure/${response.data.id}`], {
          replaceUrl: true,
        });
      } else {
        this._nav.setPublicData(id);
        if (this._auth.isLogin()) {
          this._router.navigate([`dashboard`], { replaceUrl: true });
        } else {
          this._router.navigate([`login`], { replaceUrl: true });
        }
      }
    });
  }

  checkSharedFile(id: string) {
    this._nav.setFileData(id)
    this._files.getProxyUrl(id).subscribe((data) => {
      if(!data) {
        this._nav.getFileData()
      }
      this._router.navigate([`login`], { replaceUrl: true });
    })
  }
}
