<table mat-table [dataSource]="matDataSource" style="width: 100%">
  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>Title</th>
    <td mat-cell *matCellDef="let element" (click)="openPdf(element.data)">
      {{ element.title }}
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="date_created">
    <th mat-header-cell *matHeaderCellDef>Date Created</th>
    <td mat-cell *matCellDef="let element">{{ element.date_created }}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="recent_action">
    <th mat-header-cell *matHeaderCellDef>Recent Action</th>
    <td mat-cell *matCellDef="let element">{{ element.recent_action }}</td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="last_update">
    <th mat-header-cell *matHeaderCellDef>Last Update</th>
    <td mat-cell *matCellDef="let element">{{ element.last_update }}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element">{{ element.status }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator
#paginator
*ngIf="isPaginate"
[length]="resultsLength"
[pageSize]="pageSize"
[pageSizeOptions]="[5, 10]"></mat-paginator>