<div
  style="
    display: flex;
    background-color: #ececec;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
  ">
  <div
    style="
      display: flex;
      gap: 5vh;
      flex-direction: column;
      align-items: center;
    ">
    <img
      style="padding-top: 64px"
      src="../../../../assets/angkat/angkat logo .svg"
      alt="" />
    <div style="padding: 0 4rem">
      <span style="font-weight: 700; font-size: 24px"
        >Welcome <br />{{ user }}!</span
      ><br /><br />
      We're thrilled to have you on board for a seamless and efficient
      e-signature experience. Our user-friendly platform has been designed with
      you in mind, ensuring a hassle-free journey from start to finish.
    </div>
  </div>
  <div
    style="
      display: flex;
      flex-direction: column;
      align-self: center;
      padding-bottom: 1rem;
    ">
    <span><a>Terms & Conditions</a> | <a>Privacy Policy</a></span>
    <span>© 2024 Angkat. All rights reserved</span>
  </div>
</div>
