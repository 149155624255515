import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { catchError, finalize, Observable, of } from 'rxjs';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private _loaderService: LoaderService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      request.url.includes('file-document/create/') ||
      request.url.includes('folder/create') ||
      request.url.includes('directory/user-group/create') ||
      request.url.includes('directory/user/create')
    ) {
      return next.handle(request);
    }
    console.log(request);
    this._loaderService.show();

    return next.handle(request).pipe(
      finalize(() => {
        this._loaderService.hide();
      })
    );
  }
}
