import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, of } from 'rxjs';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.sass'],
})
export class RecoverPasswordComponent implements OnInit {
  @Output() emailSentEvent = new EventEmitter<string>();
  email: string = '';
  errorMsg: any;
  isValid: boolean = true;
  constructor(
    private _authService: AuthenticationService,
    private _router: Router
  ) {}

  ngOnInit(): void {}

  resetPassword() {
    
    this._authService
      .forgotPasswordAngkat(this.email)
      .pipe(
        catchError((err) => {
          if (err.status == 401 || err.status == 404) {
            this.errorMsg = err.error.error;
            this.errorMsg = this.errorMsg.slice(15);
            this.isValid = false;
          }
          return of();
        })
      )
      .subscribe((data) => {
        this.isValid = true;
        this._router.navigateByUrl('/reset-password-success');
      });
  }
  checkValidity() {
    if (this.errorMsg.length > 0) {
    }
  }
  goTo(loc:string){
    this._router.navigateByUrl(loc);
  }
}
