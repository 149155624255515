export const temp = `
<!DOCTYPE html>
<html>
  <head>
  </head>
  <body>
    <h3 class="pdf-header">
      IMPORT FACILITATION AGENCY AGREEMENT
    </h3>

    <p
      class="pdf-format">
      <span
        >This
        <span class="pdf-bold"
          >IMPORT FACILITATION AGENCY Agreement</span
        >
        (the “Agreement”) is made and entered into this {{month}} {{day}}, {{year}} by and
        between:<br /> </span
      ><span class="pdf-indent"
        >(1) {{clientName}}, Filipino, of legal age, and with address
        at<br />
        {{clientAddressCity}} City, hereinafter referred to as the
        “Client”; and<br />
        {{clientName}} is doing business under the name and style of<br />
        {{businessName}}, registered under the laws of the Philippines,
        with principal <br />office address at {{businessAddressCity}} City,
        hereinafter referred to as the<br />
        “Client”; and</span
      ><br />
      <span
        >(2)
        <span style="font-weight: 700">ANGKAT PH BUSINESS SERVICES INC.</span>,
        a corporation registered under the laws of the Philippines, with office
        address at 15C Road 2, 1st West Crame, San Juan City, hereinafter
        referred to generally as the “Company”) and, wherever applicable in this
        Agreement, the “Service Provider” or the Facilitation Agent”.</span
      ><br /><br />
      WHEREAS<br /><br />
      (A) The Company is engaged in the business of Import Facilitation Agency
      and Foreign Exchange Facilitation Agency (the “Services”) through its
      online facilities and online management services;<br /><br />
      (B) The Client desires to avail of the Services of the Company; and
      <br /><br />(C) The Company wishes to perform the Services for the
      Client.<br /><br />
      NOW, THEREFORE, for and in consideration of foregoing premises and the
      covenants hereinafter set forth, the parties hereto agreed and do hereby
      enter into this Agreement, subject to the following terms and
      conditions:<br /><br />
      <span style="display: inline-block; padding-left: 5px"
        >1. DEFINITIONS OF TERMS AND INTERPRETATION</span
      ><br /><br /><span style="display: inline-block; padding-left: 5rem">
        1.1 Definitions. As used in this Agreement, the following terms shall
        have the meanings ascribed to them:</span
      >
      “Affiliate” shall mean any legal entity, wherever incorporated or
      constituted, which directly or indirectly controls, is controlled by, or
      is under common control with a party to this Agreement and, for this
      purpose, "Control" means: (i) the ownership of or right to exercise
      control of at least 50% plus one share of the voting rights of such legal
      entity; or (ii) the power to manage or direct the affairs of such legal
      entity through a management or similar contract.<br /><br />
      “Business Day” shall mean a day (other than a Saturday, or Sunday, or any
      national public holiday) on which commercial banks are authorized to open
      for normal banking business or are not required to be closed.<br /><br />
      “Confidential Information” shall mean all information (either oral,
      written, or digital) provided and/or to be provided to the Receiving Party
      by the Disclosing Party and/or the Disclosing Party’s clients, including
      but not limited to:<br /><br />
      (a) Information transmitted to such party that the disclosing party has
      identified as being proprietary and/or confidential or which, by the
      nature of the circumstances surrounding the disclosure or the content of
      the disclosed information, ought in good faith to be treated as
      proprietary and/or confidential;<br />(b) Any document, data, materials,
      trade secret, process, know-how, technique, design, drawing, diagram,
      program, software, invention, and/or work in process;<br />(c) Any
      financial, supplier, administrative, technical, customer, employee,
      investor or business information;<br />(d) Information or materials
      relating to the Disclosing Party, the Disclosing Party’s clients and/or
      the Project, including, but not limited to, this Agreement and subsequent
      agreements entered into by the parties, whether related to this Agreement
      or not; and<div class="html2pdf__page-break"></div><br />(e) Any information or materials which the Disclosing
      Party or any of its affiliates and/or clients are required to keep
      confidential pursuant to an Agreement with a third party.<br /><br />
      “Effective Date” shall mean the execution and delivery of this Agreement
      by the Parties hereto.<br /><br />
      “Force Majeure” denotes any event which is reasonably beyond the control
      of a Party such as, but not limited to, the following: act of God,
      epidemic, pandemic, explosion, flood, tempest, fire, earthquake, storm,
      flood, drought, disease, natural disaster or accident; war, terrorism, or
      threat of war or terrorism, sabotage, insurrection, civil disturbance or
      requisition; acts, restrictions, regulations, bylaws, prohibitions or
      measures of any kind on the part of any governmental, parliamentary or
      local authority; import or export regulations or embargoes; or strikes,
      lock-outs or other industrial actions or trade disputes.<br /><br />
      “Foreign Exchange Fees and Expenses” shall refer to all fees and charges
      with respect to the availment of foreign exchange services from the
      Foreign Exchange Service Provider that shall be for the account of the
      Client.<br /><br />
      “Foreign Exchange Service Provider” shall refer to the person or entity
      engaged by the Facilitation Agent on behalf of the Client to render
      foreign exchange services to the Client.<br /><br />
      “Governmental Authority” shall mean any state, municipal or local
      government, administrative or legislative body, governmental or regulatory
      agency or authority, bureau, commission, court, tribunal, judicial body,
      department or other instrumentality or other governmental entity of the
      Republic of the Philippines or any foreign country.<br /><br />
      “Import Fees and Expenses” shall refer to all associated shipping costs,
      taxes, duties, fees, charges, and any other out-of-pocket expenses with
      respect to the importation of the Products into the Philippines that shall
      be for the account of the Client.<br /><br />
      “Intellectual Property Rights” shall mean any and all intellectual
      property rights worldwide, subsisting whether now or in the future, and
      whether registered or unregistered including designs, copyright,
      trademarks including names and logos, patents, inventions, trade secrets,
      moral rights, trade, business or company names or other intellectual
      property rights, or any rights to registration of such rights.<br /><br />
      “Law” shall mean any applicable law, statute, ordinance, rule, regulation,
      order, judgment, injunction, decree, treaty, principle of common law or
      reported decision of any court, including environmental laws.<br /><br />
      “Prevailing Fees and Expenses” means, depending on the services selected
      by the Client, either: (1) the Import Fees and Expenses; (2) the Foreign
      Exchange Fees and Expenses; or (3) any combination thereof.<br /><br />
      “Prevailing Foreign Exchange Fees and Expenses” means the applicable
      Foreign Exchange Fees and Expenses as set forth in the Foreign Exchange
      Fees and Expenses Matrix prevailing as of the time the Foreign Exchange
      Facilitation Agency Services is availed by the Client.<br /><br />
      “Prevailing Import Fees and Expenses” means the applicable import fees and
      expenses as set forth in the Import Fees and Expenses Matrix prevailing as
      of the time the Import Facilitation Agency Services is availed by the
      Client.<br /><br />
      “Prevailing Service Fees” means, depending on the Services availed by the
      Client, either: (1) the applicable service fee as set forth in the Service
      Fee Matrix prevailing as of the time the applicable service fee as set
      forth in the Service Fee Matrix prevailing as of the time the Import
      Facilitation Agency Services is availed by the Client; (2) applicable
      service fee as set forth in the Service Fee Matrix prevailing as of the
      time the Foreign Exchange Agency Services is availed by the Client; or (3)
      any combination thereof.<br /><br />
      “Products” shall mean the products of the Client which are subject of the
      Services to be rendered by the Service Provider under this Agreement and
      those products purchased by the Client from a manufacturer or supplier
      located in another country and to be imported into the Philippines through
      the Import Services of the Import Service Provider as monitored, managed,
      and facilitated by the Facilitation Agent.<div class="html2pdf__page-break"></div><br /><br />
      “Services” means the service selected by the Client, which shall refer to
      either: (1) the Import Facilitation Agency Services; or (2) the Foreign
      Exchange Agency Services; or (3) any combination thereof.<br /><br />
      1.2 Interpretation. <br /><br />(a) Sections and Clauses. Unless the
      context requires otherwise, any reference to “Sections”, “paragraphs” and
      “Annexes” in this Agreement are to be construed as references to the
      sections, paragraphs, schedules and annexes of and to this Agreement.
      Terms such as “in this Agreement,” “of this Agreement,” “according to this
      Agreement”, or other terms of similar meaning refer to the entirety and
      not a certain section and item of this Agreement. Terms such as “herein”.
      “hereof”, “hereunder”, “hereinafter”, and other terms of similar import
      shall refer to this Agreement, as amended, supplemented and otherwise
      modified and in effect from time to time.<br /><br />
      (b) Headings. The headings of this Agreement are used for convenience only
      and shall not limit or affect the interpretation of the provisions hereof,
      or this Agreement.<br /><br />
      (c) Writings. References to “writing” or cognate expressions includes a
      reference to telex, cable, electronic mail, facsimile transmission or
      comparable means of communications.<br /><br />
      (d) Successors and Assigns. References to any Party include references to
      their or its respective successors and permitted transferees, assigns and
      substitutes.<br /><br />
      (e) Singular and Plural Forms. References to the singular shall include
      the plural and vice versa.<br /><br />
      2. SCOPE OF SERVICES<br /><br />
      2.1. The Company agrees and binds itself to provide the Services in favor
      of the Client as set forth in either Annex A (Import Facilitation Agency
      Services) or Annex B (Foreign Exchange Facilitation Agency Services) of
      this Agreement (collectively, the “Annexes”), depending on the services
      selected by the Client. Should the Client request the Company to perform
      additional services beyond what is provided including those as provided in
      the Annexes of this Agreement (each such service, an “Additional
      Service”), the Client and the Company shall negotiate the terms thereof
      and any agreement for additional services shall be set forth in writing
      and considered an addendum to this Agreement.<br /><br />
      3. SERVICE FEES<br /><br />
      3.1. As consideration for the Services rendered by the Company under this
      Agreement, the Client agrees to pay the Company the Prevailing Service
      Fees as set forth in the Annexes, depending on the services selected by
      the Client.<br /><br />
      3.2. The Service Provider reserves the right to change at any time and its
      sole discretion the Prevailing Service Fees upon written notice to the
      Client. Any change in the Prevailing Service Fees shall apply immediately
      to all Services rendered after the date of written notice.<br /><br />
      3.3. If applicable, the Client shall pay all Prevailing Fees and Expenses
      incurred in connection with the Services. It is understood that the
      Company reserves the right to change at any time and its sole discretion
      the Prevailing Fees and Expenses. The Company shall procure that written
      notice be given and that any change in the Prevailing Fees and Expenses
      shall apply only to transactions after the date of written notice.<br /><br />
      3.4. The Company shall issue a Billing Statement to the Client separately
      indicating the Prevailing Service Fees and the Prevailing Fees and
      Expenses. For the Prevailing Service Fees, the Company shall issue a
      BIR-registered Official Receipt in the name of the Client and containing
      all other information required by applicable laws and regulations. For the
      Prevailing Fees and Expenses, the Company shall issue a BIR-registered
      Acknowledgment Receipt. The specific manner of billing and the payment
      terms and conditions for the Services are set forth in the Annexes.<br /><br />
      3.5. The Client shall refrain from making deductions of any kind from any
      payments due to the<div class="html2pdf__page-break"></div> Company except in the event of taxes required by any
      law or regulation to be deducted from or in respect of any sum payable to
      the Company. In such case, the Client shall make such deductions and pay
      the full of the deducted amount to the Bureau of Internal Revenue, and
      immediately after the date of such payment to the Bureau of Internal
      Revenue, the Client shall furnish the Company the original or a certified
      true copy of the Creditable Withholding Tax at Source (BIR Form 2307)
      evidencing payment thereof.<br /><br />
      4. TITLE AND RISK OF LOSS<br /><br />
      4.1. The rules on title to the Products and the risk of loss or damage to
      the Products shall be governed by either Annex A (Import Facilitation
      Agency Services) of this Agreement, depending on the services selected by
      the Client.<br /><br />
      5. GENERAL REPRESENTATIONS AND WARRANTIES<br /><br />
      5.1 Each Party represents and warrants to the other that it is duly
      organized, validly existing and in good standing under the laws of the
      jurisdiction of its organization and has the requisite power and authority
      to execute and deliver, and to perform its obligations under, this
      Agreement. All information provided to the other during the negotiation
      period prior to the execution of this Agreement are correct and not
      misleading Each party represents and warrants to the other that this
      Agreement has been duly authorized, executed and delivered by such party
      and constitutes a valid and binding obligation of such party enforceable
      against such party according to its terms. <br /><br />5.2 Each Party
      represents and warrants that it has obtained all necessary actions
      (whether corporate or otherwise), consents, licenses, approvals, permits,
      orders required under the rules of jurisdiction to which it is subject, to
      allow it to enter into this Agreement and perform all of its obligations
      hereto. <br /><br />5.3 Specific representations and warranties are set
      forth in the Annexes and shall apply on the basis of the services selected
      by the Client. <br /><br />6. GENERAL PROVISIONS ON TERM AND TERMINATION
      <br /><br />6.1. Either Party may terminate this Agreement at any time
      upon thirty (30) days prior written notice to the other party (the “Notice
      Period”). <br /><br />6.2. Either Party may terminate this Agreement
      immediately upon written notice:
      <span style="display: inline-block; padding-left: 2rem"
        >(a) In the event of a material breach (including but not limited to any
        uncured payment default) by the other party, if such other party has
        failed to cure such breach or default within seven (7) days of written
        notice thereof; <br /><br />(b) In the event of a breach that is not
        material by the other party, if such other party has failed to cure such
        breach or default within fifteen (15) days of written notice thereof;
        <br /><br />(c) In the event of three (3) consecutive delays in payment
        by the Client; <br /><br />(d) In the event that a Party attempts to
        assign this Agreement, except under circumstances permitted hereunder;
        <br /><br />(e) Upon the dissolution, insolvency or any adjudication in
        bankruptcy of, or any assignment for the benefit of creditors by, the
        other party or if the other party ceases to conduct business in the
        ordinary or normal course, provided such action or proceeding is not
        dismissed within sixty (60) days after the filing thereto;
        <br /><br />(f) a Party ceases or threatens to cease carrying on a
        business; <br /><br />(g) for any reason that a Party is no longer able
        to perform its obligations under this Agreement; <br /><br />(h) If
        required by law or by any rule, regulation, order, decree, judgment or
        other governmental act of any governmental authority;<div class="html2pdf__page-break"></div>(i) If
        a Party reasonably suspects that the other Party breached any of its
        obligations of confidentiality or protection of proprietary rights. </span
      ><br /><br />6.3. Termination of this Agreement is without prejudice to
      any accrued rights of either party as at the date of termination,
      including the right of the Company to earned Prevailing Service Fees, due
      Prevailing Fees and Expenses, and other applicable charges and/or
      payments. <br /><br />6.4. The duties and obligations of the Parties
      during the Notice Period and following the effective date of termination
      or non-renewal are set forth in the Annexes and shall apply on the basis
      of the services selected by the Client. <br /><br />7. INTELLECTUAL
      PROPERTY <br /><br />7.1 The Service Provider hereby acknowledges that the
      Client (or any of its affiliates) is the right holder of using all rights,
      title and interest in and to the intellectual property rights on the
      Products, including, but not limited to, copyrights, trademarks, patent
      rights and trade secrets, in and to the Product, as well as goodwill in
      connection therewith, now existing or hereafter developed (“Intellectual
      Property Rights“) and that the Service Provider shall acquire no rights
      whatsoever in or to any of such Intellectual Property Rights. The Service
      Provider shall not take any action that may affect or impair the Client’s
      (or any of its affiliates’) rights, title and interest in or to such
      Intellectual Property Rights. <br /><br />7.2 The Client agrees and
      acknowledges that the Service Provider shall have all the rights, title
      and interest in and to the intellectual property rights related to its own
      products and services, including, but not limited to, copyrights,
      trademarks, patent rights and trade secrets, now existing or hereafter
      developed and to any and all systems, software, programs, deliverables,
      documentation and works, which may be created out of or in connection with
      the performance by the Service Provider (or any of its affiliates), or any
      of their personnel of the Services pertinently covered by this
      Agreement<br /><br />8. CONFIDENTIALITY <br /><br />8.1 Each Party
      expressly agrees to (i) retain in confidence all information transmitted
      to such party that the disclosing party has identified as being
      proprietary and/or confidential or which, by the nature of the
      circumstances surrounding the disclosure or the content of the disclosed
      information, ought in good faith to be treated as proprietary and/or
      confidential, and (ii) use such information and/or know-how solely for the
      purposes set forth in this Agreement. The receiving Party’s obligation
      hereunder shall survive termination of this Agreement. The Parties
      acknowledge that they may be exposed to confidential information of the
      other Party that is unrelated to the matters set forth in this Agreement,
      and that such information will be subject to the same protections from
      disclosure and misuse hereunder. Notwithstanding the foregoing, if a
      receiving Party is required to disclose any confidential information of
      the other party by any court, tribunal or other governmental or regulatory
      authority, the receiving Party may disclose such confidential information;
      provided that the receiving Party, to the extent legally permissible,
      provides reasonable prior notice to the disclosing Party of any such
      requirements and provides reasonable assistance to the disclosing Party in
      obtaining a protective order or similar protection for such information.
      <br /><br />8.2 Confidential information subject to the obligations in the
      prior paragraph shall not include any information that: (a) is or becomes
      publicly available without the receiving party’s breach of any obligations
      owed the disclosing party; (b) was known to the receiving party prior to
      the disclosing party’s disclosure of such information to the receiving
      party; (c) became known to the receiving party from a source other than
      the disclosing party without breach of an obligation of confidentiality;
      or (d) is independently developed by the receiving party, as demonstrated
      by the receiving parties’ records. <br /><br />8.3 Neither Party will make
      a public announcement, publicly disclose or discuss with third parties the
      terms and conditions of this Agreement. Nothing herein prevents either
      party from making public announcements or disclosing in general that they
      have a business relationship under<div class="html2pdf__page-break"></div> which Products are jointly sold by
      Vendor and Retailer. For the avoidance of doubt, the Parties may disclose
      the terms and conditions of this Agreement for business purposes to their
      current and future employees, affiliates, advisors, attorneys,
      accountants, lenders, investors, vendors and suppliers who are not
      competitors of the other Party. <br /><br />9. INDEMNIFICATION AND
      LIMITATIONS TO LIABILITY <br /><br />9.1. The Client agrees to defend,
      indemnify and hold the Company (or any of its affiliates) and any of their
      officers, directors, agents and customers, harmless from and against any
      claims, lawsuits, investigations, penalties, damages, losses, or expenses
      (including but not limited to attorney’s fees and costs) arising out of or
      relating to any of the following:<br /><br />
      <span style="display: inline-block; padding-left: 2rem"
        >(a) Any breach or alleged breach by the Client under this Agreement or
        the representations and warranties made by the Client; <br /><br />(b)
        Any willful or grossly negligent act or omission of the Client or any of
        its officers, employees or, agents in relation to the performance of its
        obligations under this Agreement; <br /><br />(c) In the event that the
        Client avails of Import Exchange Facilitation Agency Services, any claim
        arising out of or relating to the Products to be imported by the Client,
        including but not limited to, any claims of false advertising, product
        defects (including both latent and apparent defects), personal injury,
        death, property damages, or loss shipments; <br /><br />(d) In the event
        that the Client avails of Import Exchange Facilitation Agency Services,
        any breach by the Product of the Client of any third party's rights,
        including in respect of any claim that the Product infringe, or their
        sale or distribution infringes the patent, copyright, design right,
        trademark, or other intellectual property rights of any other person
        anywhere in the world; and <br /><br />(e) In the event that the Client
        avails of Foreign Exchange Facilitation Agency Services, any claim
        arising out of or relating to the monies subject of the foreign
        exchange, including but not limited to, any claims of money laundering
        or anti-terrorism financing, fraudulent funds</span
      ><br /><br />
      9.2. With respect to any claim concerning performance or non-performance
      by the Company pursuant to, or in any other way related to the subject
      matter of this Agreement, or any claim for breach or default by the
      Company, the Client’s exclusive remedy shall be the recovery of actual
      damages; provided that, the total liability of the Company to the Client
      for any loss (whether based on contract or tort, including negligence and
      strict liability) in connection with this Agreement shall not exceed the
      amount paid by the Client to the Company for the one (1) month period
      prior to the event giving rise to liability. Under no circumstances shall
      the Company be liable to the Client for any special, incidental, punitive,
      indirect, exemplary, or consequential damages, including, but not limited
      to, lost revenue, lost profits, business, goodwill or date, loss of
      anticipated savings, cost of replacement of goods, loss of technology,
      rights, or services. <br /><br />10. FORCE MAJEUREV <br /><br />10.1 If
      either Party is prevented or delayed in the performance of any of its
      obligations, under this Agreement, by Force Majeure and there is no
      concurrent fault or negligent on the part of such party who failed to
      perform, or was delayed in performing, its obligations under this
      Agreement, that party shall: (a) immediately serve written notice to the
      other party specifying the nature and extent of circumstances giving rise
      to Force Majeure; and (b) subject to service of such notice and sub-clause
      above, have no liability in respect of the performance of such of its
      obligations, as are prevented by the Force Majeure events during the
      continuation of such events, and for such time after they cease as is
      necessary for that party, using all reasonable endeavors, to recommence
      disrupted operations, in order for it to perform its obligations.<div class="html2pdf__page-break"></div>
      <br /><br />10.2 If either Party is prevented from the performance of its
      obligations for a continuous period of thirty (30) days, the other Party
      may terminate this Agreement with immediate effect on service of written
      notice upon the Party so prevented, in which case neither Party shall have
      any liability to the other party except that such termination shall not
      release either Party from any liability of which at the time of such
      termination, has already accrued to the other Party; or (ii) subsequently
      accrue with respect to any act or omission prior to such termination.
      <br /><br />10.3 The Party claiming to be prevented or delayed in the
      performance of any of its obligations under this Agreement by reason of
      Force Majeure shall use all reasonable endeavors to bring the Force
      Majeure event to a close, or to find a solution by which the Agreement may
      be performed despite the continuance of the Force Majeure event.
      <br /><br />11. RELATIONSHIP OF THE PARTIES <br /><br />11.1 The
      relationship between the Client and the Company is that of client and
      independent contractor only. Nothing stated in this Agreement shall be
      construed as creating the relationships of employer and employee,
      franchiser and franchisee, master and servant, partnership or joint
      venture between the parties. The Company shall be deemed an independent
      contractor at all times, and shall have no express or implied right or
      authority to assume or create any obligation on behalf of the Client under
      than those specified in this Agreement. <br /><br />11.2 The Company shall
      have entire charge of the management and operation of its business; it
      shall furnish all logistics and operational systems, and hire and pay the
      wages of all assistants and employees required for the operation of its
      business. The Client shall have no supervision or control over the Company
      and its facilities, employees, and methods to be used in carrying out the
      purposes of this Agreement. <br /><br />11.3 The Company shall be
      responsible in the timely payment of wages, salaries, and benefits due to
      its personnel and employees. The Company shall faithfully comply with all
      its obligations to its personnel pursuant to the Labor Code and its
      implementing Rules and Regulations and assures its personnel safe and
      healthful working conditions; labor standards such as but not limited to
      the service incentive leaves, rest days, overtime pay, holiday pay, 13th
      month pay and separation pay; retirement benefits; social security and
      welfare benefits; self-organization, collective bargaining and peaceful
      concerted activities; and security of tenure. The Company shall likewise
      directly and properly remit to the proper government agencies all
      withholding taxes, Social Security System (SSS), Home Development Fund
      (Pag-IBIG) and Philippines Health Insurance Corporation (PhilHealth)
      contributions or premiums in accordance with existing laws.<br /><br />
      11.4 The Company shall be solely and exclusively responsible to its
      personnel and employees in case of any salary and/or material benefits
      and/or increases subsequently mandated by law. <br /><br />11.5 The
      Company shall have sole and exclusive charge in the selection, engagement,
      investigation, discipline and discharge of its personnel. In the
      performance of its obligations herein stipulated, especially in connection
      with the requested number of personnel herein agreed, the Client cannot
      require the Company to post any particular or specific person for the
      Client since the Company has full and exclusive discretion and charge of
      its obligation to provide the Services. The Client shall report, in
      writing or verbally, to the Company and/or Company’s representatives any
      untoward act, negligence, misconduct or malfeasance committed by the
      personnel or employee of the Company. <br /><br />11.6 The Company has the
      sole power to conduct disciplinary proceedings on its personnel and
      employees assigned to the Client. The Client is entitled to a replacement,
      substitution of personnel and employees thereof, to ensure that its
      objectives and desired results are not in any way hampered, compromised or
      threatened. <br /><br /><br /><br />12. MISCELLANOUS PROVISIONS
      <br /><br />12.1 Notice. Each notice, demand or other communication given
      or made under this Agreement shall be in writing and delivered or sent to
      the relevant Party at its address, email address, or<div class="html2pdf__page-break"></div><br /> facsimile number set
      out below (or such other address or email address as the addressee has by
      five (5) days prior written notice specified to the other Parties):
      <br /><br />To the Client: <br /><br /><span
        style="display: inline-block; padding-left: 15rem"
        ><span>Phone Number: {{clientPhone}}</span><br />
        <span>Email Address: {{clientEmail}}</span> </span
      ><br /><br />To the Service Provider: ANGKAT PH BUSINESS SERVICES INC.
      <br /><br /><span style="display: inline-block; padding-left: 15rem"
        ><span>Phone Number: 0917 505 3696</span><br />
        <span>Email Address: sales@angkatph.com</span>
      </span>
      <br /><br />12.2 Delivery. Any notice, demand or other communication so
      addressed to the relevant Party shall be deemed to have been delivered (a)
      if given or made by letter, when personally delivered to the relevant
      address; (b) if sent by registered mail, three (3) days after being
      deposited in the mail, postage prepaid; and (c) if transmitted or given by
      email at the proper email address of the appropriate Party.
      <br /><br />12.3 Assignment. Notwithstanding any other provision to the
      contrary, neither this Agreement nor any rights, interests or obligations
      hereunder may be transferred or assigned to any third party by a Party,
      whether by operation of applicable law or otherwise, without the prior
      written consent of the other Party to this Agreement, and any attempt to
      do so will be void. <br /><br />12.4 Counterparts and Electronic
      Signatures. This Agreement may be executed in any number of counterparts,
      each of which when executed and delivered will constitute an original of
      this Agreement and together will constitute the same document. Execution
      and delivery of this Agreement by electronic exchange bearing the copies
      of a party’s signature (e.g. DocuSign, AdobeSign, HelloSign, Twala or
      similar electronic signature technology) shall constitute a valid and
      binding execution and delivery of this Agreement by such party. Such
      electronic copies shall constitute enforceable original documents and
      shall be considered to have the same binding legal effect as if it were
      the original signed version thereof delivered in person. At the request of
      any party hereto, the other party hereto shall re-execute an original form
      thereof and deliver it to the requesting party. No party hereto shall
      raise the use of electronic mail attachment in “pdf” or similar format to
      deliver a signature, or the fact that any signature was transmitted or
      communicated as an attachment to an electronic mail message, as a defense
      to the formation of a contract and each party forever waives any such
      defense. An electronically scanned copy of a signature shall constitute
      and shall be deemed to be sufficient evidence of a party’s execution of
      this Agreement, without necessity of further proof. It shall not be
      necessary in making proof of this Agreement to produce or account for more
      than one such counterpart. <br /><br />12.5 Waiver. No waiver of any term
      or condition of this Agreement shall be effective or binding unless such
      waiver is in writing and is signed by the waiving party, nor shall this
      Agreement be changed, modified, discharged or terminated other than in
      accordance with its terms, in whole or in part, except by a writing signed
      by both parties. Waiver by any party of any term, provision or condition
      of this Agreement shall not be construed to be a waiver of any other term,
      provision or condition nor shall such waiver be deemed a subsequent waiver
      of the same term, provision or condition. <br /><br />12.6 Severability.
      If any provision or part of a provision of this Agreement shall be, or be
      found by any authority or court of competent jurisdiction to be, invalid
      or unenforceable, such invalidity or unenforceability shall not affect the
      other provisions or parts of such provisions of this Agreement, all of
      which shall remain in full force and effect. <br /><br />12.7 Successors.
      This Agreement shall be binding upon and ensure for the benefit of the
      estates, personal representatives or successors, executors, administrators
      and nominee directors of the Parties.<div class="html2pdf__page-break"></div><br /> 12.8 Governing Law. This Agreement
      and the rights of the Parties hereunder shall be governed by and
      interpreted in accordance with the Laws of Republic of the Philippines.
      12.9 Good Faith. In discharging their respective duties and obligations
      hereunder, each of the Parties hereby undertake to each other that it
      shall apply the standards of good faith and commercial loyalty, taking
      into consideration that the main purpose of this Agreement is to ensure
      the utmost cooperation among the Parties and the success of the Client.
      The Parties agree to perform all such acts and to execute and deliver such
      other documents or instruments as may be necessary in order to give effect
      to the intent underlying this Agreement. <br /><br />12.10 Dispute
      Resolution and Venue of Action. If any dispute, controversy, claim or
      difference of any kind whatsoever shall arise between the Parties in
      connection with this Agreement, including the breach, termination or
      validity of this Agreement, or in connection with the determination of any
      matters which are subject to objective determination pursuant to this
      Agreement (each, a “Dispute”), the Parties shall attempt, for a period of
      thirty (30) business days after receipt from a Party by the other Party of
      a notice stating the existence of a Dispute, to settle such Dispute in the
      first instance by mutual discussions between the duly authorized
      representatives of the Parties. The duly authorized representatives of the
      Parties shall, prior to the commencement of the discussions, present a
      Secretary’s Certificate attesting to a resolution of the board of
      directors of such Party to the effect that such representative is
      authorized to represent the said Party and to settle the Dispute under
      such terms and conditions as the representative may deem fit. If the
      Dispute cannot be resolved in the manner set out in the preceding
      paragraph, the Dispute shall be brought exclusively to the jurisdiction of
      the appropriate courts of Quezon City, Philippines. <br /><br />12.11
      Entire Agreement. This Agreement (together with any documents referred to
      herein or executed contemporaneously by the Parties in connection
      herewith) constitutes the whole agreement between the Parties and
      supersedes any previous agreements or arrangements, oral or written,
      between them relating to the subject matter of this Agreement; it is
      expressly declared that no variations of this Agreement shall be effective
      unless made in writing signed by the duly authorized representatives of
      the Parties.<br /><br />
      IN WITNESS WHEREOF, the parties have caused this Agreement to be executed
      as of the date first written above.<br /><br />
      Client <br /><br />By: <br /><br /><br /><br />_____________________<br />{{clientName}}
      <br /><br />ANGKAT PH BUSINESS SERVICES INC. <br /><br />Company
      <br /><br />By:<br /><br /><br /><br />_____________________<br />
      {{angkatSignatory}} <div class="html2pdf__page-break"></div><br /><span
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          text-align: center;
        "
        >ANNEX “A” <br /><br />SPECIFIC PROVISIONS FOR <br /><br />IMPORT
        FACILITATION AGENCY SERVICES</span
      ><br /><br />
      <span style="display: inline-block; padding-left: 2rem"
        >1. APPOINTMENT AS FACILITATION AGENT</span
      ><br /><br />1.1. Subject to the terms and conditions of this Agreement,
      the Client hereby authorizes and appoints the Facilitation Agent, and the
      Facilitation Agent accepts the authorization and appointment to procure on
      behalf of the Client the Import Services of the Import Service Provider
      and to monitor, manage, and facilitate the importation of the Client’s
      Products into the Philippines.<br /><br />
      1.2. The Facilitation Agent acknowledges and agrees that it has no rights
      or claims of any type to the Client’s Products, or any aspect thereof,
      except such rights as are created by this Agreement.<br /><br />
      <span style="display: inline-block; padding-left: 2rem"
        >2. SCOPE OF SERVICES</span
      ><br /><br />
      2.1. The Facilitation Agent agrees to exercise commercially best
      reasonable effort to: <br /><br />(a) Deal, negotiate, and transact on
      behalf of the Client with the Import Service Provider with respect to the
      prices, terms, and shipment of the Products from the point of admission in
      the Product’s country of origin to the point of dispatching, which shall
      be at the designated warehouse of the Import Service Provider;<br /><br />
      (b) To pay, on behalf of the Client, the Import Fees and Expenses to the
      Import Service Provider; <br /><br />(c) When requested, arrange on behalf
      of the Client, the availment of foreign exchange services relating to the
      importation of the Products into the Philippines, which shall be covered
      by a separate agreement; <br /><br />(d) Monitor the shipment of the
      Products by the Import Service Provider; <br /><br />(e) Arrange the
      dispatch of the Client’s Products from the Import Service Provider’s
      designated warehouse; <br /><br />(f) Conduct its business in a manner
      that reflects favorably at all times on the Client’s Products and the good
      name, goodwill, and reputation of the Client (or any of its affiliates);
      and <br /><br />(g) comply with all laws, statutes, and ordinances of any
      Governmental Authority in the Philippines that may be applicable to the
      Facilitation Agent, its activities under this Agreement or the Client’s
      Products. The Facilitation Agent agrees to take all such further acts and
      execute all such further documents as the Client reasonably may request in
      connection such compliance. <br /><br /><span
        style="display: inline-block; padding-left: 2rem"
        >3. SHIPMENT, TITLE, AND RISK OF LOSS</span
      ><br /><br />3.1. The Import Service Provider shall be responsible for the
      shipment of the Products from the point of admission to the point of
      dispatching. <br /><br />3.2. The Facilitation Agent shall monitor the
      shipment of the Products and ensure that the Import Service Provider
      exercises commercially best efforts to ship the Products within a
      reasonable time. The Facilitation Agent shall advise the Client of the
      expected date of arrival of the Products into the Philippines. As soon as
      the Facilitation Agent is made aware that the Products will not be able to
      arrive at the expected date, the Facilitation Agent shall notify the
      Client immediately. <br /><br />3.3. The Import Service Provider shall be
      responsible for the customs clearance of the Products. As merely an agent,
      the Facilitation Agent bears no responsibility or risk in connection
      thereto and nor shall it be responsible for any damages or loss that might
      be incurred by the Client as a result of the Import Service Provider’s
      failure to ensure all the necessities for the importation of the Products
      or any delay in the customs clearance of the Products. <br /><br />3.4.
      Unless covered by an appropriate insurance coverage availed of by the
      Client on the<div class="html2pdf__page-break"></div> Client’s own account, any loss during shipment and until
      such Products are actually dispatched from the Import Service Provider’s
      warehouse, shall be the Import Service Provider’s sole and exclusive
      liability: provided, however, that the Import Service Provider shall not
      be liable if the loss is due to the Client’s incorrect or incomplete
      shipping mark, improper or bad packaging of shipments. The Client shall
      bear all risk of loss or damage to the Products prior to the point of
      admission in the country of origin and after the dispatch of such Products
      from the Import Service Provider’s warehouse. As merely an agent, the
      Facilitation Agent bears no responsibility or risk in connection with lost
      or damaged Products. <br /><br /><span
        style="display: inline-block; padding-left: 2rem"
        >4. PAYMENT AND EXPENSES</span
      ><br /><br />
      4.1. As consideration for the Import Facilitation Agency Services rendered
      by the Facilitation Agent under this Agreement, the Client agrees to pay
      the Facilitation Agent a Service Fee for each importation of Products at
      the Prevailing Service Fees. <br /><br />4.2 The Client shall pay all
      Import Fees and Expenses incurred in connection with the importation of
      the Products at the Prevailing Import Fees and Expenses. <br /><br />4.3.
      The Client shall pay the Service Fee and, if applicable, remit the Import
      Fees and Expenses within seven (7) days from the issuance of the Billing
      Statement. If the last day for payment, as thus computed, falls on a
      Saturday, a Sunday, or a legal holiday, the payment shall be made until
      the next business day. Otherwise, the unpaid amount shall bear a three and
      five tenths percent (3.5%) interest per month and a One Thousand
      Philippine Pesos (Php1,000.00) penalty per month until fully paid. For the
      avoidance of doubt, the interest and the penalty shall continue to run
      regardless of whether the Products are claimed from the Import Service
      Provider’s warehouse or not. <br /><br /><span
        style="display: inline-block; padding-left: 2rem"
        >5. UNCLAIMED PRODUCTS</span
      >
      <br /><br />5.1. If the imported Products are not claimed by the Client
      from the Import Service Provider’s warehouse within thirty (30) days from
      the issuance of the Billing Statement, the Facilitation Agent shall charge
      a Storage Fee of One Thousand Five Hundred Philippine Pesos (Php 1,500.00)
      for an occupied storage space of between 0.00 to 1.00 cubic meter and One
      Thousand Five Hundred Philippine Pesos (Php 1,500.00) for every additional
      1 cubic meter or a fraction thereof. <br /><br />5.2. If the imported
      Products remains unclaimed for more than ninety (90) days counted from the
      issuance of the Billing Statement, the Facilitation Agent shall have the
      right to seize the imported Products and to appropriate the same or to
      sell, transfer, or dispose of the same and the proceeds applied to satisfy
      the Client’s obligations, without prejudice to any action as may be
      appropriate for the recovery of any deficiency or other damages by the
      Facilitation Agent.<br /><br />
      <span style="display: inline-block; padding-left: 2rem"
        >6. SPECIFIC REPRESENTATIONS AND WARRANTIES</span
      ><br /><br />6.1 The Client represents and warrants that: <br /><br />(f)
      It has good title to the Products and that the Products are delivered free
      of liens or encumbrances;<br /><br />
      (g) All Products to be imported comply with all relevant standards adopted
      by such bodies as the Philippine Standards Association and with the
      requirements of any Governmental Authority and all applicable laws;
      <br /><br />(h) All Products to be imported are not, to the best of the
      Client’s knowledge, in breach of any third-party rights and are compliant
      with any applicable law and will not cause the Facilitation Agent or the
      Import Service Provider to breach any applicable law. <br /><br /><span
        style="display: inline-block; padding-left: 2rem"
        >7. DUTIES AND OBLIGATIONS DURING THE NOTICE PERIOD AND FOLLOWING THE
        EFFECTIVE DATE OF TERMINATION OR NON-RENEWAL</span
      ><br /><br />7.1 During the Notice Period, the Facilitation Agent shall
      continue to perform the Import<div class="html2pdf__page-break"></div> Facilitation Agency Services and ensure
      that the Import Service Provider exercises commercially best efforts to
      ship the Products within a reasonable time and the Client shall pay for
      all such Service Fees and remit all such Import Fees and Expenses relating
      to the importation of the Products; provided, however, that
      notwithstanding anything to the contrary in this Agreement, the
      Facilitation Agent shall not be required to render Import Facilitation
      Agency Services to the Client during any period in which the Client is in
      material breach of this Agreement (including but not limited to any
      payment default) after giving effect to all applicable notice and cure
      periods; provided, further, that if the Facilitation Agent elects to
      render Import Facilitation Agency Services during such periods of material
      default it shall hold title to the imported Products, and title shall not
      pass to the Client until the Client has paid the Service Fee and remitted
      the Import Fees and Expenses in full unless such payment or portion
      thereof is disputed in good faith. <br /><br />7.2 Following the effective
      date of termination or non-renewal: <br /><br />(a) The Client may
      continue to avail of the Import Facilitation Agency Services for an
      importation that has been accepted by the Facilitation Agent prior to the
      termination or non-renewal of this Agreement under the terms and
      conditions of this Agreement; <br /><br />(b) The Client shall remit to
      the Facilitation Agent all unpaid amounts owed to the Facilitation Agent;
      and <br /><br />(c) The Client shall recover all unclaimed imported
      Products within seven (7) days from the effective date of termination.
      Otherwise, the Facilitation Agent shall charge a Storage Fee of One
      Thousand Five Hundred Philippine Pesos (Php 1,500.00) for an occupied
      storage space of between 0.00 to 1.00 cubic meter and One Thousand Five
      Hundred Philippine Pesos (Php 1,500.00) for every additional 1 cubic meter
      or a fraction thereof. If the imported Products remains unclaimed for more
      than ninety (90) days counted from the effective date of termination, the
      Facilitation Agent shall have the right to seize the imported Products and
      to appropriate the same or to sell, transfer, or dispose of the same for
      its benefit.<div class="html2pdf__page-break"></div>
      <span
      style="
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      text-align: center;
    "
    >ANNEX “B” <br /><br />SPECIFIC PROVISIONS FOR <br /><br />FOREIGN
        EXCHANGE FACILITATION AGENCY SERVICES</span
      ><br /><br /><span style="display: inline-block; padding-left: 2rem">
        1. APPOINTMENT AS FACILITATION AGENT</span
      ><br /><br />1.1. Subject to the terms and conditions of this Agreement,
      the Client hereby authorizes and appoints the Facilitation Agent, and the
      Facilitation Agent accepts the authorization and appointment to procure on
      behalf of the Client the Foreign Exchange Services of the Foreign Exchange
      Service Provider and to monitor, manage, and facilitate the availment of
      foreign exchange services relating to the importation of the Products into
      the Philippines covered by the Importation Agency Agreement.<br /><br />
      1.2. The Facilitation Agent acknowledges and agrees that it has no rights
      or claims of any type to the Client’s monies, or any aspect thereof,
      except such rights as are created by this Agreement. <br /><br />1.3. The
      Facilitation Agent shall have no responsibility, obligation, or liability
      whatsoever with respect to any limitations or restrictions, any event, or
      any law or regulation that is introduced or changed (or any change takes
      place in the interpretation of any law or regulation) which would likely
      result in the restriction or prohibition or which would have a serious
      adverse effect on the ability of the recipient to receive, whether actual
      or constructive, the monies subject of the Foreign Exchange Facilitation
      Services under this Agreement. <br /><br />1.4. The Client acknowledges
      that the Facilitation Agent is not a foreign exchange dealer, money
      changer, or remittance agent. It is merely acting as the Client’s agent in
      relation to the procurement of the services of the Foreign Exchange
      Service Provider in order to avail of foreign exchange services relating
      to the importation of the Products into the<div class="html2pdf__page-break"></div> Philippines covered by the
      Importation Agency Agreement. <br /><br /><span
        style="display: inline-block; padding-left: 2rem"
        >2. SCOPE OF SERVICES</span
      >
      <br /><br />2.1. The Facilitation Agent agrees to exercise commercially
      best reasonable effort to: <br /><br />(a) Deal, negotiate, and transact
      on behalf of the Client with the Foreign Exchange Service Provider with
      respect to the terms and conditions of the Foreign Exchange Service;
      <br /><br />(b) To pay, on behalf of the Client, the Foreign Exchange Fees
      and Expenses to the Foreign Exchange Service Provider; <br /><br />(c) To
      hold in trust for the Client the monies subject of the Foreign Exchange
      and to act in accordance with the Client’s reasonable instructions in
      relation to such monies; <br /><br />(d) Conduct its business in a manner
      that reflects favorably at all times on the Client’s Products and the good
      name, goodwill, and reputation of the Client (or any of its affiliates);
      and <br /><br />(e) comply with all laws, statutes, and ordinances of any
      Governmental Authority in the Philippines that may be applicable to the
      Facilitation Agent, its activities under this Agreement or the Client’s
      monies, including laws, rules, and regulations dealing with money
      laundering and anti-terrorism financing. The Facilitation Agent shall not
      assist directly or indirectly through the Foreign Exchange Facilitation
      Services in money laundering from any criminal or illegal sources. The
      Facilitation Agent agrees to take all such further acts and execute all
      such further documents as the Client reasonably may request in connection
      such compliance. <br /><br /><span
        style="display: inline-block; padding-left: 2rem"
        >3. PAYMENT AND EXPENSES</span
      >
      <br /><br />3.1. As consideration for the Foreign Exchange Facilitation
      Agency Services rendered by the Facilitation Agent under this Agreement,
      the Client agrees to pay the Facilitation Agent a Service Fee for each
      foreign exchange transaction at the Prevailing Service Fees.
      <br /><br />3.2. The Client shall pay all Foreign Exchange Fees and
      Expenses incurred in connection with the availment of foreign exchange
      services (relating to the importation of the Products into the Philippines
      covered by the Import Facilitation Agency Agreement) at the Prevailing
      Foreign Exchange Fees and Expenses. <br /><br />3.3. The Client shall pay
      the Service Fee and, if applicable, remit the Foreign Exchange Fees and
      Expenses within seven (7) days from the issuance of the Billing Statement.
      If the last day for payment, as thus computed, falls on a Saturday, a
      Sunday, or a legal holiday, the payment shall be made until the next
      business day. Otherwise, the unpaid amount shall bear a three and five
      tenths percent (3.5%) interest per month and a One Thousand Philippine
      Pesos (Php 1,000.00) penalty per month until fully paid. <br /><br /><span
        style="display: inline-block; padding-left: 2rem"
        >4. DUTIES AND OBLIGATIONS DURING THE NOTICE PERIOD AND FOLLOWING THE
        EFFECTIVE DATE OF TERMINATION OR NON-RENEWAL</span
      >
      <br /><br />4.1 Following the effective date of termination or
      non-renewal: <br /><br />(a) The Client shall remit to the Facilitation
      Agent all unpaid amounts owed to the Facilitation Agent; and
      <br /><br />(b) The Facilitation Agent shall immediately cease to offer
      the Foreign Exchange Facilitation Services, and cease any activity in
      connection with therewith. The Parties shall likewise mutually and
      simultaneously turn-over and/or return all documents, papers and all kinds
      of effects in their possession belonging to the other Party.
    </p>
  </body>
</html>
`;
