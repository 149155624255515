import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, of, throwError } from 'rxjs';
import { PwordRegex } from 'src/app/@shared/constants/regex.const';
import { UserModel } from 'src/app/@shared/models/user.model';
import { UsersService } from 'src/app/@shared/services/users.service';

@Component({
  selector: 'app-add-new-user',
  templateUrl: './add-new-user.component.html',
  styleUrls: ['./add-new-user.component.sass'],
})
export class AddNewUserComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter();
  addNewUserForm: FormGroup = this.fb.group({
    givenName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    position: ['', []],
    role: ['', []],
    office: ['', []],
    newPassword: [
      '',
      [
        Validators.required,
        Validators.minLength(6),
        Validators.pattern(PwordRegex),
      ],
    ],
    confirmPassword: ['', [Validators.required]],
  });
  hide: boolean = true;
  confirmHide: boolean = true;
  newPassword: string = '';
  confirmPassword: string = '';
  errorMsg: string | string[] = '';
  pwordErrorMsg: string | string[] = '';
  isPwordMatch: boolean = true;
  isEmailTaken: boolean = false;
  constructor(private fb: FormBuilder, private _usersService: UsersService) {}

  ngOnInit(): void {
    this.addNewUserForm.controls['role'].setValue('admin');
  }

  createUser() {
    const {
      givenName,
      lastName,
      email,
      position,
      role,
      newPassword,
      confirmPassword,
      office,
    } = this.addNewUserForm.controls;

    if (
      newPassword.value == confirmPassword.value &&
      this.addNewUserForm.controls['email'].valid &&
      this.addNewUserForm.controls['givenName'].valid &&
      this.addNewUserForm.controls['lastName'].valid
    ) {
      const user: UserModel = {
        givenName: givenName.value,
        lastName: lastName.value,
        email: email.value.toLowerCase(),
        position: position.value,
        role: role.value,
        office: office.value,
        displayPhoto: '',
        password: newPassword.value || '', // TODO :: generate random string here
      };

      this._usersService
        .createUser(user)
        .pipe(
          catchError(err => {
            confirmPassword.reset();
            newPassword.reset();
            this.isPwordMatch = true;
            this.errorMsg = err.error.error;

            if (typeof this.errorMsg == 'string') {
              this.errorMsg = this.errorMsg.split(':').pop() || '';
              if (this.errorMsg.trim() == 'This email is already taken') {
                this.isEmailTaken = true;
              }
            }
            if (this.errorMsg[0].includes('password')) {
              this.pwordErrorMsg = this.errorMsg;
              this.errorMsg = '';
              this.isPwordMatch = false;
            }

            return of();
          })
        )
        .subscribe((result: any) => {
          this.closeModal(result?.data?.email);
        });
    } else {
      this.isPwordMatch = false;
      this.checkInvalidControl();
    }
  }
  checkInvalidControl() {
    const controls = this.addNewUserForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        this.addNewUserForm.controls[name].markAsDirty();
      }
    }
  }

  checkPassword() {
    if (
      this.addNewUserForm.controls['newPassword'].dirty &&
      this.addNewUserForm.controls['confirmPassword'].dirty
    ) {
      if (
        this.addNewUserForm.controls['newPassword'].value !=
        this.addNewUserForm.controls['confirmPassword'].value
      ) {
        this.pwordErrorMsg = 'The password does not match';
        this.isPwordMatch = false;
      } else {
        this.pwordErrorMsg = '';
        this.isPwordMatch = true;
      }
    }
  }
  closeModal(email?: string): void {
    this.closeModalEvent.emit(email);
  }
}
