import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AngkatDashboardService } from 'src/app/@shared/services/angkat-services/angkat-dashboard.service';
import { temp } from 'src/app/@shared/services/angkat-services/temp';
import { SignSecureService } from 'src/app/sign-secure/services/sign-secure.service';
import { OverlaySpinnerComponent } from '../spinner/overlay-spinner.component';
import {
  catchError,
  combineLatest,
  first,
  firstValueFrom,
  lastValueFrom,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FileCreatedComponent } from '../spinner/file-created/file-created.component';
import { CreationFailedComponent } from '../spinner/creation-failed/creation-failed.component';

@Component({
  selector: 'angkat-create-document',
  templateUrl: './document-creation.component.html',
  styleUrls: ['./document-creation.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentCreationComponent implements OnInit {
  @ViewChild('formTemplate', { static: true }) formContent!: ElementRef;
  template = temp;
  month = 'December';
  pdfSrc: any = '';
  urlData!: IntegrationModel;
  pdfData: any;
  constructor(
    private _angkatService: AngkatDashboardService,
    private _signSecure: SignSecureService,
    private _dialog: MatDialog,
    private _ar: ActivatedRoute,
    private _router: Router
  ) {}

  async ngOnInit() {

    const spinnerDialog = this._dialog.open(OverlaySpinnerComponent, {
      disableClose: true,
    });

    this._ar.params
      .pipe(
        tap(params => {
          this.urlData = {
            'api-key': params['api-key'],
            'api-secret': params['api-secret'],
            host: params['host'],
            userId: params['userId'],
            workflowId: params['workflowId'],
          };
        }),
        switchMap(() => {
          return this._angkatService.getWorkflowDraft(this.urlData);
        }),
        switchMap(async draft => {
          const workflowList = await firstValueFrom(
            this._angkatService
              .getWorkflowFileAll(this.urlData)
              .pipe(map(data => data.data.data[0]))
          );

          this._signSecure.userId = this.urlData.userId;
          this._signSecure.urlData = this.urlData;
          if (!workflowList?.id) {
            const formData = draft.data.form;
            this.template = this.template.replaceAll(
              '{{businessAddressCity}}',
              formData.businessAddressCity
            );
            this.template = this.template.replaceAll(
              '{{businessName}}',
              formData.businessName
            );
            this.template = this.template.replaceAll(
              '{{clientAddressCity}}',
              formData.clientAddressCity
            );
            this.template = this.template.replaceAll(
              '{{clientEmail}}',
              formData.clientEmail
            );
            this.template = this.template.replaceAll(
              '{{clientName}}',
              formData.clientName
            );
            this.template = this.template.replaceAll(
              '{{clientPhone}}',
              formData.clientPhone
            );
            this.template = this.template.replaceAll('{{day}}', formData.day);
            this.template = this.template.replaceAll(
              '{{month}}',
              formData.month
            );
            this.template = this.template.replaceAll('{{year}}', formData.year);
            this.template = this.template.replaceAll(
              '{{angkatSignatory}}',
              draft.data.workflow.parties[1].name
            );
            let pdf: any;
            pdf = await this._angkatService.generatePDF(this.template);

            const fileReader = new FileReader();
            fileReader.onload = async () => {
              this.pdfSrc = new Uint8Array(fileReader.result as ArrayBuffer);

              this.pdfData = this.createDoc(
                new File([pdf], 'test.pdf', {
                  type: 'application/pdf',
                }),
                this.pdfSrc,
                spinnerDialog,
                draft
              );
              const formattedData = await this.pdfData;
              this._signSecure.setWorkFlowData(formattedData);
              this._signSecure.dirId = formattedData.id;
              spinnerDialog.close();
              const createdDialog = this._dialog.open(FileCreatedComponent, {
                disableClose: true,
              });

              await this.sleep(3000);
              createdDialog.close();
              this._router.navigateByUrl(`/sign-secure/${formattedData.id}`);
            };
            fileReader.readAsArrayBuffer(pdf);
          } else {
            this._signSecure.dirId = workflowList.id;
            this._signSecure.setWorkFlowData(workflowList);

            spinnerDialog.close();

            const createdDialog = this._dialog.open(FileCreatedComponent, {
              disableClose: true,
            });
            await this.sleep(3000);
            createdDialog.close();
            this._router.navigateByUrl(`/sign-secure/${workflowList.id}`);
          }
          return;
        })
      )
      .subscribe();
  }
  bytesToFile(data: any, filename: string): File {
    return new File([data], this.getCorrectFilename(filename), {
      type: 'application/pdf',
    });
  }

  getCorrectFilename(name: string = '') {
    return name.match(/^.*\.[^\\]+$/g)?.length ?? 0 > 0 ? name : `${name}.pdf`;
  }
  sleep = (ms: number) => new Promise(r => setTimeout(r, ms));
  async createDoc(file: File, fileData: any, spinnerDialog: any, draft: any) {
    const workflowDraft = draft.data.form;
    const workflowParties = draft.data.workflow.parties;
    const angkatDraft = draft.data.workflow.parties.map((data: any) => {
      const format = {
        designation: data.affiliateType,
        affiliateType: data.affiliateType,
        email: data.email,
        id: data.id,
        name: data.name,
        role: data.role,
      };
      return format;
    });
    const x = {
      file: file,
      fileData: fileData,
      type: 'file',
      name: `SLA_${workflowParties[0].name}.pdf`,
      description: '',
      ip: await firstValueFrom(this._signSecure.callIpify()),
      timezone: '',
      workflow: {
        parties: angkatDraft,
        emailContent: {
          subject: '',
          object: '',
        },
        signOrder: true,
        signatures: [
          [
            {
              trackId: '',
            },
          ],
          [
            {
              trackId: '',
            },
          ],
          [
            {
              trackId: '',
            },
          ],
          [
            {
              trackId: '',
            },
          ],
          [
            {
              trackId: '',
            },
          ],
          [
            {
              trackId: '',
            },
          ],
          [
            {
              trackId: '',
            },
          ],
          [
            {
              trackId: '',
            },
          ],
          [
            {
              name: workflowParties[0].name,
              image:
                '../../../../../assets/images/template-icons/signature.svg',
              type: 'signature',
              trackId: '5l6tpk',
              config: {
                id: '0h9w9',
                x: '35',
                y: '728',
                width: '263',
                height: '72',
                fill: '#5E89B755',
                stroke: 'transparent',
                strokeWidth: '0',
              },
              textConfig: {
                id: 'wf83bj',
                x: '0',
                y: '0',
                text: '[Signature will be placed here]',
                fontSize: '8.5',
                fontFamily: 'DM Sans',
                width: '263',
                padding: '33',
                align: 'center',
                fill: '#5E89B7',
              },
              documentConfig: {
                id: 'dpz47',
                x: '162',
                y: '0',
                text: 'XXXXXXXXXXXXXXX',
                fontSize: '5.28533',
                fontFamily: 'DM Sans',
                width: '100',
                padding: '14',
                align: 'center',
                fill: 'black',
              },
              id: workflowParties[0].id,
              email: workflowParties[0].email,
            },
            {
              name: workflowParties[1].name,
              image:
                '../../../../../assets/images/template-icons/signature.svg',
              type: 'signature',
              trackId: '8mrdl',
              config: {
                id: 'sbboz',
                x: '35',
                y: '948',
                width: '263',
                height: '72',
                fill: '#CCCD5C55',
                stroke: 'transparent',
                strokeWidth: '0',
              },
              textConfig: {
                id: 'cthn9',
                x: '0',
                y: '0',
                text: '[Signature will be placed here]',
                fontSize: '8.5',
                fontFamily: 'DM Sans',
                width: '263',
                padding: '33',
                align: 'center',
                fill: '#CCCD5C',
              },
              documentConfig: {
                id: 'yfe8kk',
                x: '162',
                y: '0',
                text: 'XXXXXXXXXXXXXXX',
                fontSize: '5.28533',
                fontFamily: 'DM Sans',
                width: '100',
                padding: '14',
                align: 'center',
                fill: 'black',
              },
              id: workflowParties[1].id,
              email: workflowParties[1].email,
            },
          ],
          [
            {
              trackId: '',
            },
          ],
          [
            {
              trackId: '',
            },
          ],
          [
            {
              trackId: '',
            },
          ],
          [
            {
              trackId: '',
            },
          ],
          [
            {
              trackId: '',
            },
          ],
        ],
        qrPosition: 'bottom-right',
      },
    };
    return await firstValueFrom(
      this._angkatService
        .createFile(this.urlData, this._signSecure.formatData(x))
        .pipe(
          catchError(err => {
            spinnerDialog.close();
            const createdDialog = this._dialog.open(CreationFailedComponent, {
              disableClose: true,
            });
            return of();
          }),
          map(data => {
            const formattedData = data.data;
            formattedData['id'] = data.data._id;
            return formattedData;
          })
        )
    );
  }
}

export interface IntegrationModel {
  'api-key': string;
  'api-secret': string;
  host: string;
  userId: string;
  workflowId: string;
}
