import { Component, OnInit } from "@angular/core";
import { PDFDocument, PageSizes } from "pdf-lib";
import { FilesService } from "src/app/@shared/services/files.service";
import { Base64 } from 'js-base64'
import { SignSecureService } from "src/app/sign-secure/services/sign-secure.service";

@Component({
	selector: "app-upload-template",
	templateUrl: "./upload-template.component.html",
	styleUrls: ["./upload-template.component.sass"],
})
export class UploadTemplateComponent implements OnInit {
	value: string = "files";
	uploadFile: any;
	size: string = "";
	fileData: any;
	name: any = undefined
	constructor(
		private _signSecureService: SignSecureService,
		private _fileService: FilesService
	) { }

	ngOnInit(): void {
		this._signSecureService.worflowData$.subscribe((data) => {
			if (data.file) {
				this.uploadFile = data.file;
				this.size = this._fileService.formatBytes(data.file?.size);
			}
		});
	}

	onFileSelected(data: any) {
		const regex = /.*\.(pdf|PDF|PNG|png|JPG|jpg)$/g;
		let files = [];
		if (data.path) {
			files = Array.from(data.path[0].files)?.filter((file: any) => {
				const result = file?.name?.match(regex);
				return result && result.length !== 0;
			});
		} else if (data.target) {
			files = Array.from(data.target.files)?.filter((file: any) => {
				const result = file?.name?.match(regex);
				return result && result.length !== 0;
			});
		} else {
			files = Array.from(data.path[0].files)?.filter((file: any) => {
				const result = file?.name?.match(regex);
				return result && result.length !== 0;
			});
		}
		this.uploadFile = files[0];
		this.size = this._fileService.formatBytes((files[0] as any)?.size);

		let reader = new FileReader();

		reader.onload = (e: any) => {
			this.fileData = e.target.result;
		};

		reader.readAsArrayBuffer(files[0] as Blob);
	}

	dragover(data: any) {

		this.uploadFile = Array.from(data)[0];
		this.size = this._fileService.formatBytes((this.uploadFile as any)?.size);

		let reader = new FileReader();

		reader.onload = (e: any) => {
			this.fileData = e.target.result;
		};

		reader.readAsArrayBuffer(this.uploadFile as Blob);
	}

	upload() {
		document.getElementById("fileUpload")?.click();
	}

	async next() {
		if (this.fileData) {
			await this.transformToPDF()
			this._signSecureService.setFile(this.uploadFile, this.fileData, this.name);
		}

		this._signSecureService.nextProgress(2);
	}

	pickAnother() {
		this.uploadFile = null;
	}

	async transformToPDF() {

		const img = new Image();
		// Transform to pdf
		const pdfDoc = await PDFDocument.create({})

		Base64.extendUint8Array()
		const bytes = new Uint8Array(this.fileData)
		const string64 = (bytes as any).toBase64()

		let image
		if (this.uploadFile.type.includes('jpeg')) {
			img.src = `data:image/jpg;base64,${string64}`
			image = await pdfDoc.embedJpg(this.fileData)
		} else if (this.uploadFile.type.includes('png')) {
			img.src = `data:image/png;base64,${string64}`
			image = await pdfDoc.embedPng(this.fileData)
		} else {
			console.log('Not a image')
			return
		}

		await img.decode()

		console.log({ pagesize: PageSizes.Folio, letter: PageSizes.Letter })
		const { width, height } = image.scaleToFit(PageSizes.Folio[0], PageSizes.Folio[1])
		const page = pdfDoc.addPage([width, height])

		page.drawImage(image, { x: 0, y: 0, width, height })

		this.fileData = await pdfDoc.save()

		this.name = this.uploadFile.name
		const newName = this._fileService.changeExtension(this.name, 'pdf')
		this.uploadFile = this._signSecureService.bytesToFile(this.fileData, newName)
	}
}
