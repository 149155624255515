<div class="signature_title">
	<div class="header">
		<h3 class="title">Add your initial</h3>
		<button class="close" (click)="close()">
			<img src="../../../../assets/images/close.svg" alt="" srcset="" />
		</button>
	</div>

	<mat-tab-group animationDuration="0ms" (selectedTabChange)="clearSelected()">
		<mat-tab>
			<ng-template mat-tab-label>
				<span>Draw</span>
			</ng-template>
			<div class="signature-pad-header">
				<p class="subtitle">Draw your initial below</p>
				<div class="reset" (click)="clearDrawing()">
					<img src="../../../../assets/images/reset.svg" alt="" srcset="" />
					Reset
				</div>
			</div>
			<div class="draw-pad">
				<signature-pad
					#signature
					[options]="signaturePadOptions"
					(drawStart)="drawStart($event)"
					(drawEnd)="drawComplete($event)"
				></signature-pad>
				<button
					class="sign {{ signatureData !== null ? 'active' : '' }}"
					(click)="close()"
				>
					Sign
				</button>
			</div>
			<div class="draw-pad-phone">
				<signature-pad
					#signature
					[options]="signaturePhonePadOptions"
					(drawStart)="drawStart($event)"
					(drawEnd)="drawComplete($event)"
				></signature-pad>
				<div class="align-button">
					<button
						class="sign {{ signatureData !== null ? 'active' : '' }}"
						(click)="close()"
					>
						Sign
					</button>
				</div>
			</div>
		</mat-tab>
		<mat-tab label="Upload">
			<ng-template mat-tab-label>
				<span>Upload</span>
			</ng-template>
			<div class="signature-2-pad-header">
				<div class="text">
					<p class="subtitle">Upload your signature below</p>
					<p class="description">
						File should be GIF, JPG, PNG, or BMP. Max file size is 200KB.
					</p>
				</div>
				<div
					class="reset {{ status === 'start' ? 'hide' : '' }}"
					(click)="(reset)"
				>
					<img
						src="../../../../assets/images/icons/delete.svg"
						alt=""
						srcset=""
					/>
					Delete
				</div>
			</div>
			<app-add-signature-component
				(done)="doneUpload($event)"
				(status)="uploadStatus($event)"
				[reset]="resetUpload"
			></app-add-signature-component>
		</mat-tab>
		<mat-tab label="Choose">
			<ng-template mat-tab-label>
				<span>Choose</span>
			</ng-template>
			<div class="signature-pad-header">
				<p class="subtitle">Select a saved signature</p>
				<div class="reset">
					<img src="../../../../assets/images/reset.svg" alt="" srcset="" />
					Reset
				</div>
			</div>
			<div class="no-overflow">
				<div class="signatures" *ngIf="!signatureData">
					<div
						class="signature"
						*ngFor="let signature of signatureList"
						(click)="selectSignature(signature)"
					>
						<img [src]="signature.path" />
						<span>select</span>
					</div>
				</div>
				<div class="selectedSignature" *ngIf="signatureData">
					<img [src]="signatureData" />
				</div>
				<div class="align-button">
					<button
						class="sign {{ signatureData ? 'active' : '' }}"
						(click)="close()"
					>
						Sign
					</button>
				</div>
			</div>
		</mat-tab>
		<mat-tab label="Type">
			<ng-template mat-tab-label>
				<span>Type</span>
			</ng-template>
			<div class="type-container">
				<ko-stage [config]="config"></ko-stage>
			</div>
		</mat-tab>
	</mat-tab-group>
</div>
