<div class="guest-db-container">
  <div class="side-panel">
    <angkat-side-panel [user]="user"></angkat-side-panel>
  </div>

  <div
    style="
      flex-direction: column;
      display: flex;
      flex-basis: 80%;
      padding: 5rem 5rem 0 2rem;
      gap: 9rem;
    ">
    <div
      style="align-self: end; font-size: 700; font-size: 24px; cursor: pointer"
      (click)="logout()">
      < Logout
    </div>
    <div style="display: flex; flex-direction: column; gap: 3rem">
      <div style="color: #c2061e; font-size: 700; font-size: 24px">
        You are invited to review and sign the following documents:
      </div>
      <div><angkat-table [dataSource]="tableData$ | async"></angkat-table></div>
    </div>
  </div>
</div>
