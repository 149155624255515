<div class="inbox margin-container">
  <app-table
    *ngIf="!(viewMsg | async)"
    [title]="'Inbox'"
    [hasCheckBox]="hasCheckBox"
    [columns]="columns"
    [data]="data | async"
    [type]="'inbox'"
    [rowFunction]="onClickRow"
    [isFolderStructure]="false"
    [selection]="selection"
  >
    <div class="extra-buttons">
      <button matRipple class="extra-button" (click)="deleteMessages()">
        Delete selected messages
      </button>
    </div>
  </app-table>
  <app-inbox-view
    *ngIf="viewMsg | async"
    (goBackEvent)="goback($event)"
  ></app-inbox-view>
</div>
