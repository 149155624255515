<div class="move-file">
  <h2 class="title">Move {{ numberOfFiles }} {{ fileType }} to...</h2>
  <div class="breadcrumbs">
    <img
      *ngIf="breadcrumbs.length === 1"
      src="../../../../assets/images/icons/back-gold.svg"
      alt=""
      srcset=""
      class="back-img"
    />
    <app-breadcrumb
      [data]="breadcrumbs"
      (onBack)="back($event)"
    ></app-breadcrumb>
  </div>

  <div class="directory-container">
    <div
      [ngClass]="selected !== file.id ? 'directory' : 'directory selected'"
      *ngFor="let file of openedDirectory"
      (click)="openDirectory(file)"
    >
      <div><img [src]="getIconSrc(file['type'])" alt="" /> {{ file.name }}</div>

      <img
        *ngIf="file.children && file.children.length !== 0"
        src="../../../../assets/images/icons/next.png"
        alt=""
        class="next"
      />
    </div>
  </div>

  <div class="actions-container">
    <div class="left-side">
      <button class="create-button">
        <img src="../../../../assets/images/icons/plus-icon-black.svg" alt="" />
      </button>
    </div>
    <div class="rigth-side">
      <button class="cancel-button" (click)="close()">Cancel</button>
      <button
        class="move-button {{ disabled ? 'disabled' : '' }}"
        (click)="moveDirectory()"
      >
        Move
      </button>
    </div>
  </div>
</div>
