<app-wrapper-background>
  <div class="verification-container" *ngIf="!isEmailSent; else emailSent">
    <img src="../../../../assets/images/forma/forma-white-logo.svg" />
    <mat-card class="container">
      <div class="wrapper" id="email">
        <img [src]="imgLink" />
        <h2 class="header">
          <ng-container *ngIf="success === 'Verified';else failedheader">
            Account verified
          </ng-container>
          <ng-template #failedheader>
              <ng-container *ngIf="success === 'Failed';else notverifiedheader">
                Account verification failed
              </ng-container>
          </ng-template>
          <ng-template #notverifiedheader>
            Your email is not verified
          </ng-template>
        </h2>
        <h4 class="description" >
          <ng-container *ngIf="success === 'Verified';else faileddesc">
            Your email has been verified. Start accessing your documents using your account.
          </ng-container>
          <ng-template #faileddesc>
              <ng-container *ngIf="success === 'Failed';else notverifieddesc">
                Please make sure that you are verifying the correct email address associated with account and try again
              </ng-container>
          </ng-template>
          <ng-template #notverifieddesc>
            Start accessing your documents by verifying your email.
          </ng-template>
        </h4>
       
        <a class="btn-next" (click)="handleClick()">
          <label class="btn-font">
            <ng-container *ngIf="success === 'Verified';else failedsubmit">
              {{ 'Go back to login' }} 
            </ng-container>
            <ng-template #failedsubmit>
                <ng-container *ngIf="success === 'Failed';else notverifiedsubmit">
                  {{ 'Go back to login' }} 
                </ng-container>
            </ng-template>
            <ng-template #notverifiedsubmit>
              {{ 'Send verification' }} 
            </ng-template>
          </label>
        </a>
      </div>
    </mat-card>
  </div>

  <ng-template #emailSent>
    <app-email-sent [type]="'email-sent'"></app-email-sent>
  </ng-template>
  
</app-wrapper-background>
