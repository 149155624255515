<div class="msg-container">
  <div class="subject">
    <span class="back-arrow" (click)="goBack()">< </span>{{ message.subject }}
  </div>
  <div class="sender">
    <img class="sender-image" src="../../../assets/images/avatar.png" />{{
      message.sender
    }}
  </div>
  <div class="recipient">to me ({{ message.recipient }})</div>
  <iframe frameBorder="0" [srcdoc]="html" class="msg-body"></iframe>
</div>
