<div class="sidebar">
  <img
    [src]="logoSrc | async"
    alt=""
    class="logo"
    (click)="updateLocation('dashboard')"
  />
  <div class="sidebar-content">
    <div class="sidebar-items">
      <a
        class="sidebar-item"
        routerLink="/dashboard"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
      >
        <div class="icon icon-dashboard"></div>
        <p>Dashboard</p>
      </a>
      <a
        class="sidebar-item"
        routerLink="/inbox"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
      >
        <div class="icon icon-inbox"></div>
        <p>Inbox</p>
      </a>
      <a
        class="sidebar-item"
        routerLink="/documents"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
      >
        <div class="icon icon-documents"></div>
        <p>Documents</p>
      </a>
      <a
        class="sidebar-item"
        routerLink="/folders-and-files"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
      >
        <div class="icon icon-folder"></div>
        <p>Folders and Files</p>
      </a>
      <a
        class="sidebar-item"
        routerLink="/workflow"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
      >
        <div class="icon icon-workflow"></div>
        <p>Workflow</p>
      </a>
      <a
        class="sidebar-item"
        routerLink="/signatures"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
      >
        <div class="icon icon-signature"></div>
        <p>Signatures</p>
      </a>
      <a class="disabled-item" *ngIf="!hideItem">
        <div class="icon icon-template"></div>
        <p>Templates</p>
      </a>

      <a
        class="sidebar-item"
        routerLink="/faq"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
      >
        <div class="icon icon-faq"></div>
        <p>FAQ</p>
      </a>
      <a
        class="sidebar-item support"
        routerLink="/support"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
      >
        <div class="icon icon-support"></div>
        <p>Support</p>
      </a>
      <app-admin-menu *ngIf="userType === 'admin'"></app-admin-menu>
    </div>
    <div class="sidebar-footer">
      <div *ngIf="unawaLogoFlag" class="footer-logo">
        <span>Powered by:</span>
        <img src="../../../assets/images/unawa-logo-text.png" alt="" />
      </div>
      <p class="footer-text">
        <a
          class="emphasize"
          href="https://signsecureapp.unawa.asia/terms-and-conditions"
          target="_blank"
          >Terms & Conditions</a
        >
        |
        <a
          class="emphasize"
          href="https://signsecureapp.unawa.asia/privacy-policy"
          target="_blank"
          >Privacy Policy</a
        ><br />
        © 2023
        <a class="emphasize" href="https://www.unawa.asia/" target="_blank"
          >UNAWA</a
        >
        <!--<span class="emphasize">UNAWA</span>.!-->
        All rights reserved<br />
        <span class="version">Version: {{ version }}</span>
      </p>
    </div>
  </div>
</div>
