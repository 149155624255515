import { SignaturePadComponent, NgSignaturePadOptions } from '@almothafar/angular-signature-pad';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { of } from 'rxjs';
import { SignatureService } from 'src/app/@shared/services/signature.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-initials',
  templateUrl: './create-initials.component.html',
  styleUrls: ['./create-initials.component.sass']
})
export class CreateInitialsComponent implements OnInit {

  @Input()
  data: any

  @Output()
  closeModalEvent: EventEmitter<any> = new EventEmitter()

  @ViewChild('signature')
  public signaturePad!: SignaturePadComponent;

  public signaturePadOptions: NgSignaturePadOptions = { // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 462,
    canvasHeight: 134,
  };

  public signaturePhonePadOptions: NgSignaturePadOptions = { // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 340,
    canvasHeight: 300,
  };

  signatureData: any = null;

  resetUpload: boolean = false
  status: string = ''

  signatureList: any[] = []

  selectedSignature: any = null

  config = of({
    width: 462,
    height: 134
  })

  typedSignature: string = ''
  constructor(private _signatures: SignatureService) { }

  ngOnInit(): void {
    this._signatures.getSignatures().subscribe((data: any) => {
      console.table(data.data)
      this.signatureList = data?.data ?? []
      if (environment.local) {
        this.signatureList = this.signatureList.map((signature: any) => ({ ...signature, path: '../../../assets/images/signature.svg' }))
      } else {
        this.signatureList = this.signatureList.map((signature: any) => ({ ...signature, path: `./assets/${signature.path}` }))
      }

    })
  }

  ngAfterViewInit() {
  }

  drawComplete(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onEnd event
    console.log('Completed drawing', event);

    this.signatureData = this.signaturePad.toDataURL()
    console.log({ signature: this.signatureData })
  }

  drawStart(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('Start drawing', event);
  }

  close() {
    if (this.signatureData) {
      this.closeModalEvent.emit(this.signatureData)
    } else {
      this.closeModalEvent.emit(null)
    }

  }


  doneUpload(event: any) {
    console.log('doneUpload', event)
    if (!environment.local) {

      this.signatureData = `./assets/${event.data.path}`;
    } else {
      this.signatureData = `../../../assets/images/signature.svg`;
    }

    this.close()
  }

  uploadStatus(event: any) {
    this.status = event
    if (event === 'start') {
      this.resetUpload = false
    }
  }

  reset() {
    if (this.status !== 'status')
      this.resetUpload = true
  }

  selectSignature(signature: any) {
    this.signatureData = signature.path
  }

  clearSelected() {
    this.signatureData = null
  }

  clearDrawing() {
    this.signaturePad.clear()
    this.clearSelected()
  }
}
